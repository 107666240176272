import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setIsContactModal } from "store/contacts";

import { PATHES } from "constants/pathes";
import { Button } from "components/UI";
import { SelectedType, TSharedDocumentStatus } from "types";
import { cs } from "utils";

import searchBig from "assets/img/icons/search-big.svg";

import girlChating from "assets/img/girl-chatting-with-friends.svg";
import femaleCandidate from "assets/img/female-candidate-job-profile.svg";
import manCleaning from "assets/img/man-cleaning.svg";
import noContacts from "assets/img/no-contacts.svg";
import girlTyping from "assets/img/girl-typing.svg";
import manTyping from "assets/img/man-typing.svg";

import styles from "./styles.module.scss";

type NoTableDataProps = {
  isSearch?: boolean;
  isNoFilteredData?: boolean;
  isNoData?: boolean;
  isTrash?: boolean;
  isContacts?: boolean;
  isSigningsSent?: boolean;
  isSigningsDraft?: boolean;
  isSigningsInbox?: boolean;
  isRequestSigning?: boolean;
  selectedTypes?: SelectedType[];
  signingsStatus?: TSharedDocumentStatus;
};

export const NoTableData: FC<NoTableDataProps> = ({
  isSearch,
  isNoFilteredData,
  isNoData,
  isTrash,
  isContacts,
  isSigningsSent,
  isSigningsDraft,
  isSigningsInbox,
  isRequestSigning,
  selectedTypes = [],
  signingsStatus,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("Table", {
    keyPrefix: "NoTableData",
  });

  const handleOpenModal = () => {
    dispatch(setIsContactModal(true));
  };

  const handleRequestSigning = () => {
    navigate(PATHES.FILE_RECIPIENTS);
  };

  const getSentInfo = () => {
    switch (signingsStatus) {
      // case "IN_PROGRESS":
      //   return {
      //     img: girlWriting,
      //     imgName: "girlWriting",
      //     text: t("noSigningInProgress"),
      //   };

      default:
        return {
          img: girlTyping,
          imgName: "girlTyping",
          text: t("noSentFiles"),
        };
    }
  };

  if (isNoData && isSigningsSent && !isSearch) {
    return (
      <div className={cs([styles.NoTableData, styles.signings])}>
        <img
          src={getSentInfo().img}
          alt={getSentInfo().imgName}
          width={450}
          height={325}
          style={{ opacity: 0.7 }}
        />
        {getSentInfo().text}
        <Button
          variant="secondary"
          title={t("reqestSigning")}
          size="md"
          onClick={handleRequestSigning}
        />
      </div>
    );
  }

  if (isNoData && isSigningsInbox && !isSearch) {
    return (
      <div className={cs([styles.NoTableData, styles.signings])}>
        <img
          src={manTyping}
          alt="manTyping"
          width={450}
          height={325}
          style={{ opacity: 0.7 }}
        />
        {t("noDocsHere")}
      </div>
    );
  }

  if (isNoData && isSigningsDraft && !isSearch) {
    return (
      <div className={cs([styles.NoTableData, styles.signings])}>
        <img
          src={getSentInfo().img}
          alt={getSentInfo().imgName}
          width={450}
          height={325}
          style={{ opacity: 0.7 }}
        />
        No draft files...
        <Button
          variant="secondary"
          title={t("reqestSigning")}
          size="md"
          onClick={handleRequestSigning}
        />
      </div>
    );
  }

  if (isNoData && isContacts && !isSearch) {
    return (
      <div className={cs([styles.NoTableData, styles.contacts])}>
        <img
          src={noContacts}
          alt="noContacts"
          width={450}
          height={325}
          style={{ opacity: 0.7 }}
        />
        {t("noContactsAdded")}
        <Button
          variant="primary"
          title={t("addContactButtonTitle")}
          size="md"
          onClick={handleOpenModal}
          iconStart="add-recepient"
          iconStartType="stroke"
        />
      </div>
    );
  }

  if (isNoData && isTrash && !isSearch) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={manCleaning}
          alt="manCleaning"
          width={450}
          height={280}
          style={{ opacity: 0.7 }}
        />
        {t("noTrashBinItems")}
      </div>
    );
  }

  if (isNoData && !isSearch) {
    return (
      <div
        className={cs([
          styles.NoTableData,
          isRequestSigning && styles.requestSigning,
        ])}
      >
        <img
          src={girlChating}
          alt="girlChating"
          width={450}
          height={280}
          style={{ opacity: 0.7 }}
        />
        {t("noDocsAdded")}
      </div>
    );
  }

  if (isSearch && isNoFilteredData) {
    return (
      <div
        className={cs([
          styles.NoTableData,
          isContacts && styles.contacts,
          (isSigningsSent || isSigningsDraft) && styles.signings,
          isRequestSigning && styles.requestSigning,
        ])}
      >
        <img src={searchBig} alt="search" width={65} height={65} />
        {t("noResults")}
      </div>
    );
  }

  if (
    isNoFilteredData &&
    selectedTypes.filter((el) => ["FOLDER", "PDF"].includes(el)).length > 0
  ) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={girlChating}
          alt="girlChating"
          width={450}
          height={280}
          style={{ opacity: 0.7 }}
        />
        {selectedTypes.includes("FOLDER")
          ? t("noFolders")
          : t("noOriginalFiles")}
      </div>
    );
  }

  if (isNoFilteredData && selectedTypes.includes("PDF_CERTIFIED")) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={femaleCandidate}
          alt="femaleCandidate"
          width={450}
          height={325}
          style={{ opacity: 0.3 }}
        />
        {t("noCertifiedFiles")}
      </div>
    );
  }

  return <></>;
};
