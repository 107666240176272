import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import {
  setEmail as setStoreEmail,
  setForgotEmail,
  setFirstName as setStoreFirstName,
  setLastName as setStoreLastName,
} from "store/auth";
import { Auth } from "api";

import { Input, Button } from "components/UI";
import { AppleGoogleLogin } from "components";
import { PATHES } from "constants/pathes";
import { TERMS_AND_POLICY_LINK } from "constants/index";
import { validateEmail } from "utils";

import styles from "./styles.module.scss";

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Auth", { keyPrefix: "SignUp" });
  const { t: tG } = useTranslation("General");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const checkValidity = () => {
    const emailValidationErrors = email && validateEmail(email);
    if (emailValidationErrors) {
      setError(emailValidationErrors[0]);
    }
  };

  const handleChangeEmail = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmail(value);
  };

  const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setLastName(e.target.value);
  };

  const handleClearEmail = () => {
    setError("");
    setEmail("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkValidity();
    if (email && !error) {
      dispatch(setForgotEmail(""));
      dispatch(setStoreEmail(email));
      dispatch(setStoreFirstName(firstName));
      dispatch(setStoreLastName(lastName));
      await Auth.checkEmail({ email });
      navigate(PATHES.ENTER_CODE);
    }
  };

  return (
    <form className={styles.SignUp}>
      <h1 className={styles.title}>{t("title")}</h1>
      <AppleGoogleLogin />
      <div className={styles.info}>{t("or")}</div>
      <Input
        type="email"
        label={tG("emailLabel")}
        onChange={handleChangeEmail}
        onBlur={checkValidity}
        value={email}
        name="email"
        error={error}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
        placeholder={tG("emailPlaceholder")}
      />
      <Input
        type="text"
        placeholder="Enter first name"
        name="fisrtName"
        label="First name"
        onChange={handleChangeFirstName}
        value={firstName}
      />

      <Input
        type="text"
        placeholder="Enter last name"
        name="lastName"
        label="Last name"
        onChange={handleChangeLastName}
        value={lastName}
      />
      <div className={styles.agreement}>
        {t("agreement")}{" "}
        <NavLink
          to={TERMS_AND_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {t("termsOfService")}
        </NavLink>{" "}
        {t("and")}{" "}
        <NavLink
          to={TERMS_AND_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {t("privacyPolicy")}.
        </NavLink>
      </div>
      <Button
        variant="primary"
        title={t("submitButtonTitle")}
        onClick={handleSubmit}
        isDisabled={!email || !!error || !firstName || !lastName}
        className={styles.submitButton}
      />
      <div className={styles.footer}>
        <p className={styles.text}>{t("alreadyHaveAccount")}</p>
        <Button
          variant="textBlack"
          size="sm"
          title={t("logInButtonTitle")}
          onClick={() => navigate(PATHES.SIGN_IN)}
          className={styles.logInButton}
          iconStart="login"
          iconStartType="stroke"
        />
      </div>
    </form>
  );
};
