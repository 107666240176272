import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";

import { useAppDispatch } from "store";
import { setVerificationId, setPasswordVerificationId } from "store/settings";
import { setEditableEmail } from "store/auth";

import mainLogo from "assets/img/main-logo.svg";

import styles from "./styles.module.scss";

export const EmailChangedModal: FC = () => {
  const { t } = useTranslation("Modals", { keyPrefix: "EmailChanged" });
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(setVerificationId(""));
    dispatch(setPasswordVerificationId(""));
    dispatch(setEditableEmail(""));
  };

  return (
    <div className={styles.EmailChangedModal}>
      <img src={mainLogo} alt="logo" width="120" height="32" />

      <div className={styles.info}>
        <h2 className={styles.title}>{t("title")}</h2>
        <p className={styles.text}>{t("text")}</p>
      </div>
      <Button
        variant="outlined"
        title={t("submitButtonTitle")}
        onClick={handleSubmit}
        className={styles.submitButton}
      />
    </div>
  );
};
