import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const FolderEmptyIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 14 / 14; // width / height
  let height = 14;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8337 6.4165V9.9165C12.8337 12.2498 12.2503 12.8332 9.91699 12.8332H4.08366C1.75033 12.8332 1.16699 12.2498 1.16699 9.9165V4.08317C1.16699 1.74984 1.75033 1.1665 4.08366 1.1665H4.95866C5.83366 1.1665 6.02616 1.42317 6.35866 1.8665L7.23366 3.03317C7.45532 3.32484 7.58366 3.49984 8.16699 3.49984H9.91699C12.2503 3.49984 12.8337 4.08317 12.8337 6.4165Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default FolderEmptyIcon;
