import { FC } from "react";
import { calculateSideByRatio } from "utils";
import { IconProps } from "./index";

const GoogleIcon: FC<IconProps> = ({
  className,
  //   color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 18 / 18; // width / height
  let height = 18;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_15763_6982" fill="white">
        <path d="M9.18359 7.36365V10.8491H14.126C13.909 11.97 13.2577 12.9191 12.2809 13.5573L15.2614 15.8236C16.998 14.2528 17.9998 11.9455 17.9998 9.20463C17.9998 8.56647 17.9414 7.95278 17.8328 7.36375L9.18359 7.36365Z" />
      </mask>
      <path
        d="M9.18359 7.36365V10.8491H14.126C13.909 11.97 13.2577 12.9191 12.2809 13.5573L15.2614 15.8236C16.998 14.2528 17.9998 11.9455 17.9998 9.20463C17.9998 8.56647 17.9414 7.95278 17.8328 7.36375L9.18359 7.36365Z"
        fill="#4285F4"
      />
      <path
        d="M9.18359 7.36365L9.18364 3.58412L5.40407 3.58408V7.36365H9.18359ZM9.18359 10.8491H5.40407V14.6286H9.18359V10.8491ZM14.126 10.8491L17.8366 11.5675L18.7076 7.06956H14.126V10.8491ZM12.2809 13.5573L10.2137 10.3932L5.72896 13.3232L9.99324 16.5658L12.2809 13.5573ZM15.2614 15.8236L12.9737 18.8322L15.4706 20.7308L17.7969 18.6265L15.2614 15.8236ZM17.8328 7.36375L21.5498 6.67868L20.9794 3.58425L17.8329 3.58422L17.8328 7.36375ZM5.40407 7.36365V10.8491H12.9631V7.36365H5.40407ZM9.18359 14.6286H14.126V7.06956H9.18359V14.6286ZM10.4154 10.1306C10.4044 10.1878 10.3867 10.2242 10.3667 10.2535C10.346 10.2839 10.303 10.3348 10.2137 10.3932L14.3481 16.7214C16.1475 15.5458 17.4177 13.7311 17.8366 11.5675L10.4154 10.1306ZM9.99324 16.5658L12.9737 18.8322L17.5491 12.8151L14.5686 10.5487L9.99324 16.5658ZM17.7969 18.6265C20.4091 16.2636 21.7793 12.8955 21.7793 9.20463H14.2203C14.2203 10.9955 13.5868 12.242 12.726 13.0207L17.7969 18.6265ZM21.7793 9.20463C21.7793 8.33443 21.6996 7.49146 21.5498 6.67868L14.1159 8.04881C14.1832 8.41409 14.2203 8.79851 14.2203 9.20463H21.7793ZM17.8329 3.58422L9.18364 3.58412L9.18355 11.1432L17.8328 11.1433L17.8329 3.58422Z"
        fill="#4285F4"
        mask="url(#path-1-inside-1_15763_6982)"
      />
      <path
        d="M4.037 10.7131L3.36478 11.2174L0.985352 13.0337C2.49647 15.971 5.59362 18.0001 9.18356 18.0001C11.6631 18.0001 13.7419 17.1983 15.2614 15.8238L12.2809 13.5574C11.4627 14.0974 10.4191 14.4247 9.18356 14.4247C6.79583 14.4247 4.76714 12.8456 4.04075 10.7183L4.037 10.7131Z"
        fill="#34A853"
      />
      <path
        d="M0.98508 4.96643C0.358958 6.17728 0 7.54366 0 8.99999C0 10.4563 0.358958 11.8227 0.98508 13.0335C0.98508 13.0417 4.04077 10.7099 4.04077 10.7099C3.8571 10.1699 3.74854 9.59724 3.74854 8.99989C3.74854 8.40255 3.8571 7.82986 4.04077 7.28986L0.98508 4.96643Z"
        fill="#FBBC05"
      />
      <path
        d="M9.18375 3.58373C10.5363 3.58373 11.7385 4.0419 12.6986 4.92554L15.3284 2.34832C13.7338 0.891991 11.6634 0.00012207 9.18375 0.00012207C5.59381 0.00012207 2.49647 2.02102 0.985352 4.96646L4.04095 7.29009C4.76725 5.1628 6.79602 3.58373 9.18375 3.58373Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
