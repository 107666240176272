import i18next from "i18next";

import en from "../assets/locales/en.json";

i18next.addResourceBundle("en", "en", en);

export const authErrors = (errMessage: string | string[]) => {
  const { t } = i18next;
  switch (Array.isArray(errMessage) ? errMessage[0] : errMessage) {
    case "api.errors.auth.signup.verificationCodeExpired":
    case "api.errors.auth.forgotPassword.verificationCodeExpired":
    case "api.errors.settings.changeEmailVerificationCodeExpired":
      return t("Utils.Errors.AuthErrors.verificationCodeExpired");
    case "api.errors.auth.signup.wrongVerificationCode":
    case "api.errors.auth.forgotPassword.wrongVerificationCode":
    case "api.errors.settings.changeEmailWrongVerificationCode":
      return t("Utils.Errors.AuthErrors.wrongVerificationCode");
    case "api.errors.auth.signup.verificationCodeRecentlySent":
    case "api.errors.auth.forgotPassword.verificationCodeRecentlySent":
    case "api.errors.settings.changeEmailVerificationCodeRecentlySent":
    case "api.errors.account.emailVerificationCodeRecentlySent":
      return t("Utils.Errors.AuthErrors.verificationCodeRecentlySent");
    case "api.errors.auth.forgotPassword.verificationCodeIsUsed":
    case "api.errors.settings.changeEmailVerificationCodeIsUsed":
      return t("Utils.Errors.AuthErrors.verificationCodeIsUsed");
    case "api.errors.auth.signup.invalidPassword":
    case "api.errors.auth.resetPassword.invalidPassword":
    case "api.errors.settings.wrongPassword":
      return t("Utils.Errors.AuthErrors.invalidPassword");
    case "api.errors.auth.signup.invalidEmail":
    case "api.errors.auth.login.invalidEmail":
    case "api.errors.forgotPassword.invalidEmail":
    case "api.errors.forgotPassword.emailNotFound":
    case "api.errors.settings.wrongEmail":
    case "api.errors.settings.invalidEmail":
      return t("Utils.Errors.AuthErrors.invalidEmail");
    case "api.errors.login.incorrectEmailOrPassword":
      return t("Utils.Errors.AuthErrors.incorrectEmailOrPassword");
    case "api.errors.auth.signup.userExists":
      return t("Utils.Errors.AuthErrors.userExists");
    case "api.errors.auth.userWithEmailAlreadyExists":
      return t("Utils.Errors.AuthErrors.userWithEmailAlreadyExists");
    case "api.errors.auth.resetPassword.invalidVerification":
    case "api.errors.settings.wrongPasswordVerification":
    case "api.errors.auth.login.googleLoginInvalidToken":
    case "api.errors.auth.login.appleLoginInvalidToken":
      return t("Utils.Errors.AuthErrors.invalidVerification");
    case "api.errors.settings.passwordVerificationThrottling":
      return t("Utils.Errors.AuthErrors.passwordVerificationThrottling");
    case "Verification code expired":
      return t("Utils.Errors.AuthErrors.expired");
    case "tryAgainLater":
      return t("Utils.Errors.AuthErrors.tryAgainLater");

    default:
      return errMessage.toString();
  }
};

export const dashboardErrors = (errMessage: string) => {
  const { t } = i18next;
  switch (errMessage) {
    case "api.errors.userFiles.totalFilesSizeExceedsLimitation":
      return t("Utils.Errors.DashboardErrors.totalFilesSizeExceedsLimitation");
    case "api.errors.folders.userDocumentNotFound":
      return t("Utils.Errors.DashboardErrors.userDocumentNotFound");
    case "api.errors.folders.api.errors.files.documentUpdatingIsBlocked":
      return t("Utils.Errors.DashboardErrors.documentUpdatingIsBlocked");
    case "api.errors.userFiles.fileSizeExceedsLimitation":
      return t("Utils.Errors.DashboardErrors.toastFileSizeError");
    case "noSignatureData":
      return t("Utils.Errors.DashboardErrors.noSignatureData");
    case "api.errors.sharedDocuments.conflictingInstantJson":
      return t("Utils.Errors.DashboardErrors.conflictingInstantJson");
    case "api.errors.subscription.forbiddenToBuy":
      return t("Utils.Errors.DashboardErrors.forbiddenToBuy");
    case "api.errors.subscription.cancelForbidden":
      return t("Utils.Errors.DashboardErrors.cancelForbidden");
    case "api.errors.paymentMethod.notFound":
      return t("Utils.Errors.DashboardErrors.paymentMethodNotFound");
    case "api.errors.subscription.retryNeedsConfirmation":
      return t("Utils.Errors.DashboardErrors.retryNeedsConfirmation");
    case "api.errors.subscription.retryForbidden":
      return t("Utils.Errors.DashboardErrors.retryForbidden");
    case "api.errors.contacts.contactWithEmailExists":
      return t("Utils.Errors.DashboardErrors.contactWithEmailExists");

    default:
      return errMessage;
  }
};

export const stripeErrors = (errMessage: string) => {
  const { t } = i18next;
  switch (errMessage) {
    case "insufficient_funds":
      return t("Subscription.StripeErrors.insufficient_funds");
    case "expired_card":
      return t("Subscription.StripeErrors.expired_card");
    case "card_velocity_exceeded":
      return t("Subscription.StripeErrors.card_velocity_exceeded");
    case "incorrect_number":
    case "invalid_number":
      return t("Subscription.StripeErrors.wrong_number");
    case "incorrect_cvc":
    case "invalid_cvc":
      return t("Subscription.StripeErrors.wrong_cvc");
    case "invalid_expiry_month":
    case "invalid_expiry_year":
      return t("Subscription.StripeErrors.wrong_date");
    case "authentication_required":
      return t("Subscription.StripeErrors.3d_secure_error");
    case "generic_decline":
      return t("Subscription.StripeErrors.generic_decline");
    case "payment_failed":
      return t("Subscription.StripeErrors.payment_failed");
    default:
      return t("Subscription.StripeErrors.defaultError");
  }
};
