import { FC, Fragment, useEffect } from "react";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  trashBin,
  requestSigning,
} from "store";
import { setCurrentFolder as setFoldersCurrentFolder } from "store/folders";
import { getFolderParents as getFoldersFolderParents } from "store/folders/thunks";
import { setCurrentFolder as setTrashBinCurrentFolder } from "store/trashBin";
import { getFolderParents as getTrashBinFolderParents } from "store/trashBin/thunks";
import { setCurrentFolder as setRequestSigningCurrentFolder } from "store/requestSigning";
import { getFolderParents as getRequestSigningFolderParents } from "store/requestSigning/thunks";

import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type BreadcrumbsDocsProps = {
  className?: string;
  rootFolderName?: string;
  sliceSource: "folders" | "trashBin" | "requestSigning";
};

export const BreadcrumbsDocs: FC<BreadcrumbsDocsProps> = ({
  className,
  rootFolderName = "My docs",
  sliceSource,
}) => {
  const dispatch = useAppDispatch();
  const sliceStates = {
    folders: {
      state: useAppSelector(folders),
      actions: {
        setCurrentFolder: setFoldersCurrentFolder,
        getFolderParents: getFoldersFolderParents,
      },
    },
    trashBin: {
      state: useAppSelector(trashBin),
      actions: {
        setCurrentFolder: setTrashBinCurrentFolder,
        getFolderParents: getTrashBinFolderParents,
      },
    },
    requestSigning: {
      state: useAppSelector(requestSigning),
      actions: {
        setCurrentFolder: setRequestSigningCurrentFolder,
        getFolderParents: getRequestSigningFolderParents,
      },
    },
  };
  const { setCurrentFolder, getFolderParents } =
    sliceStates[sliceSource].actions;
  const { currentFolder, currentFolderParents } =
    sliceStates[sliceSource].state;
  const { rootFolder } = useAppSelector(folders);
  const { parents, folder } = currentFolderParents || {};
  const reversedParents = parents && [...parents].reverse();
  const isRoot = rootFolder?.id === currentFolder;

  useEffect(() => {
    if (currentFolder && (!isRoot || sliceSource !== "trashBin")) {
      dispatch(getFolderParents(currentFolder));
    }
  }, [currentFolder, dispatch, getFolderParents, isRoot, sliceSource]);

  const renderArrow = () => (
    <Icon
      name="chevron-left-empty"
      className={styles.arrow}
      color={palette.grey70}
      size={16}
    />
  );

  const goUp = () => {
    reversedParents &&
      parents.length > 3 &&
      dispatch(setCurrentFolder(reversedParents[parents.length - 2].id));
  };

  const goTo = (id: string) => {
    dispatch(setCurrentFolder(id));
  };

  return (
    <nav className={cs([styles.BreadcrumbsDocs, className])}>
      {reversedParents &&
        (!isRoot || sliceSource !== "trashBin") &&
        (parents.length > 3 ? (
          <>
            <>
              <div
                className={styles.link}
                onClick={() => goTo(reversedParents[0].id)}
              >
                {reversedParents[0].parentFolderId === null
                  ? rootFolderName
                  : reversedParents[0].name}
              </div>
              {renderArrow()}
            </>
            <>
              <div className={styles.link} onClick={goUp}>
                ...
              </div>
              {renderArrow()}
            </>
            {reversedParents.slice(parents.length - 1).map((el, index) => (
              <Fragment key={index}>
                <div className={styles.link} onClick={() => goTo(el.id)}>
                  {el.name}
                </div>
                {renderArrow()}
              </Fragment>
            ))}
          </>
        ) : (
          reversedParents.map((el, index) => (
            <Fragment key={index}>
              <div className={styles.link} onClick={() => goTo(el.id)}>
                {el.parentFolderId === null ? rootFolderName : el.name}
              </div>
              {renderArrow()}
            </Fragment>
          ))
        ))}
      <div className={cs([styles.link, styles.active])}>
        {isRoot ? rootFolderName : folder?.name}
      </div>
    </nav>
  );
};
