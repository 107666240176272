import { AxiosError, isAxiosError } from "axios";

import { toastError, toastInfo, dashboardErrors } from "utils";
import { IHttpRejection, IParent, IPdfFile } from "types";

import { $http } from "./instance";

export interface ICreatePDF {
  parentFolderId: string;
  fileSize: number;
  documentName?: string;
}

export interface IUpdatePDF {
  documentId: string;
  fileSize: number;
}

export interface ISignPDFReqest {
  documentName?: string;
}
export interface IGetPdfDocumentResponse {
  document: IPdfFile;
  parentFolder: IParent;
}

export const PDF = {
  getPdfDocument: async (
    id: string,
  ): Promise<IGetPdfDocumentResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/documents/${id}`);
      return res.data;
    } catch (e) {
      console.error(e);
      // if (isAxiosError(e)) {
      //   const error = e as AxiosError<IHttpRejection>;
      //   const { message } = error.response?.data || {};
      //   message && toastError(authErrors(message));
      // } else {
      //   toastError("Something went wrong");
      // }
    }
  },
  getSignedPdfDocument: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/documents/signed/${id}`);
      return res.data;
    } catch (e) {
      console.error(e);
      // if (isAxiosError(e)) {
      //   const error = e as AxiosError<IHttpRejection>;
      //   const { message } = error.response?.data || {};
      //   message && toastError(authErrors(message));
      // } else {
      //   toastError("Something went wrong");
      // }
    }
  },
  getSignatureInfo: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(
        `/clients/documents/signed/${id}/signature-info`,
      );
      return res.data;
    } catch (e) {
      console.error(e);
      toastInfo(dashboardErrors("noSignatureData"));
    }
  },
  createPdfDocument: async (data: ICreatePDF): Promise<any> => {
    try {
      const res = await $http.post(`/clients/documents`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  updatePdfDocument: async (data: IUpdatePDF): Promise<any> => {
    try {
      const res = await $http.put(`/clients/documents`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  signPdfDocument: async (
    id: string,
    data: ISignPDFReqest,
  ): Promise<IGetPdfDocumentResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/documents/${id}/sign`, data);
      return res.data;
    } catch (e) {
      console.log("error:", e);
      // if (isAxiosError(e)) {
      //   const error = e as AxiosError<IHttpRejection>;
      //   const { message } = error.response?.data || {};
      //   message
      //     ? toastError(authErrors(message))
      //     : toastError(
      //         Array.isArray(error.message) ? error.message[0] : error.message,
      //       );
      // }
    }
  },
  signWithFilePdfDocument: async (
    id: string,
    data: ISignPDFReqest,
  ): Promise<IGetPdfDocumentResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/documents/${id}/sign-with-file`,
        data,
      );
      return res.data;
    } catch (e) {
      console.log("error:", e);
      // if (isAxiosError(e)) {
      //   const error = e as AxiosError<IHttpRejection>;
      //   const { message } = error.response?.data || {};
      //   message
      //     ? toastError(authErrors(message))
      //     : toastError(
      //         Array.isArray(error.message) ? error.message[0] : error.message,
      //       );
      // }
    }
  },
};
