import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection, IAuthUser } from "types";

import { $http } from "./instance";

export interface IVerifyPasswordRequest {
  password: string;
}

export interface IVerifyPasswordResponse {
  verificationId: string;
}

export interface IChangePasswordRequest {
  password: string;
  verificationId: string;
}
export interface IChangePasswordResponse {
  message: string;
}

export interface IVerifyCurrentEmailRequest {
  email: string;
  verificationId: string;
}

export interface IVerifyCurrentEmailResponse {
  verificationId: string;
}

export interface IConfirmCurrentEmailRequest {
  verificationId: string;
  verificationCode: string;
}

export interface IConfirmCurrentEmailResponse {
  verificationId: string;
}

export interface IVerifyNewEmailRequest {
  email: string;
  verificationId: string;
}

export interface IVerifyNewEmailResponse {
  verificationId: string;
}

export interface IChangeEmailRequest {
  verificationId: string;
  verificationCode: string;
}
export interface IChangeEmailResponse {
  message: string;
}

export interface IChangeNameRequest {
  firstName?: string;
  lastName?: string;
}

export const Settings = {
  verifyPassword: async (
    data: IVerifyPasswordRequest,
  ): Promise<IVerifyPasswordResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/settings/verify-password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  changePassword: async (
    data: IChangePasswordRequest,
  ): Promise<IChangePasswordResponse | undefined> => {
    try {
      const res = await $http.put(`/clients/settings/password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  verifyCurrentEmail: async (
    data: IVerifyCurrentEmailRequest,
  ): Promise<IVerifyCurrentEmailResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/settings/email/verify-current-email`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  confirmCurrentEmail: async (
    data: IConfirmCurrentEmailRequest,
  ): Promise<IConfirmCurrentEmailResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/settings/email/confirm-current-email`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  verifyNewEmail: async (
    data: IVerifyNewEmailRequest,
  ): Promise<IVerifyNewEmailResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/settings/email/verify-new-email`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  changeEmail: async (
    data: IChangeEmailRequest,
  ): Promise<IChangeEmailResponse | undefined> => {
    try {
      const res = await $http.put(`/clients/settings/email`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  changeName: async (
    data: IChangeNameRequest,
  ): Promise<IAuthUser | undefined> => {
    try {
      const res = await $http.put(`/clients/settings/user-info`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
};
