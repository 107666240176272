import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./assets/locales/en.json";
import fr from "./assets/locales/fr.json";
import de from "./assets/locales/de.json";
import es from "./assets/locales/es.json";
import pl from "./assets/locales/pl.json";
import pt from "./assets/locales/pt.json";
import uk from "./assets/locales/uk.json";

const resources = {
  en,
  fr,
  de,
  es,
  pl,
  pt,
  uk,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  ns: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
