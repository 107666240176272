import i18next from "i18next";

import { MenuItem, SigningsType, TSharedDocumentStatus } from "types";

import en from "../assets/locales/en.json";

i18next.addResourceBundle("en", "en", en);

export const getSigningsItems = ({
  selectedItemsCount,
  signingsType,
  signingsStatus,
  isAdditional,
  isAllSelectedItemsInProgress,
  isAllSelectedItemsNeedToSign,
  isSomeSelectedItemsInProgress,
  isSelectedItemsCompleted,
  isSelectedItemsVoided,
  isAllShowed,
}: {
  selectedItemsCount: number;
  signingsType: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
  isAdditional?: boolean;
  isAllShowed?: boolean;
  isAllSelectedItemsInProgress?: boolean;
  isAllSelectedItemsNeedToSign?: boolean;
  isSomeSelectedItemsInProgress?: boolean;
  isSelectedItemsCompleted?: boolean;
  isSelectedItemsVoided?: boolean;
}): MenuItem[] => {
  if (signingsType === "draft") {
    if (selectedItemsCount === 1 && isAllShowed) {
      return ["edit", "trash"];
    }
    if (selectedItemsCount === 1 && isAdditional) {
      return ["trash"];
    }
    if (selectedItemsCount === 1 && !isAdditional) {
      return ["edit"];
    }
    if (selectedItemsCount > 1) {
      return ["trash"];
    }
    return ["edit", "trash"];
  }
  if (
    signingsType === "sent" &&
    !signingsStatus &&
    isAllSelectedItemsInProgress
  ) {
    return [];
  }
  if (
    signingsType === "inbox" &&
    !signingsStatus &&
    isAllSelectedItemsNeedToSign
  ) {
    return [];
  }
  if (["sent", "inbox"].includes(signingsType) && !signingsStatus) {
    return ["trash"];
  }
  if (
    signingsType === "sent" &&
    signingsStatus === "IN_PROGRESS" &&
    isAllShowed
  ) {
    return ["download", "close-in-circle"];
  }
  if (
    signingsType === "sent" &&
    signingsStatus === "IN_PROGRESS" &&
    isAdditional
  ) {
    return ["close-in-circle"];
  }
  if (
    signingsType === "sent" &&
    signingsStatus === "IN_PROGRESS" &&
    !isAdditional
  ) {
    return ["download"];
  }
  if (signingsType === "sent" && isAllShowed && selectedItemsCount > 1) {
    if (signingsStatus === "COMPLETED" || isSelectedItemsCompleted) {
      return ["move", "download", "trash"];
    }
    return ["download", "trash"];
  }
  if (
    signingsType === "inbox" &&
    signingsStatus === "NEED_TO_SIGN" &&
    (isAdditional || isAllShowed)
  ) {
    return ["sign", "close-in-circle"];
  }
  if (["sent", "inbox"].includes(signingsType) && isAllShowed) {
    if (signingsStatus === "COMPLETED") {
      return ["move", "download", "rename", "trash"];
    }
    return ["download", "trash"];
  }
  if (["sent", "inbox"].includes(signingsType) && isAdditional) {
    return ["trash"];
  }
  if (["sent", "inbox"].includes(signingsType) && !isAdditional) {
    // if (isSelectedItemsInProgress) {
    //   return ["download", "close-in-circle"];
    // }
    if (signingsStatus === "COMPLETED") {
      return ["move", "download", "rename"];
    }
    return ["download"];
  }
  return [];
};

export const getSigningsDate = (signingsType: SigningsType) => {
  switch (signingsType) {
    case "sent":
      return "sentAt";
    case "inbox":
      return "receivedAt";
    case "draft":
      return "updatedAt";
    default:
      return "sentAt";
  }
};

export const getSigningsDateTitle = (signingsType: SigningsType) => {
  const { t } = i18next;
  switch (signingsType) {
    case "sent":
      return t("Utils.UseSignings.getSigningsDateTitle.sent");
    case "inbox":
      return t("Utils.UseSignings.getSigningsDateTitle.received");
    case "draft":
      return t("Utils.UseSignings.getSigningsDateTitle.modificationDate");
    default:
      return t("Utils.UseSignings.getSigningsDateTitle.sent");
  }
};
