import { useState, MouseEvent } from "react";

export const useTableRow = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isRowMenu, setIsRowMenu] = useState<boolean>(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsClicked(true);
    setPoints({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleOver = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
    setIsRowMenu(false);
    setIsClicked(false);
  };

  const handleSetRowMenu = (e: MouseEvent<HTMLElement>) => {
    setIsRowMenu(true);
    setPoints({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleClearClicked = () => {
    setIsClicked(false);
  };

  return {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  };
};
