import { FC, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { Icon, Button } from "components/UI";

import { PATHES } from "constants/pathes";
import { cs, toastError, toastSuccess } from "utils";
import { Subscriptions } from "api";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import styles from "./styles.module.scss";

export const PaymentCard: FC = () => {
  const { t } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPayment",
  });
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!elements || !stripe) return;

    setIsLoading(true);

    const { error: submitError }: any = await elements.submit();
    if (submitError) {
      console.log("submitError:", submitError);
      setIsLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      setIsLoading(false);
      toastError(error.message || "Something went wrong");
      console.error(error);
      return;
    }

    if (paymentMethod?.id && paymentMethod?.card) {
      const res = await Subscriptions.createStripeCard({
        id: paymentMethod.id,
        last4: paymentMethod.card.last4,
        expirationMonth: paymentMethod.card.exp_month,
        expirationYear: paymentMethod.card.exp_year,
      });

      if (res?.message) {
        setIsLoading(false);
        navigate(PATHES.SUBSCRIPTION_PAYMENT_METHOD);
        toastSuccess(t("paymentMethodToastSuccess"));
      }
    }

    setIsLoading(false);
  };

  return (
    <form className={styles.PaymentCard} onSubmit={handleSubmit}>
      <Link to={PATHES.SIGN_IN} className={styles.link}>
        <img src={mainLogoBlack} alt="main-logo" width="124" height="33" />
      </Link>
      <Icon
        name="chevron-left-empty"
        action={handleGoBack}
        className={styles.backButton}
      />
      <div className={styles.fieldLong}>
        <h2 className={cs([styles.title, styles.update])}>
          {t("titleUpdate")}
        </h2>
      </div>
      <PaymentElement className={styles.paymentElement} />
      <div className={styles.fieldLong}>
        <Button
          variant="primary"
          type="submit"
          title={t("saveButtonTitle")}
          onClick={handleSubmit}
          className={styles.submitButton}
          isDisabled={!stripe || isLoading}
        />
      </div>
    </form>
  );
};
