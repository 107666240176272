import { FC, useCallback, MouseEvent } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch, dashboard } from "store";
import { setCurrentFolder, setDestinationFolder } from "store/folders";
import { setSearch } from "store/dashboard";

import { ContextMenu, RowActions, DocImage, DocBreadcrumbs } from "components";
import { Checkbox } from "components/UI";

import { cs, getSearchItems, folderItemName } from "utils";
import { IGlobalSearchDoc, SIGNING_STATUSES } from "types";
import { useTableRow } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

interface IGlobalSearchTableRowProps {
  onChange: () => void;
  item: IGlobalSearchDoc;
}

export const GlobalSearchTableRow: FC<IGlobalSearchTableRowProps> = ({
  onChange,
  item,
}) => {
  const {
    type,
    date,
    previewUrl,
    id,
    name,
    parents,
    pagesNumber,
    contentsNumber,
    status,
  } = item;
  const { dateFormat, timeFormat, selectedSearchItems } =
    useAppSelector(dashboard);

  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick: onRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpenFolder = useCallback(() => {
    dispatch(setCurrentFolder(id));
    dispatch(setSearch(""));
  }, [dispatch, id]);

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    onRightClick(e);
    dispatch(setDestinationFolder(null));
  };

  const handleCheck = () => {
    onChange();
  };

  const handleSelectRow = () => {
    onChange();
  };

  const handleOpen = async () => {
    if (type === "FOLDER") {
      handleOpenFolder();
    } else {
      type === "PDF"
        ? navigate(`${PATHES.DETAILED_VIEW}/${id}`)
        : navigate(`${PATHES.DETAILED_VIEW}/certified/${id}`);
    }
  };

  const isSelected = selectedSearchItems.some((el) => el.id === id);

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={handleSelectRow}
        className={cs([
          styles.GlobalSearchTableRow,
          isSelected && styles.selected,
          type === "PDF_CERTIFIED" && styles.certified,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={handleCheck} isChecked={isSelected} />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name} onClick={handleOpen}>
            <DocImage type={type} previewUrl={previewUrl} />
            <div className={styles.nameWithDir}>
              {parents && <DocBreadcrumbs parents={parents} />}
              <div className={styles.textName}>{name}</div>
            </div>
          </div>
          <RowActions
            actions={["move", "rename"]}
            additionalActions={["trash"]}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered && type === "FOLDER"}
          />

          <RowActions
            actions={["move", "download", "rename"]}
            additionalActions={["trash"]}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered && ["PDF_CERTIFIED", "PDF"].includes(type)}
          />
        </div>
        <div className={styles.td}>
          {status ? (
            <div className={cs([styles.status, styles[status]])}>
              {SIGNING_STATUSES[status]}
            </div>
          ) : (
            folderItemName[type]
          )}
        </div>
        <div className={styles.td}>
          {`${pagesNumber || contentsNumber || 0} ${
            type === "FOLDER"
              ? `item${contentsNumber === 1 ? "" : "s"}`
              : `page${pagesNumber === 1 ? "" : "s"}`
          } `}
        </div>
        <div className={styles.td}>
          {dayjs(date).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && (
          <ContextMenu
            theme="light"
            items={getSearchItems({ selectedItems: selectedSearchItems })}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
