import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setAuthenticated, setUser } from "store/auth";
import { Auth, setSession, removeSession } from "api";
import { getPasswordIcon } from "utils";

import { Input, Button } from "components/UI";
import { AppleGoogleLogin } from "components";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const SignIn: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Auth", { keyPrefix: "SignIn" });
  const { t: tG } = useTranslation("General");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    removeSession();
    e.preventDefault();
    if (!email.includes("@")) {
      setIsError(true);
    } else {
      const res = await Auth.signIn({
        password,
        email,
      });

      if (res?.accessToken) {
        const { accessToken, refreshToken, user } = res;
        dispatch(setUser(user));
        setSession({
          accessToken,
          refreshToken,
          user,
        });
        dispatch(setAuthenticated(true));
        navigate(PATHES.HOME);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  const handleClearEmail = () => {
    setIsError(false);
    setEmail("");
  };

  return (
    <form className={styles.SignIn} autoComplete="off">
      <h1 className={styles.title}>{t("title")}</h1>
      <AppleGoogleLogin />
      <div className={styles.info}>{t("or")}</div>
      <Input
        type="email"
        label={tG("emailLabel")}
        onChange={handleChangeEmail}
        value={email}
        name="email"
        error={isError}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
        placeholder={tG("emailPlaceholder")}
      />
      <Input
        type={isPasswordVisible ? "text" : "password"}
        label={tG("passwordLabel")}
        onChange={handleChangePassword}
        value={password}
        error={isError ? t("passwordError") : ""}
        name="password"
        iconEnd={getPasswordIcon(password, isPasswordVisible)}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
        placeholder={tG("passwordPlaceholder")}
      />
      <Button
        variant="textBlack"
        size="sm"
        title={t("forgotButtonTitle")}
        onClick={() => navigate(PATHES.FORGOT_PASSWORD)}
        className={styles.forgotButton}
      />
      <Button
        type="submit"
        variant="primary"
        title={t("submitButtonTitle")}
        onClick={handleSubmit}
        isDisabled={!email || !password}
      />
      <div className={styles.footer}>
        <p className={styles.text}>{t("dontHaveAccount")}</p>
        <Button
          variant="textBlack"
          size="sm"
          title={t("signUpButtonTitle")}
          onClick={() => navigate(PATHES.SIGN_UP)}
          className={styles.signUpButton}
          iconStart="login"
        />
      </div>
    </form>
  );
};
