import {
  Routes,
  Route,
  Navigate,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { setAuthenticated } from "store/auth";

import {
  SignIn,
  SignUp,
  EnterCode,
  ForgotPassword,
  CreatePassword,
} from "components";
import { Icon } from "components/UI";

import { PATHES } from "constants/pathes";
import { sleep } from "utils";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import styles from "./styles.module.scss";

export const Public = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation("Public");
  const { isFromSettings } = useAppSelector(settings);

  const handleGoBack = async () => {
    if (isFromSettings && !pathname.startsWith(PATHES.ENTER_CODE)) {
      dispatch(setAuthenticated(true));
      await sleep(100);
      navigate(PATHES.SETTINGS);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.Public}>
      <div className={styles.wrap}>
        <Link to={PATHES.SIGN_IN} className={styles.link}>
          <img src={mainLogoBlack} alt="main-logo" width="124" height="33" />
        </Link>
        <div className={styles.main}>
          <Icon
            name="chevron-left-empty"
            action={handleGoBack}
            className={styles.backButton}
          />
          <Routes>
            <Route path={PATHES.SIGN_IN} element={<SignIn />} />
            <Route path={PATHES.SIGN_UP} element={<SignUp />} />
            <Route path={PATHES.ENTER_CODE} element={<EnterCode />} />
            <Route path={PATHES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={PATHES.CREATE_PASSWORD} element={<CreatePassword />} />
            <Route path={PATHES.TERMS} element={<div>{t("terms")}</div>} />
            <Route
              path={PATHES.PRIVACY_POLICY}
              element={<div>{t("privacyPolicy")}</div>}
            />
            <Route
              path="*"
              element={<Navigate to={PATHES.SIGN_IN} replace />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
