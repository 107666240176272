import { FC } from "react";
import { calculateSideByRatio } from "utils";
import { IconProps } from "./index";

const GPayIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 30 / 16; // width / height
  let height = 16;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 30 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.021 0H7.98C3.59 0 0 3.6 0 8C0 12.4 3.59 16 7.979 16H22.02C26.41 16 30 12.4 30 8C30 3.6 26.41 0 22.021 0Z"
        fill="white"
      />
      <path
        d="M22.021 0.648C23.007 0.648 23.964 0.844 24.866 1.228C26.6278 1.97906 28.0294 3.38432 28.776 5.148C29.158 6.052 29.354 7.012 29.354 8C29.354 8.988 29.158 9.948 28.775 10.852C28.0265 12.6144 26.6255 14.019 24.865 14.772C23.965 15.156 23.007 15.352 22.021 15.352H7.98C7.00218 15.3533 6.03428 15.156 5.135 14.772C3.37323 14.0209 1.97155 12.6157 1.225 10.852C0.842201 9.94997 0.645601 8.97989 0.647 8C0.647 7.012 0.843 6.052 1.226 5.148C1.97449 3.38562 3.37554 1.98099 5.136 1.228C6.03497 0.844167 7.00251 0.646849 7.98 0.648H22.02H22.021ZM22.021 0H7.98C3.59 0 0 3.6 0 8C0 12.4 3.59 16 7.979 16H22.02C26.41 16 30 12.4 30 8C30 3.6 26.41 0 22.021 0Z"
        fill="#706F6F"
      />
      <path
        d="M14.306 8.56796V10.988H13.54V5.01196H15.57C16.085 5.01196 16.524 5.18396 16.883 5.52796C17.25 5.87196 17.433 6.29196 17.433 6.78796C17.433 7.29596 17.25 7.71596 16.883 8.05596C16.528 8.39596 16.089 8.56396 15.57 8.56396H14.306V8.56796ZM14.306 5.74796V7.83196H15.586C15.89 7.83196 16.145 7.72796 16.344 7.52396C16.548 7.31996 16.651 7.07196 16.651 6.79196C16.6516 6.65678 16.6246 6.5229 16.5719 6.39845C16.5191 6.274 16.4416 6.16156 16.344 6.06796C16.2476 5.96422 16.13 5.88235 15.9993 5.82784C15.8685 5.77333 15.7276 5.74746 15.586 5.75196H14.306V5.74796ZM19.436 6.76396C20.003 6.76396 20.45 6.91596 20.776 7.21996C21.104 7.52396 21.267 7.93996 21.267 8.46796V10.988H20.537V10.42H20.505C20.19 10.888 19.767 11.12 19.241 11.12C18.79 11.12 18.415 10.988 18.111 10.72C17.9659 10.5975 17.8498 10.4441 17.7712 10.2712C17.6927 10.0982 17.6537 9.9099 17.657 9.71996C17.657 9.29596 17.817 8.95996 18.136 8.71196C18.455 8.45996 18.882 8.33596 19.412 8.33596C19.867 8.33596 20.242 8.41996 20.533 8.58796V8.41196C20.5343 8.28234 20.5067 8.15406 20.4522 8.03644C20.3977 7.91882 20.3177 7.81481 20.218 7.73196C20.0152 7.54791 19.7499 7.44782 19.476 7.45196C19.049 7.45196 18.71 7.63196 18.463 7.99596L17.789 7.57196C18.159 7.03196 18.71 6.76396 19.436 6.76396ZM18.446 9.73196C18.446 9.93196 18.531 10.1 18.702 10.232C18.87 10.364 19.069 10.432 19.297 10.432C19.62 10.432 19.907 10.312 20.158 10.072C20.41 9.83196 20.538 9.55196 20.538 9.22796C20.298 9.03996 19.967 8.94396 19.54 8.94396C19.229 8.94396 18.97 9.01996 18.762 9.16796C18.551 9.32396 18.447 9.51196 18.447 9.73196H18.446ZM25.432 6.89596L22.879 12.784H22.089L23.039 10.724L21.355 6.89596H22.189L23.4 9.83196H23.416L24.597 6.89596H25.431H25.432Z"
        fill="#3C4043"
      />
      <path
        d="M11.2604 8.08011C11.2604 7.83011 11.2374 7.59011 11.1954 7.36011H7.98535V8.68011H9.83435C9.75935 9.12011 9.51735 9.49411 9.14835 9.74311V10.6001H10.2484C10.8914 10.0031 11.2594 9.12211 11.2594 8.08011H11.2604Z"
        fill="#4285F4"
      />
      <path
        d="M9.14866 9.74298C8.84266 9.94998 8.44766 10.072 7.98566 10.072C7.09466 10.072 6.33766 9.46898 6.06766 8.65698H4.93066V9.53998C5.2144 10.1071 5.65034 10.584 6.1897 10.9174C6.72906 11.2509 7.35056 11.4276 7.98466 11.428C8.90866 11.428 9.68466 11.123 10.2477 10.599L9.14766 9.74298H9.14866Z"
        fill="#34A853"
      />
      <path
        d="M5.96039 8.00189C5.96039 7.77389 5.99839 7.55389 6.06739 7.34589V6.46289H4.93039C4.69069 6.94044 4.56636 7.46756 4.56739 8.00189C4.56739 8.55589 4.69839 9.07789 4.93039 9.54089L6.06639 8.65789C5.99564 8.44641 5.9595 8.22489 5.95939 8.00189H5.96039Z"
        fill="#FABB05"
      />
      <path
        d="M7.98564 5.9319C8.48964 5.9319 8.94064 6.1059 9.29764 6.4459L10.2726 5.4689C9.65472 4.8863 8.83485 4.56616 7.98564 4.5759C7.35164 4.57614 6.73021 4.75277 6.19087 5.08602C5.65152 5.41927 5.21552 5.89601 4.93164 6.4629L6.06664 7.3459C6.33764 6.5339 7.09464 5.9319 7.98664 5.9319H7.98564Z"
        fill="#E94235"
      />
    </svg>
  );
};

export default GPayIcon;
