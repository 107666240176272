import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
  auth,
  settings,
  subscription,
} from "store";
import {
  setPremiumModal,
  setTrialActivatedModal,
  setPremiumActivatedModal,
  setTrialExpireModal,
} from "store/subscription";
import { getUser } from "store/settings/thunks";

import { useEffectOnce, useSubscription } from "hooks";

export const usePremium = () => {
  const dispatch = useAppDispatch();
  const { editablePassword, editableEmail } = useAppSelector(auth);
  const { user } = useAppSelector(settings);
  const { currentSubscription } = useAppSelector(subscription);
  const { trialDaysLeft } = useSubscription();
  const storageShowPremium = sessionStorage.getItem("showPremium");
  const storageShowTrial = sessionStorage.getItem("showTrial");

  useEffectOnce(() => {
    if (
      storageShowPremium === "false" ||
      (user && user?.isPremium) ||
      editablePassword ||
      editableEmail
    ) {
      dispatch(setPremiumModal(false));
    }
  });

  useEffect(() => {
    if (
      currentSubscription?.onTrial &&
      Number(trialDaysLeft) < 3 &&
      storageShowTrial !== "false"
    ) {
      dispatch(setTrialExpireModal(true));
    }
  }, [dispatch, storageShowTrial, currentSubscription?.onTrial, trialDaysLeft]);

  const handleSubscribe = () => {
    // dispatch(setPremium(true));
    dispatch(setPremiumActivatedModal(false));
    dispatch(getUser());
    dispatch(setTrialActivatedModal(false));
  };

  const handleOpenTryPremium = () => {
    dispatch(setPremiumModal(true));
  };

  const handleClosePremium = () => {
    dispatch(setPremiumModal(false));
    sessionStorage.setItem("showPremium", "false");
  };

  return {
    handleOpenTryPremium,
    handleSubscribe,
    handleClosePremium,
  };
};
