import { FC, useState, useEffect, useCallback, useMemo } from "react";
import { isAxiosError } from "axios";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, contacts } from "store";
import { setSelectedItems } from "store/contacts";
import { getContacts } from "store/contacts/thunks";

import { NoTableData } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getContactsItems, getMenuTitle, toastError } from "utils";
import { IContact } from "types";
import { useActions, useEffectOnce } from "hooks";
import { Contacts } from "api";

import { HeaderCell } from "../HeaderCell";
import { ContactsTableModals } from "./Modals";
import { ContactsTableRow } from "./Row";
import styles from "./styles.module.scss";

export const ContactsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { search, selectedItems, allContacts } = useAppSelector(contacts);
  const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const { t } = useTranslation("Table", {
    keyPrefix: "Contacts",
  });
  const { t: tG } = useTranslation("General");
  const [tableData, setTableData] = useState<IContact[]>([]);
  const storageSorting = localStorage.getItem("sortingContacts");
  const parsedSorting = storageSorting && JSON.parse(storageSorting);
  const [sorting, setSorting] = useState({
    name: parsedSorting?.name === true || false,
  });

  const initialData = useMemo(() => allContacts || [], [allContacts]);

  const sortTable = useCallback(async () => {
    if (tableData.length > 0) {
      setTableData((prevState) =>
        prevState.slice().sort((a: IContact, b: IContact) => {
          const asc = sorting.name ? 1 : -1;
          const desc = sorting.name ? -1 : 1;

          return a.name < b.name ? asc : desc;
        }),
      );
    }
  }, [sorting, tableData.length]);

  const handleSort = useCallback(() => {
    localStorage.setItem(
      "sortingContacts",
      JSON.stringify({
        name: !sorting.name,
      }),
    );

    setSorting((prevState) => ({
      name: !prevState.name,
    }));
  }, [sorting]);

  useEffectOnce(() => {
    dispatch(setSelectedItems([]));
    dispatch(getContacts());
  });

  useEffect(() => {
    sortTable();
  }, [sortTable]);

  const searchItems = useCallback(async () => {
    if (searchValue) {
      try {
        const res = await Contacts.searchContact({ query: searchValue });
        if (res?.items) {
          setTableData(res.items);
        }
      } catch (error) {
        console.log("error:", error);
        if (isAxiosError(error)) {
          error?.message &&
            toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        }
      }
    } else {
      setTableData(allContacts || []);
      sortTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, allContacts]);

  useEffect(() => {
    searchItems();
  }, [searchItems]);

  const handleSelectAll = () => {
    dispatch(
      setSelectedItems(
        selectedItems.length < initialData?.length ? [...initialData] : [],
      ),
    );
  };

  const handleSelectItem = (item: IContact) => {
    dispatch(
      setSelectedItems(
        selectedItems?.some((el) => el.id === item.id)
          ? [...selectedItems.filter((el) => el.id !== item.id)]
          : [...selectedItems, item],
      ),
    );
  };

  return (
    <div className={styles.ContactsTable}>
      <ContactsTableModals />
      <header
        className={cs([styles.tr, selectedItems.length > 0 && styles.active])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedItems.length === initialData?.length &&
            selectedItems.length > 0
          }
        />
        {selectedItems.length > 0 ? (
          <ul className={styles.actions}>
            {getContactsItems({ selectedItems })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({ name: el, isContact: true })}
              >
                <Icon name={el} size={18} />{" "}
                {getMenuTitle({ name: el, isContact: true })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell
              name={tG("name")}
              onSort={handleSort}
              isSorted={sorting.name}
            />
            <HeaderCell name={t("email")} />
          </>
        )}
      </header>
      {tableData?.map((item) => (
        <ContactsTableRow
          key={item.id}
          onChange={() => handleSelectItem(item)}
          item={item}
        />
      ))}

      <NoTableData
        isSearch={!!searchValue}
        isNoFilteredData={tableData?.length === 0}
        isNoData={initialData?.length === 0}
        isContacts
      />
    </div>
  );
};
