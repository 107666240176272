import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const FolderEmptyThinIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 25 / 25; // width / height
  let height = 25;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9173 11.458V17.708C22.9173 21.8747 21.8757 22.9163 17.709 22.9163H7.29232C3.12565 22.9163 2.08398 21.8747 2.08398 17.708V7.29134C2.08398 3.12467 3.12565 2.08301 7.29232 2.08301H8.85482C10.4173 2.08301 10.7611 2.54134 11.3548 3.33301L12.9173 5.41634C13.3132 5.93717 13.5423 6.24967 14.584 6.24967H17.709C21.8757 6.24967 22.9173 7.29134 22.9173 11.458Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default FolderEmptyThinIcon;
