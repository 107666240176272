import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const Sent = () => {
  const { t } = useTranslation("Pages", {
    keyPrefix: "Sent",
  });
  return (
    <div className={styles.Sent}>
      <header className={styles.header}>
        <ul className={styles.links}>
          <li>
            <NavLink
              to={PATHES.SENT_ALL}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("all")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.SENT_IN_PROGRESS}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("inProgress")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.SENT_COMPLETED}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("completed")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.SENT_VOIDED}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("voided")}
            </NavLink>
          </li>
        </ul>
      </header>
      <Outlet />
    </div>
  );
};
