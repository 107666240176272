export const palette = {
  white: "#fefefe",
  black: "#121212",

  red: "#f1403e",
  red20: "#fff4f4",

  peach: "#f87238",
  peach20: "#fff6ed",

  green: "#47a80c",
  green20: "#f4feed",
  actionGreen: "#7df644",
  greenPricing: "#43A932",

  blue: "#2986dc",
  blue20: "#f3f8ff",

  grey10: "#f8f8f8",
  grey20: "#f5f5f5",
  grey30: "#f0f0f0",
  grey40: "#d9d9d9",
  grey50: "#b9b9b9",
  grey70: "#989898",
  grey80: "#706f6f",
  grey90: "#484848",
};

export const pspdfkitColorsList = [
  {
    // blue
    standart: "#E7F4FF",
    pdf: { r: 231, g: 244, b: 255 },
  },
  {
    // yellow
    standart: "#FFF7D0",
    pdf: { r: 255, g: 247, b: 208 },
  },
  {
    // green
    standart: "#EAFFE7",
    pdf: { r: 234, g: 255, b: 231 },
  },
  {
    // orange
    standart: "#FFF4E7",
    pdf: { r: 255, g: 244, b: 231 },
  },
  {
    // purple
    standart: "#EFE7FF",
    pdf: { r: 239, g: 231, b: 255 },
  },
  {
    // pink
    standart: "#FFEBF8",
    pdf: { r: 255, g: 235, b: 248 },
  },
  {
    // neon
    standart: "#D9F7F8",
    pdf: { r: 217, g: 247, b: 248 },
  },
  {
    // rose
    standart: "#FFE8E8",
    pdf: { r: 255, g: 232, b: 232 },
  },
  {
    // deep-purple
    standart: "#DEE2FA",
    pdf: { r: 222, g: 226, b: 250 },
  },
  {
    // chalk
    standart: "#F4F1EE",
    pdf: { r: 244, g: 241, b: 238 },
  },
];
