import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors, toastErrorTwoRows } from "utils";
import { IHttpRejection, IAuthUser } from "types";

import { $http } from "./instance";

export interface IDeleteReason {
  id: string;
  title: string;
}

interface IDeleteReasonsResponse {
  reasons: IDeleteReason[];
}
interface IDeleteAccountRequest {
  verificationId: string;
  reasons?: string[];
  textReason?: string;
}
interface IDeleteAccountResponse {
  message: string;
}

interface IVerifyEmailRequest {
  passwordVerificationId: string;
}

interface IVerifyEmailResponse {
  verificationId: string;
}

interface ISubmitEmailVerificationRequest {
  verificationId: string;
  verificationCode: string;
}

interface ISubmitEmailVerificationResponse {
  verificationId: string;
}

export const User = {
  getAccountInfo: async (): Promise<IAuthUser | undefined> => {
    try {
      const res = await $http.get(`/clients/account`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getDeleteReasons: async (): Promise<IDeleteReasonsResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/account/deletion-reasons`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  deleteAccount: async (
    data: IDeleteAccountRequest,
  ): Promise<IDeleteAccountResponse | undefined> => {
    try {
      const res = await $http.delete(`/clients/account`, { data });
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  verifyEmail: async (
    data: IVerifyEmailRequest,
  ): Promise<IVerifyEmailResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/account/verify-email`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        if (
          message === "api.errors.account.emailVerificationCodeRecentlySent"
        ) {
          toastErrorTwoRows(authErrors(message), authErrors("tryAgainLater"));
        } else {
          message
            ? toastError(authErrors(message))
            : toastError(
                Array.isArray(error.message) ? error.message[0] : error.message,
              );
        }
      } else {
        console.error(e);
      }
    }
  },
  submitEmailVerification: async (
    data: ISubmitEmailVerificationRequest,
  ): Promise<ISubmitEmailVerificationResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/account/submit-email-verification`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(authErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
};
