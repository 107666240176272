import { FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useAppSelector, dashboard } from "store";

import { cs } from "utils";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type MetadataModalProps = {
  onClose: () => void;
};

export const MetadataModal: FC<MetadataModalProps> = ({ onClose }) => {
  const { dateFormat, timeFormat, openedDocMetadata } =
    useAppSelector(dashboard);
  const { certificateIssuedBy, certifiedAt, reason, signedBy } =
    openedDocMetadata || {};
  const { t } = useTranslation("Modals", { keyPrefix: "MetadataModal" });
  const validItems = [
    t("docNotModified"),
    t("signersIdentityValid"),
    t("embededTimestamp"),
    t("ltvEnabled"),
  ];
  const entrustLink = "www.entrust.net/legal-terms";
  const entrustValue = "(c) 2015 Entrust, Inc.";

  return (
    <div className={styles.MetadataModal}>
      <ModalHeader onClose={onClose} title={t("title")} />
      <main className={styles.main}>
        {signedBy && (
          <div className={styles.row}>
            {t("signedBy")} {signedBy}
          </div>
        )}
        {certifiedAt && (
          <div className={styles.row}>
            <span className={styles.label}>{t("certificationDate")}</span>:{" "}
            <span>
              {dayjs(certifiedAt).format(`${dateFormat}, ${timeFormat}`)}
            </span>
          </div>
        )}
        <div className={cs([styles.row, styles.label])}>
          {t("signatureIsValid")}:
        </div>
        <ul className={styles.validList}>
          {validItems.map((item) => (
            <li key={item} className={styles.validItem}>
              {item}
            </li>
          ))}
        </ul>
        {reason && (
          <div className={styles.row}>
            <span className={styles.label}>{t("reason")}:</span>{" "}
            <span>{reason}</span>
          </div>
        )}
      </main>
      <footer className={styles.footer}>
        <h3 className={styles.subtitle}>{t("cetificateDetails")}:</h3>
        {signedBy && <div className={styles.row}>{signedBy}</div>}
        <div className={cs([styles.row, styles.label])}>{t("issuedBy")}:</div>
        {certificateIssuedBy && (
          <div className={styles.row}>{certificateIssuedBy}</div>
        )}
        <div className={styles.row}>
          {t("see")}{" "}
          <a
            className={styles.link}
            href="http://www.entrust.net/legal-terms"
            target="_blank"
            rel="noreferrer"
          >
            {entrustLink}
          </a>{" "}
          <span>{entrustValue}</span>
        </div>
      </footer>
    </div>
  );
};
