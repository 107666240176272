import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHES } from "constants/pathes";

import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type BreadcrumbsPaymentMethodProps = {
  className?: string;
};

export const BreadcrumbsPaymentMethod: FC<BreadcrumbsPaymentMethodProps> = ({
  className,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("Subscription", {
    keyPrefix: "PaymentMethod",
  });

  return (
    <nav className={cs([styles.BreadcrumbsPaymentMethod, className])}>
      <div
        className={styles.link}
        onClick={() => navigate(PATHES.SUBSCRIPTION)}
      >
        {t("breadcrumbHome")}
      </div>
      <Icon
        name="chevron-left-empty"
        className={styles.arrow}
        color={palette.grey70}
        size={16}
      />
      <div className={cs([styles.link, styles.active])}>
        {t("breadcrumbCurrent")}
      </div>
    </nav>
  );
};
