export const BASE_URL = "https://api.dev.apostisign.fulcrum.rocks/v1";

export const WS_URL = "wss://ws.dev.apostisign.fulcrum.rocks";

export const TERMS_AND_POLICY_LINK =
  "https://www.apostisign.com/Terms-of-use&Privacy-Policy";

export const SUPPORT_LINK = "https://www.apostisign.com/FAQ";

export const MAX_FILE_SIZE = 78643200; // 75MB

export const MAX_INSTANT_JSON_SIZE = 3145728; // 3MB
