import { FC } from "react";
import { useTranslation } from "react-i18next";

import { cs } from "utils";

import styles from "./styles.module.scss";

type SwitcherProps = {
  isActive: boolean;
  onClick: (value: boolean) => void;
};

export const Switcher: FC<SwitcherProps> = ({ isActive, onClick }) => {
  const { t } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPlans",
  });

  return (
    <div className={cs([styles.Switcher, isActive && styles.active])}>
      <div className={styles.text} onClick={() => onClick(false)}>
        {t("monthly")}
      </div>
      <div className={styles.text} onClick={() => onClick(true)}>
        {t("annually")}
      </div>
      <div className={styles.ellipse} />
    </div>
  );
};
