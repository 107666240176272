import { FC } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, requestSigning } from "store";
import { getDraft } from "store/signings/thunks";

import { PATHES } from "constants/pathes";
import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type BreadcrumbsRequestSigningProps = {
  className?: string;
};

export const BreadcrumbsRequestSigning: FC<BreadcrumbsRequestSigningProps> = ({
  className,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { draftId } = useParams();
  const { t } = useTranslation("RequestSigning", { keyPrefix: "Breadcrumbs" });
  const { isRequestReady } = useAppSelector(requestSigning);

  const routes = [
    {
      name: t("fileRecipients"),
      path: draftId
        ? `${PATHES.FILE_RECIPIENTS}/${draftId}`
        : PATHES.FILE_RECIPIENTS,
      isDisabled: false,
    },
    {
      name: t("signingsArea"),
      path: `${PATHES.SIGNINGS_AREA}/${draftId}`,
      isDisabled: !draftId,
    },
    {
      name: t("emailDetails"),
      path: `${PATHES.EMAIL_DETAILS}/${draftId}`,
      isDisabled: !isRequestReady,
    },
  ];

  const renderArrow = () => (
    <Icon
      name="chevron-left-empty"
      className={styles.arrow}
      color={palette.grey70}
      size={20}
    />
  );

  const handleGoTo = (path: string, isDisabled: boolean) => {
    if (!isDisabled) {
      draftId && dispatch(getDraft(draftId));
      navigate(path);
    }
  };

  return (
    <nav className={cs([styles.BreadcrumbsRequestSigning, className])}>
      {routes.map((item, index) => (
        <div
          key={item.name}
          className={cs([
            styles.linkWrap,
            pathname.startsWith(item.path) && styles.active,
            item.isDisabled && styles.disabled,
          ])}
        >
          <div
            className={styles.link}
            onClick={() => handleGoTo(item.path, item.isDisabled)}
          >
            <div className={styles.label}>{index + 1}</div>
            <div className={styles.text}>{item.name}</div>
          </div>
          {index < routes.length - 1 && renderArrow()}
        </div>
      ))}
    </nav>
  );
};
