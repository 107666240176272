import { useAppSelector, trashBin, dashboard } from "store";

import {
  AccountButton,
  TrashBinTable,
  PdfViewerTrashDoc,
  TrashDocHeader,
  GlobalSearchTable,
  GlobalSearch,
} from "components";

import styles from "./styles.module.scss";

export const TrashBin = () => {
  const {
    openedDocument: { url: documentURL },
  } = useAppSelector(trashBin);
  const { search } = useAppSelector(dashboard);

  if (documentURL) {
    return (
      <>
        <TrashDocHeader />
        <PdfViewerTrashDoc document={documentURL} />
      </>
    );
  }

  return (
    <div className={styles.TrashBin}>
      <header className={styles.header}>
        <GlobalSearch />
        <AccountButton />
      </header>
      {search ? <GlobalSearchTable /> : <TrashBinTable />}
    </div>
  );
};
