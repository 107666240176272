import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const CertificationIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 21; // width / height
  let height = 21;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.8335C14 20.8335 14 19.8335 10.5 18.3335C7.18629 18.3335 7.99984 20.0836 6.49984 19.0836C6.49984 17.0128 5.98994 13.1013 6.99965 11.5836C7.60571 10.6726 9.2569 11.5836 10.4998 11.5836C13.8135 11.5836 14 11.5198 14 14.8335Z"
        fill="#F3F8FF"
      />
      <circle cx="10" cy="7.5" r="6" fill="#F3F8FF" />
      <path
        d="M10 13.125C13.2619 13.125 15.9062 10.5786 15.9062 7.43752C15.9062 4.29639 13.2619 1.75 10 1.75C6.73807 1.75 4.09375 4.29639 4.09375 7.43752C4.09375 10.5786 6.73807 13.125 10 13.125Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.08002 11.8299L6.07129 18.2874C6.07129 19.0749 6.62254 19.4599 7.30504 19.1361L9.65004 18.0249C9.84254 17.9286 10.1663 17.9286 10.3588 18.0249L12.7125 19.1361C13.3863 19.4511 13.9463 19.0749 13.9463 18.2874V11.6724"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6998 7.34991C13.6998 9.39338 12.0433 11.0499 9.9998 11.0499C7.95635 11.0499 6.2998 9.39338 6.2998 7.34991C6.2998 5.30645 7.95635 3.6499 9.9998 3.6499C12.0433 3.6499 13.6998 5.30645 13.6998 7.34991Z"
        fill={color || palette.black}
        stroke={color || palette.black}
      />
    </svg>
  );
};

export default CertificationIcon;
