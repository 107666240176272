import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
} from "@dnd-kit/core";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, dashboard, folders } from "store";
import { setItemsToMove } from "store/folders";

import { Button } from "components/UI";
import {
  MyDocsTable,
  AccountButton,
  CreateFolder,
  GlobalSearchTable,
  GlobalSearch,
} from "components";
import { useUpload, useActions } from "hooks";
import { DataType } from "types";

import styles from "./styles.module.scss";

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { search } = useAppSelector(dashboard);
  const { t } = useTranslation("Pages", {
    keyPrefix: "Dashboard",
  });
  const { UploadModalWrapped, onUpload } = useUpload();
  const { destinationFolder } = useAppSelector(folders);
  const { handleMove } = useActions();

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 8,
    },
  });

  const handleDragEnd = () => {
    if (destinationFolder) {
      handleMove();
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    dispatch(
      setItemsToMove(
        active.data.current?.length > 0
          ? active.data.current?.map((el: DataType) => el.id)
          : [active.id as string],
      ),
    );
  };
  const sensors = useSensors(pointerSensor);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <UploadModalWrapped />
      <div className={styles.Dashboard}>
        <header className={styles.header}>
          <GlobalSearch />
          <div className={styles.controls}>
            <label className={styles.uploadButton}>
              <input
                className={styles.fileInput}
                type="file"
                accept=".pdf"
                onChange={onUpload}
                multiple
              />
              <Button
                title={t("uploadButtonTitle")}
                variant="primary"
                onClick={() => {}}
                className={styles.controlButton}
              />
            </label>
            <CreateFolder />
            <AccountButton />
          </div>
        </header>
        {search ? <GlobalSearchTable /> : <MyDocsTable />}
      </div>
    </DndContext>
  );
};
