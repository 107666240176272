import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, contacts } from "store";
import { setSearch, setIsContactModal } from "store/contacts";

import { Search, Button } from "components/UI";
import { ContactsTable } from "components";

import styles from "./styles.module.scss";

export const Contacts = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Pages", {
    keyPrefix: "Contacts",
  });
  const { search } = useAppSelector(contacts);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const handleOpenModal = () => {
    dispatch(setIsContactModal(true));
  };

  return (
    <div className={styles.Contacts}>
      <header className={styles.header}>
        <Search
          onChange={handleSearch}
          value={search}
          className={styles.search}
          onClear={() => dispatch(setSearch(""))}
        />
        <Button
          variant="secondary"
          title={t("addButtonTitle")}
          size="md"
          onClick={handleOpenModal}
          iconStart="add-recepient"
          iconStartType="stroke"
        />
      </header>
      <ContactsTable />
    </div>
  );
};
