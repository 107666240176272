import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type RequestSigningCloseModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  onCancel: () => void;
};

export const RequestSigningCloseModal: FC<RequestSigningCloseModalProps> = ({
  onClose,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "RequestSigningClose" });

  const handleSubmit = async () => {
    onSubmit();
  };

  return (
    <div className={styles.RequestSigningCloseModal}>
      <ModalHeader onClose={onClose} title={t("title")} />
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={t("submitButtonTitle")}
          onClick={handleSubmit}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
