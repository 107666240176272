import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon, Button } from "components/UI";
import { palette } from "utils";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import styles from "./styles.module.scss";

type SubscriptionSuccessModalProps = {
  title: string;
  type: string;
  info: string;
  features: {
    title: string;
    isActive: boolean;
  }[];
  submitTitle: string;
  submitAction: () => void;
};

export const SubscriptionSuccessModal: FC<SubscriptionSuccessModalProps> = ({
  title,
  type,
  info,
  features,
  submitTitle,
  submitAction,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "SubscriptionSuccess" });

  return (
    <div className={styles.SubscriptionSuccessModal}>
      <header className={styles.header}>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <img src={mainLogoBlack} alt="logo" width="70" height="19" />
        </div>
      </header>

      <div className={styles.info}>
        <h2 className={styles.title}>{type}</h2>
        <p>{info}</p>
        <ul className={styles.privileges}>
          {features.map((el) => (
            <li className={styles.privilege} key={el.title}>
              <Icon
                name={el.isActive ? "check-in-flower" : "close-in-circle"}
                size={24}
                color={el.isActive ? palette.actionGreen : palette.red}
              />
              {t(el.title)}
            </li>
          ))}
          {/* <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            {t("signatureRequests")}
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            {t("digitalSignature")}
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            {t("noWatermarks")}
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            15GB {t("forDevicesSync")}
          </li> */}
        </ul>
        <Button
          variant="outlined"
          title={submitTitle}
          onClick={submitAction}
          className={styles.submitButton}
        />
      </div>
    </div>
  );
};
