/* eslint-disable no-underscore-dangle */
import { FC, useState, useRef, useEffect } from "react";
import { Annotation, Instance } from "pspdfkit";
import fileDownload from "js-file-download";
import axios from "axios";

import { setStoredInstance, setIsUpdated } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  signings,
  settings,
} from "store";

import { SharedDocuments, Signatures } from "api";
import { toArrayBuffer, palette, getEmptyNode } from "utils";
import { useInstance, useUploadRequest, useActions } from "hooks";

import downloadIcon from "assets/img/icons/download.svg";
import movefolderIcon from "assets/img/pdfView/moveFolder.svg";

import { RecipientsWidget } from "./RecipientsWidget";
import { SignatureWidget } from "./SignatureWidget";
import { RecipientSigningFooter } from "./Footer";
import { GoNextButton } from "./GoNextButton";

export const PdfViewerRecipientSigning: FC = () => {
  const dispatch = useAppDispatch();
  const { activeRecipient } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  const { inboxDoc } = useAppSelector(signings);
  const { handleMoveInboxDocument } = useActions(inboxDoc?.id);
  const {
    saveInkAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    deleteSignature,
    getDynamicColor,
    getSavedJSON,
    signAllSignatureFields,
    cleanAllSignatureFields,
    signSignatureField,
    getStoredSignatures,
    getStoredAtachments,
  } = useInstance();
  const recipientId = activeRecipient?.email || "";
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const [stateInstance, setStateInstance] = useState<Instance | null>(null);
  const [statePSPDFKit, setStatePSPDFKit] = useState<any>(null);
  const [stateChanged, setStateChanged] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const isCreateInitial = useRef(false);
  const { handleAddWaterMark } = useUploadRequest();
  const isDev = window.location.href.startsWith(
    "https://dev.apostisign.fulcrum.rocks",
  );

  const handleChangeSignature = () => {
    stateInstance?.setViewState((viewState: any) =>
      viewState.set("interactionMode", statePSPDFKit.InteractionMode.SIGNATURE),
    );
    sessionStorage.setItem("toDeleteSignature", "true");
  };

  useEffect(() => {
    const savedJSON = getSavedJSON();
    if (!savedJSON) {
      const savedEditedJSON = inboxDoc?.instantJson
        ? {
            ...inboxDoc?.instantJson,
            ...(inboxDoc?.instantJson.annotations?.length > 0 && {
              annotations: inboxDoc?.instantJson?.annotations.map(
                (item: any) => ({
                  ...item,
                }),
              ),
            }),
          }
        : null;

      const dataStringified =
        savedEditedJSON && JSON.stringify(savedEditedJSON);
      localStorage.setItem("instantJSON", dataStringified);
    }
  }, [inboxDoc?.instantJson, getSavedJSON]);

  useEffect(() => {
    if (inboxDoc?.file?.url) {
      const container = containerRef.current;

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");
        // setPSPDFKit(PSPDFKit);

        if (container) {
          const savedJSON = getSavedJSON();
          const storedSignatures = await getStoredSignatures();
          const storedAttachments = await getStoredAtachments();

          // View state config
          const initialViewState = new PSPDFKit.ViewState({
            scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
            layoutMode: PSPDFKit.LayoutMode.SINGLE,
            pageSpacing: 10,
            spreadSpacing: 50,
          });

          // Compose toolbar

          const emptyButton = {
            type: "custom",
            id: "emptyButton",
            node: getEmptyNode(5, `1px solid ${palette.grey30}`),
          };

          const moveButton = {
            type: "custom",
            id: "moveToFolder",
            title: "Move to another folder",
            icon: movefolderIcon,
            onPress: async () => {
              handleMoveInboxDocument();
            },
          };

          const exportButton = {
            type: "custom",
            id: "export",
            icon: downloadIcon,
            onPress: async () => {
              const res = await SharedDocuments.downloadInboxDoc({
                id: inboxDoc.id,
              });
              if (res?.file?.url) {
                axios
                  .get(res?.file?.url, {
                    responseType: "blob",
                  })
                  .then(async (res) => {
                    if (user?.isPremium && !user?.isWebPremium) {
                      fileDownload(res.data, `${inboxDoc.name}.pdf`);
                    } else {
                      const newFileData = await toArrayBuffer(res.data);
                      const updatedBuffer =
                        newFileData &&
                        (await handleAddWaterMark(newFileData as ArrayBuffer));
                      const blob =
                        updatedBuffer &&
                        new Blob([updatedBuffer], {
                          type: "application/pdf",
                        });
                      blob && fileDownload(blob, `${inboxDoc.name}.pdf`);
                    }
                  });
              }
            },
          };
          const toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            { type: "spacer" },
            // { type: "form-creator", className: "form-creator" },
            // { type: "signature" },
            // { type: "text" },
            // { type: "line" },
            // { type: "document-editor" },
            // { type: "spacer" },
            { type: "search" },
          ];

          const list = PSPDFKit.Immutable?.List(
            storedSignatures?.map(PSPDFKit.Annotations.fromSerializableObject),
          );
          // const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
          // const attachmentsArray =
          //   attachmentsString && JSON.parse(attachmentsString);
          // const attachmentsArray =
          //   storedSignatures
          //     ?.filter((el) => el.customData?.atachment)
          //     .map((el) => el.customData?.atachment) || [];

          toolbarItems.push(exportButton);
          toolbarItems.push({ type: "print" });
          inboxDoc.status === "COMPLETED" && toolbarItems.push(moveButton);
          toolbarItems.push(emptyButton);

          PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

          const {
            UI: { createBlock, Recipes, Interfaces },
          } = PSPDFKit;

          instance = await PSPDFKit.load({
            container,
            document: inboxDoc.file.url,
            baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
            initialViewState,
            toolbarItems,
            enableClipboardActions: true,
            enableHistory: true,
            locale: "en",
            styleSheets: ["/custom-pspdfkit.css"],
            licenseKey: isDev ? process.env.REACT_APP_PSPDFKIT_ID : "",
            ...(savedJSON && {
              instantJSON: savedJSON,
            }),
            customRenderers: {
              Annotation: ({ annotation }: any) => {
                const placeholderEl = document.createElement("div");
                // const buttonEl = document.createElement("div");
                if (annotation.formFieldName?.startsWith("SIGNATURE")) {
                  placeholderEl.style.fontSize = "24px";
                  placeholderEl.style.height = "100%";
                  placeholderEl.style.display = "flex";
                  placeholderEl.style.justifyContent = "center";
                  placeholderEl.style.alignItems = "center";
                  placeholderEl.innerHTML = annotation.customData?.isInitial
                    ? "Initials"
                    : "Sign";
                }
                // if (annotation.formFieldName) {
                //   buttonEl.style.position = "absolute";
                //   buttonEl.style.left = "0";
                //   buttonEl.style.top = "-40px";
                //   buttonEl.style.width = "100px";
                //   buttonEl.style.height = "40px";
                //   buttonEl.style.backgroundColor = "red";
                //   buttonEl.style.zIndex = "102";
                //   buttonEl.style.fontSize = "16px";
                //   buttonEl.style.textAlign = "center";
                //   buttonEl.style.display = "flex";
                //   buttonEl.style.justifyContent = "center";
                //   buttonEl.style.alignItems = "center";
                //   buttonEl.innerHTML = "Sign";
                //   buttonEl.style.pointerEvents = "all";
                //   buttonEl.addEventListener("pointerdown", handleGoNext, {
                //     capture: true,
                //   });
                // }
                const parentDiv = document.createElement("div");
                parentDiv.style.height = "100%";
                parentDiv.appendChild(placeholderEl);
                // parentDiv.appendChild(buttonEl);
                return {
                  node: parentDiv,
                  append: true,
                };
              },
            },
            ui: {
              [Interfaces.CreateSignature]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.CreateSignature,
                    props,
                    ({ ui }: any) => {
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Create Initial";
                        ui.getBlockById(
                          "save-signature-checkbox",
                        )._props.label = "Save Initial";

                        const textInput = ui.getBlockById(
                          "signature-text-input",
                        );
                        textInput._props.placeholder = "Initial";
                        textInput._props.label = "Intial here";
                        textInput._props.clearLabel = "Clear initial";

                        const freehand = ui.getBlockById("freehand-canvas");
                        freehand._props.placeholder = "Intial here";
                        freehand._props.clearLabel = "Clear initial";

                        const fontselect = ui.getBlockById("font-selector");
                        if (fontselect._props.items[0].label === "Signature") {
                          fontselect._props.items = fontselect._props.items.map(
                            (item: any) => {
                              return { id: item.id, label: "Initial" };
                            },
                          );
                        }
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
              [Interfaces.SignaturesList]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.SignaturesList,
                    props,
                    ({ ui }: any) => {
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Initials";
                        ui.getBlockById("add-signature")._props.label =
                          "Add initial";
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
            },
          });

          instance.setViewState((viewState) =>
            viewState.set("sidebarMode", PSPDFKit.SidebarMode.THUMBNAILS),
          );

          instance.addEventListener(
            "document.saveStateChange",
            async (event) => {
              if (event.hasUnsavedChanges) {
                dispatch(setStoredInstance(instance));
              }
              setStateChanged((prevState) => !prevState);
            },
          );

          instance.addEventListener("annotations.didSave", () => {
            saveInstanceJSON({ instance, recipientId });
          });

          instance.addEventListener("formFieldValues.didSave", () => {
            saveInstanceJSON({ instance, recipientId });
          });

          instance.addEventListener("annotations.press", async (event) => {
            const { annotation } = event;
            const instSignatures = await instance
              .getStoredSignatures()
              .then((signatures) =>
                signatures
                  .map(PSPDFKit.Annotations.toSerializableObject)
                  .toJS(),
              );
            event.preventDefault &&
              annotation.formFieldName?.startsWith("SIGNATURE_WIDGET") &&
              instSignatures?.length > 0 &&
              event.preventDefault();
            isCreateInitial.current = !!annotation?.customData?.isInitial;

            const innerDoc =
              instance?.contentDocument || instance?.contentWindow?.document;
            const readOnlyInputs = innerDoc?.querySelectorAll("input");
            const readOnlyText = innerDoc?.querySelectorAll<HTMLElement>(
              ".PSPDFKit-Annotation-Widget-Text",
            );
            const readOnlyCheckboxes = innerDoc?.querySelectorAll<HTMLElement>(
              ".PSPDFKit-Annotation-Widget-CheckBox",
            );
            const readOnlySignatures = innerDoc?.querySelectorAll<HTMLElement>(
              ".PSPDFKit-Annotation-Widget-Signature",
            );
            readOnlyInputs?.forEach((item) => item.removeAttribute("disabled"));
            [
              ...readOnlyText,
              ...readOnlyCheckboxes,
              ...readOnlySignatures,
            ]?.forEach((item) => {
              item.style.pointerEvents = "auto";
              return "";
            });
            if (
              instSignatures?.length > 0 &&
              !annotation.isSignature &&
              annotation.formFieldName?.startsWith("SIGNATURE_WIDGET")
            ) {
              signSignatureField({ annotation, instance, PSPDFKit });
            }
            if (annotation.formFieldName?.startsWith("SIGNATURE_INITIALS")) {
              isCreateInitial.current = true;
            }

            if (
              instSignatures?.length > 0 &&
              annotation.formFieldName?.startsWith("SIGNATURE_WIDGET")
            ) {
              instance.setViewState((viewState) =>
                viewState.set("interactionMode", null),
              );
            }
          });

          if (storedAttachments?.length > 0) {
            const blobs = await Promise.all(
              storedAttachments?.map(({ url }: any) =>
                fetch(url).then((res) => res.blob()),
              ),
            );
            blobs.forEach(instance.createAttachment);
          }
          instance.setStoredSignatures(list);

          instance.addEventListener(
            "storedSignatures.create",
            async (annotation: Annotation) => {
              // isCreateInitial.current = false;
              if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
                await instance.create(annotation);
                sessionStorage.setItem("toDeleteSignature", "true");
                // saveInkAnnotationAtachment({ annotation, instance });
              }
              // if (annotation.imageAttachmentId) {
              //   await saveImageAnnotationAtachment({ annotation, instance });
              //   sessionStorage.setItem("toDeleteSignature", "true");
              // }
              // sessionStorage.setItem("toSaveSignature", "true");
              saveSignatures({ annotation, instance, PSPDFKit });
            },
          );

          instance.addEventListener(
            "storedSignatures.delete",
            async (annotation: any) => {
              await deleteSignature({ annotation, instance, PSPDFKit });
              cleanAllSignatureFields({ instance });
            },
          );

          instance.addEventListener(
            "annotations.create",
            async (createdAnnotations) => {
              const annotation = createdAnnotations.get(0);
              const toDeleteSignature =
                sessionStorage.getItem("toDeleteSignature");
              // const toSaveSignature = sessionStorage.getItem("toSaveSignature");

              if (
                annotation &&
                annotation instanceof PSPDFKit.Annotations.InkAnnotation
              ) {
                saveInkAnnotationAtachment({ annotation, instance });
              }
              if (toDeleteSignature && annotation) {
                await instance.delete(annotation.id);
                sessionStorage.removeItem("toDeleteSignature");
              }
              if (annotation?.customData?.savedSignatureId && stateInstance) {
                await Signatures.putSignature({
                  id: annotation?.customData?.savedSignatureId as string,
                  data: {
                    isDefault: true,
                  },
                });

                await cleanAllSignatureFields({ instance: stateInstance });
                dispatch(setIsUpdated(true));
              }
              // if (annotation && toSaveSignature) {
              //   await saveSignatures({ annotation, instance, PSPDFKit });
              //   sessionStorage.removeItem("toSaveSignature");
              // }
            },
          );

          instance.setOnWidgetAnnotationCreationStart(
            (annotation, formField) => {
              return {
                annotation: annotation.set(
                  "backgroundColor",
                  getDynamicColor({ PSPDFKit }),
                ),
              };
            },
          );

          instance.addEventListener(
            "viewState.currentPageIndex.change",
            (page: any) => {
              setOnPageIndex(page);
            },
          );

          setStateInstance(instance);
          setStatePSPDFKit(PSPDFKit);

          return () => PSPDFKit && PSPDFKit.unload(container);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inboxDoc?.file?.url,
    activeRecipient,
    isCreateInitial,
    getStoredSignatures,
    getStoredAtachments,
  ]);

  return (
    <>
      <div
        onMouseEnter={() => {
          dispatch(setIsDetailedViewMenu(false));
        }}
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: "100",
          position: "fixed",
          left: "0",
          top: "9.9rem",
          backgroundColor: "white",
        }}
      />
      <RecipientsWidget />
      {stateInstance && (
        <SignatureWidget
          onChangeSignature={handleChangeSignature}
          handleSignAll={() =>
            signAllSignatureFields({
              PSPDFKit: statePSPDFKit,
              instance: stateInstance,
            })
          }
          handleCleanAll={() =>
            cleanAllSignatureFields({ instance: stateInstance })
          }
        />
      )}
      <RecipientSigningFooter
        stateInstance={stateInstance}
        stateChanged={stateChanged}
        statePSPDFKit={statePSPDFKit}
      />
      <GoNextButton
        fromTop={180}
        stateInstance={stateInstance}
        statePSPDFKit={statePSPDFKit}
      />
    </>
  );
};
