import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, dashboard } from "store";

import { Modal, Loader } from "components/UI";
import { cs } from "utils";

import styles from "./styles.module.scss";

type LoaderFullScreenProps = {
  show?: boolean;
};

export const LoaderFullScreen: FC<LoaderFullScreenProps> = ({ show }) => {
  const { isLoading } = useAppSelector(dashboard);
  const { t } = useTranslation("Dashboard");

  return (
    <div
      className={cs([
        styles.LoaderFullScreen,
        (isLoading || show) && styles.showed,
      ])}
    >
      <Modal isShowed={isLoading || !!show}>
        <div className={styles.container}>
          <Loader size={40} />
          <p className={styles.title}>{t("loading")}...</p>
        </div>
      </Modal>
    </div>
  );
};
