import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, trashBin, folders } from "store";
import {
  setOpenedDocument,
  setIsDeleteItemsModal,
  setIsRestoreItemsModal,
  setSoftDeletedItems,
  setItemsToDelete,
  setItemsToRestore,
} from "store/trashBin";
import { setOpenedDocument as setOpenedDashboardDocument } from "store/dashboard";
import { setCurrentFolder } from "store/folders";

import { Button, Modal } from "components/UI";
import { AreYouSureModal } from "components";

import { cs, toastSuccess } from "utils";
import { useActions } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const TrashDocHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("TrashBin");
  const { t: tM } = useTranslation("Table", {
    keyPrefix: "TrashBin.Modals",
  });
  const {
    openedDocument: { id, url, name, type, daysLeft },
  } = useAppSelector(trashBin);
  const {
    handleRestore,
    handleHardDelete,
    handleHardDeleteSuccess,
    handleRestoreSuccess,
  } = useActions();
  const { isDeleteItemsModal, isRestoreItemsModal } = useAppSelector(trashBin);
  const { rootFolder } = useAppSelector(folders);
  const navigate = useNavigate();

  const handleCloseDocument = () => {
    dispatch(setOpenedDocument({ url: "", id: "", name: "" }));
  };

  const handleRestoreDocument = () => {
    dispatch(setItemsToRestore([id]));
    handleRestore();
  };

  const handleDeleteDocument = () => {
    dispatch(setItemsToDelete([id]));
    handleHardDelete();
  };

  const handleCloseAreYouSureDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureDelete = async () => {
    dispatch(setIsDeleteItemsModal(false));
    await handleHardDeleteSuccess(t("deleteSuccessToast"));
    handleCloseDocument();
  };

  const handleCloseAreYouSureRestore = () => {
    dispatch(setIsRestoreItemsModal(false));
  };

  const handleConfirmAreYouSureRestore = async () => {
    handleRestoreSuccess();
    handleCloseAreYouSureRestore();
    rootFolder && dispatch(setCurrentFolder(rootFolder.id));
    dispatch(setOpenedDashboardDocument({ id, url, name }));
    dispatch(setOpenedDocument({ url: "", id: "", name: "" }));
    type === "PDF"
      ? navigate(`${PATHES.DETAILED_VIEW}/${id}`)
      : navigate(`${PATHES.DETAILED_VIEW}/certified/${id}`);
    toastSuccess(tM("confirmRestoreFileToast"));
  };

  const labelTextNextDays =
    daysLeft === 1
      ? t("labelTextOneDay", {
          count: daysLeft,
        })
      : t("labelTextFewDay", {
          count: daysLeft,
        });

  return (
    <nav className={styles.TrashDocHeader}>
      <Modal
        isShowed={isDeleteItemsModal}
        onClose={handleCloseAreYouSureDelete}
        className={styles.modal}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDelete}
          onSubmit={handleConfirmAreYouSureDelete}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <Modal
        isShowed={isRestoreItemsModal}
        onClose={handleCloseAreYouSureRestore}
        className={styles.modal}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureRestore}
          onSubmit={handleConfirmAreYouSureRestore}
          info={t("areYouSureRestoreInfo")}
          confirmButtonTitle={t("areYouSureRestoreConfirmButtonTitle")}
          isSubmitPositive
        />
      </Modal>
      <div className={styles.inner}>
        <Button
          title={t("backButtonTitle")}
          size="sm"
          variant="textBlack"
          onClick={handleCloseDocument}
          iconStart="chevron-left-empty"
          iconStartType="stroke"
          className={styles.backButton}
        />
        <div className={styles.info}>
          <h2 className={styles.title}>{name}</h2>
          <p
            className={cs([styles.deleteText, daysLeft === 0 && styles.active])}
          >
            {daysLeft === 0 ? t("labelTextToday") : labelTextNextDays}
          </p>
        </div>
        <div className={styles.actionButtons}>
          <Button
            title={t("restoreButtonTitle")}
            size="md"
            variant="primary"
            onClick={handleRestoreDocument}
            iconStart="restore"
            iconStartType="stroke"
            className={styles.actionButton}
          />
          <Button
            title={t("deleteButtonTitle")}
            size="md"
            variant="secondary"
            onClick={handleDeleteDocument}
            iconStart="trash"
            iconStartType="stroke"
            className={styles.actionButton}
          />
        </div>
      </div>
    </nav>
  );
};
