import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Switcher } from "components";
import { Icon, Button } from "components/UI";
import { PlansList } from "components/Subscription/types";
import { cs, palette } from "utils";
import { useSubscription } from "hooks";

import styles from "./styles.module.scss";

type PricingProps = {
  cardsList: PlansList;
  isAnnually: boolean;
  setIsAnnually: (value: boolean) => void;
  getCanceledButtonTitle: () => string;
  handleRenew: () => void;
};

export const Pricing: FC<PricingProps> = ({
  cardsList,
  isAnnually,
  setIsAnnually,
  getCanceledButtonTitle,
  handleRenew,
}) => {
  // const dispatch = useAppDispatch();
  const { t } = useTranslation("Subscription", {
    keyPrefix: "Pricing",
  });
  const { t: tS } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPlans",
  });
  const { isPremium, isBasic, isCanceled } = useSubscription();

  return (
    <div className={styles.Pricing}>
      <h3 className={styles.subtitle}>{t("subtitle")}</h3>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {isCanceled && t("renewTitle")}
          {(isBasic || isPremium) && !isCanceled && t("upgradeTitle")}
          {!isCanceled && !isBasic && t("title")}
        </h2>
        <div className={styles.switcherWrap}>
          <Switcher onClick={setIsAnnually} isActive={isAnnually} />
        </div>
      </div>
      <ul className={styles.cards}>
        {Object.values(cardsList).map(
          ({
            title,
            price,
            priceInfo,
            info,
            features,
            button,
            isPopular,
            isCardPremium,
            isCurrent,
          }) => (
            <li
              key={title}
              className={cs([styles.card, isCardPremium && styles.premium])}
            >
              <div className={styles.header}>
                <div className={styles.title}>
                  {title}{" "}
                  {isPopular && (
                    <div className={styles.label}>{tS("mostPopular")}</div>
                  )}
                  {isCurrent && (
                    <div className={styles.labelEmpty}>{tS("yourCurrent")}</div>
                  )}
                </div>
                <div className={cs([styles.price, !price && styles.free])}>
                  <span>${price?.toFixed()}</span>{" "}
                  {price ? `/ ${tS("month")}` : ""}
                </div>
                <div className={styles.priceInfo}>{priceInfo}</div>
                <div className={styles.info}>{info}</div>
              </div>
              <ul className={styles.features}>
                {features?.map((item: any) => (
                  <li key={item} className={styles.feature}>
                    <Icon
                      name="check-in-flower"
                      size={24}
                      color={
                        isPopular ? palette.actionGreen : palette.greenPricing
                      }
                      className={styles.icon}
                    />

                    {item}
                  </li>
                ))}
              </ul>
              <div className={styles.button}>
                {button && (
                  <Button
                    variant={isCardPremium ? "filled" : "outlinedBlack"}
                    shape="rounded"
                    title={button?.title}
                    onClick={button?.action}
                  />
                )}
                {isCurrent && isCanceled && (
                  <Button
                    variant={isCardPremium ? "filled" : "outlinedBlack"}
                    shape="rounded"
                    title={getCanceledButtonTitle()}
                    onClick={handleRenew}
                  />
                )}
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};
