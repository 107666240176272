import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setIsFromSettings } from "store/settings";

import {
  EmailPasswordName,
  LogoutDelete,
  ChangeLanguage,
  DateTimeFormat,
} from "components";
import { Icon } from "components/UI";
import { useEffectOnce } from "hooks";
import { TERMS_AND_POLICY_LINK, SUPPORT_LINK } from "constants/index";

import styles from "./styles.module.scss";

export const Settings = () => {
  const { t } = useTranslation("Settings");
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(setIsFromSettings(false));
  });

  return (
    <div className={styles.Settings}>
      <EmailPasswordName />
      <ChangeLanguage />
      <DateTimeFormat />
      <Link
        to={SUPPORT_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.menuItem}
      >
        <Icon name="info" size={18} /> {t("help")}
      </Link>
      <Link
        to={TERMS_AND_POLICY_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.menuItem}
      >
        <Icon name="terms" size={18} /> {t("termsAndPrivacy")}
      </Link>
      <LogoutDelete />
    </div>
  );
};
