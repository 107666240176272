import { FC, useCallback, MouseEvent } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, trashBin, dashboard } from "store";
import { setCurrentFolder, setSearch, setOpenedDocument } from "store/trashBin";

import { ContextMenu, RowActions, DocBreadcrumbs, DocImage } from "components";
import { Checkbox } from "components/UI";

import { cs, getMenuItems, folderItemName } from "utils";
import { DataType } from "types";
import { useTableRow } from "hooks";
import { TrashBin } from "api";

import styles from "./styles.module.scss";

interface ITrashBinTableRowProps {
  onChange: () => void;
  item: DataType;
}

export const TrashBinTableRow: FC<ITrashBinTableRowProps> = ({
  onChange,
  item,
}) => {
  const {
    type,
    childrenCount,
    previewUrl,
    id,
    name,
    parents,
    deletedAt,
    daysLeftToHardDeletion,
  } = item;
  const { t } = useTranslation("Table", {
    keyPrefix: "TrashBin.Row",
  });
  const { selectedItems } = useAppSelector(trashBin);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const dispatch = useAppDispatch();
  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();

  const handleOpenFolder = useCallback(() => {
    dispatch(setCurrentFolder(id));
    dispatch(setSearch(""));
  }, [dispatch, id]);

  const handleCheck = () => {
    onChange();
  };

  const handleSelectRow = (e: MouseEvent<HTMLDivElement>) => {
    onChange();
  };

  const handleOpen = async () => {
    if (type === "FOLDER") {
      handleOpenFolder();
    } else {
      try {
        const res =
          type === "PDF"
            ? await TrashBin.getPDFDocument(id)
            : await TrashBin.getSignedPDFDocument(id);
        const { url, name } = res?.document || {};
        dispatch(
          setOpenedDocument({
            id,
            url,
            name,
            type,
            daysLeft: daysLeftToHardDeletion,
          }),
        );
      } catch (error) {
        console.log("error:", error);
      }
    }
  };

  const isSelected = selectedItems.some((el) => el.id === id);

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={handleSelectRow}
        className={cs([
          styles.TrashBinTableRow,
          isSelected && styles.selected,
          type === "PDF_CERTIFIED" && styles.certified,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={handleCheck} isChecked={isSelected} />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name} onClick={handleOpen}>
            <DocImage type={type} previewUrl={previewUrl} />
            <div className={styles.nameWithDir}>
              {parents && (
                <DocBreadcrumbs
                  parents={parents}
                  rootFolderName={t("rootFolderName")}
                />
              )}
              <div className={styles.textName}>{name}</div>
            </div>
          </div>
          <RowActions
            actions={["trash", "restore"]}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            isTrash
          />
        </div>
        <div className={styles.td}>{folderItemName[type]}</div>
        <div className={styles.td}>
          {`${childrenCount || 0} ${
            type === "FOLDER"
              ? `item${childrenCount === 1 ? "" : "s"}`
              : `page${childrenCount === 1 ? "" : "s"}`
          } `}
        </div>
        <div className={styles.td}>
          {dayjs(deletedAt).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && (
          <ContextMenu
            theme="light"
            items={getMenuItems({ selectedItems, type, isTrash: true })}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            isTrash
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
