import { createAsyncThunk } from "@reduxjs/toolkit";
import { Contacts, ICreateContact, IUpdateContact, IDeleteContacts } from "api";

export const getContacts = createAsyncThunk("contacts/getContacts", async () =>
  Contacts.getContacts(),
);

export const getContact = createAsyncThunk(
  "contacts/getContact",
  async (id: string) => Contacts.getContact(id),
);

export const createContact = createAsyncThunk(
  "contacts/createContact",
  async (data: ICreateContact) => Contacts.createContact(data),
);

export const updateContact = createAsyncThunk(
  "contacts/updateContact",
  async ({ id, data }: { id: string; data: IUpdateContact }) =>
    Contacts.updateContact(id, data),
);

export const deleteContacts = createAsyncThunk(
  "contacts/deleteContacts",
  async (data: IDeleteContacts) => Contacts.deleteContacts(data),
);
