import { FC, useEffect, useState, useCallback } from "react";
import { Instance } from "pspdfkit";

import { Button } from "components/UI";

import { useInstance } from "hooks";

import styles from "./styles.module.scss";

type GoNextButtonProps = {
  fromTop: number;
  stateInstance: Instance | null;
  statePSPDFKit: any;
};

export const GoNextButton: FC<GoNextButtonProps> = ({
  fromTop,
  stateInstance,
  statePSPDFKit,
}) => {
  const { getUnsignedWidgets } = useInstance();
  const [unsignedWidgetIndex, setUnsignedWidgetIndex] = useState<number>(0);
  const [totalUnsigned, setTotalUnsigned] = useState<number>(0);

  const handleGoNext = async () => {
    if (stateInstance) {
      const unsignedWidgets = await getUnsignedWidgets({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      if (unsignedWidgets[unsignedWidgetIndex]) {
        stateInstance.jumpAndZoomToRect(
          unsignedWidgets[unsignedWidgetIndex].pageIndex,
          unsignedWidgets[unsignedWidgetIndex].boundingBox,
        );
        stateInstance.setViewState((state) => state.set("zoom", 1.25));

        setUnsignedWidgetIndex((prevState) =>
          prevState < unsignedWidgets.length - 1 ? prevState + 1 : 0,
        );
      }
    }
  };

  const initTotalUnsigned = useCallback(async () => {
    if (stateInstance) {
      const unsignedWidgets = await getUnsignedWidgets({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      setTotalUnsigned(unsignedWidgets.length);
    }
  }, [getUnsignedWidgets, stateInstance, statePSPDFKit]);

  useEffect(() => {
    initTotalUnsigned();
  }, [initTotalUnsigned]);

  return (
    <div className={styles.GoNextButton} style={{ top: `${fromTop}px` }}>
      <Button
        // title="Go next"
        title={`Sign (${unsignedWidgetIndex + 1}/${totalUnsigned})`}
        onClick={handleGoNext}
        variant="primary"
      />
    </div>
  );
};
