import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Icon } from "components/UI";

import mainLogo from "assets/img/main-logo.svg";

import { palette } from "utils";

import styles from "./styles.module.scss";

type NoMemoryModalProps = {
  title: string;
  info?: string;
  submitTitle: string;
  submitAction: () => void;
  cancelTitle?: string;
  cancelAction?: () => void;
  memoryFilledPart: number;
  usedMB: number;
  usedGB: number;
  totalMB: number;
  totalGB: number;
};

export const NoMemoryModal: FC<NoMemoryModalProps> = ({
  title,
  info,
  submitTitle,
  submitAction,
  cancelTitle,
  cancelAction,
  memoryFilledPart,
  usedMB,
  usedGB,
  totalMB,
  totalGB,
}) => {
  const { t } = useTranslation("CloudMemory");
  return (
    <div className={styles.NoMemoryModal}>
      <img src={mainLogo} alt="logo" width="120" height="32" />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.info}>{info}</div>
        <div className={styles.progressBar}>
          <div
            className={styles.value}
            style={{ width: `${memoryFilledPart}%` }}
          />
        </div>
        <p className={styles.cloudInfo}>
          <Icon name="upload-fail" color={palette.white} />
          {usedMB < 1024 ? `${usedMB}MB` : `${usedGB}GB`}/
          {totalMB < 1024 ? `${totalMB}MB` : `${totalGB}GB`} {t("forSync")}
        </p>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="outlined"
          title={submitTitle}
          onClick={submitAction}
          className={styles.submitButton}
        />
        {cancelTitle && cancelAction && (
          <Button
            variant="textWhite"
            title={cancelTitle}
            onClick={cancelAction}
            className={styles.submitButton}
          />
        )}
      </div>
    </div>
  );
};
