import { FC } from "react";
import { calculateSideByRatio } from "utils";
import { IconProps } from "./index";

const AppleIcon: FC<IconProps> = ({
  className,
  //   color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 16 / 19; // width / height
  let height = 19;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8829 3.02266L10.8828 3.02282C10.5594 3.41029 10.1178 3.74824 9.6456 3.9893C9.17337 4.23039 8.67124 4.37424 8.227 4.37424C8.12876 4.37424 8.03039 4.36185 7.9675 4.35078C7.95535 4.29835 7.93187 4.15669 7.93187 4.0151C7.93187 3.03197 8.45323 2.06125 9.01231 1.44674L9.01237 1.44667C9.72244 0.650456 10.8989 0.0486772 11.8888 0.00979172C11.9129 0.117741 11.9242 0.250596 11.9242 0.381938C11.9242 1.35217 11.4929 2.32292 10.8829 3.02266ZM14.646 13.0738C15.2695 13.6543 15.9018 13.9138 15.9888 13.9459C15.9815 13.9742 15.9551 14.062 15.9078 14.1965C15.8544 14.3481 15.7745 14.5583 15.6655 14.8082C15.4476 15.308 15.1133 15.9663 14.642 16.6307C14.2214 17.216 13.792 17.7999 13.3011 18.2376C12.8104 18.6751 12.2588 18.9658 11.5937 18.9658C10.9514 18.9658 10.5393 18.792 10.1109 18.6114C10.095 18.6047 10.0791 18.598 10.0631 18.5913C9.61683 18.4034 9.14376 18.2152 8.37047 18.2152C7.61684 18.2152 7.10593 18.4096 6.63122 18.6036C6.60679 18.6135 6.58245 18.6235 6.55819 18.6335C6.11108 18.8168 5.68766 18.9905 5.10994 18.9905C4.50185 18.9905 3.98515 18.7212 3.49755 18.2851C3.00977 17.8488 2.55192 17.2463 2.06149 16.5812C0.926575 15.0207 0.00950095 12.5961 0.00950095 10.2952C0.00950095 6.60627 2.4897 4.65167 4.92912 4.65167C5.57677 4.65167 6.17084 4.85723 6.70583 5.06337C6.76771 5.08721 6.82881 5.11107 6.88912 5.13461C7.08932 5.21278 7.28078 5.28753 7.46278 5.34702C7.69964 5.42445 7.92137 5.47635 8.12677 5.47635C8.32243 5.47635 8.54485 5.42131 8.79084 5.33928C8.95836 5.28343 9.13739 5.21485 9.32675 5.14231C9.41542 5.10834 9.50636 5.07351 9.59945 5.0387C10.1837 4.82025 10.8545 4.60226 11.5917 4.60226C12.1505 4.60226 14.1538 4.65158 15.4757 6.47552C15.4666 6.48162 15.4528 6.49037 15.4348 6.50179C15.1043 6.71118 13.353 7.82092 13.353 10.0976C13.353 11.5108 13.9948 12.4675 14.646 13.0738Z"
        fill="black"
        stroke="#4285F4"
        strokeWidth="0.0190019"
      />
    </svg>
  );
};

export default AppleIcon;
