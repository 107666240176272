import { FC } from "react";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

import { toastInfo, cs } from "utils";

import copyImg from "assets/img/icons/copy.svg";

import styles from "./styles.module.scss";

type DocIDCopyProps = {
  id: string;
  width?: string;
  size?: string;
};

export const DocIDCopy: FC<DocIDCopyProps> = ({ id, width, size }) => {
  const { t } = useTranslation("Table", {
    keyPrefix: "DocIDCopy",
  });

  const handleCopyId = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(id);
      toastInfo(t("toastCopiedSuccess"));
      return;
    }

    const textArea = document.createElement("textarea");
    textArea.value = id;
    textArea.style.opacity = "0";
    textArea.style.position = "absolute";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");

    toastInfo(t("toastCopiedSuccess"));
  };

  return (
    <div
      className={styles.DocIDCopy}
      onClick={handleCopyId}
      data-tooltip-id={id}
      data-tooltip-content={t("tooltipContent")}
      data-tooltip-place="bottom"
    >
      <p
        className={cs([styles.text, size ? styles[size] : ""])}
        style={width ? { maxWidth: width } : {}}
      >
        {id}
      </p>
      <img
        src={copyImg}
        alt="copy"
        width={size === "sm" ? 16 : 20}
        height={size === "sm" ? 16 : 20}
      />
      <Tooltip id={id} positionStrategy="fixed" delayShow={300} noArrow />
    </div>
  );
};
