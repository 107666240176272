import { AxiosError, isAxiosError } from "axios";
import queryString from "query-string";

import { toastError, toastSuccess, dashboardErrors } from "utils";
import { IHttpRejection, IContact } from "types";

import { $http } from "./instance";

export interface IGetContacts {
  id: string;
}

interface ISearchFolderRequset {
  query: string;
  isFoldersOnly?: string;
}

interface ISearchFolderResponse {
  items: IContact[];
}

export interface ICreateContact {
  name: string;
  email: string;
}
export interface IUpdateContact {
  name: string;
  email: string;
}

export interface IDeleteContacts {
  contactIds: string[];
}

export const Contacts = {
  getContacts: async (): Promise<any> => {
    try {
      const res = await $http.get(`/clients/contacts`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getContact: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/contacts/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  createContact: async (
    data: ICreateContact,
    noNotify?: boolean,
  ): Promise<any> => {
    try {
      const res = await $http.post(`/clients/contacts`, data);
      !noNotify && toastSuccess("Contact added");
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteContacts: async (data: IDeleteContacts): Promise<any> => {
    const isPlural = data.contactIds.length > 1;
    try {
      const res = await $http.delete(`/clients/contacts`, { data });
      toastSuccess(`Contact${isPlural ? "s" : ""} deleted`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  updateContact: async (
    id: string,
    data: ICreateContact,
    noNotify?: boolean,
  ): Promise<any> => {
    try {
      const res = await $http.put(`/clients/contacts/${id}`, data);
      !noNotify && toastSuccess("Contact updated");
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  searchContact: async ({
    query,
  }: ISearchFolderRequset): Promise<ISearchFolderResponse | undefined> => {
    const queries = queryString.stringify({ query }, { skipEmptyString: true });
    try {
      const res = await $http.get(`/clients/contacts/search?${queries}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
};
