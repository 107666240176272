import { FC } from "react";
import { Button } from "components/UI";

import mainLogo from "assets/img/main-logo.svg";

import styles from "./styles.module.scss";

type ConfirmModalProps = {
  title: string;
  info?: string;
  submitTitle: string;
  submitAction: () => void;
  cancelTitle?: string;
  cancelAction?: () => void;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  info,
  submitTitle,
  submitAction,
  cancelTitle,
  cancelAction,
}) => {
  return (
    <div className={styles.ConfirmModal}>
      <img src={mainLogo} alt="logo" width="120" height="32" />
      <div className={styles.wrap}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.info}>{info}</div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="outlined"
          title={submitTitle}
          onClick={submitAction}
          className={styles.submitButton}
        />
        {cancelTitle && cancelAction && (
          <Button
            variant="textWhite"
            title={cancelTitle}
            onClick={cancelAction}
            className={styles.submitButton}
          />
        )}
      </div>
    </div>
  );
};
