export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const toArrayBuffer = (
  file: File,
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const downloadPdf = (objectUrl: string, name: string) => {
  // const objectUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  // const namePDF =
  //   name?.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase() === "pdf"
  //     ? name
  //     : `${name}.pdf`;
  a.href = objectUrl;
  a.style.display = "none";
  a.download = `${name}.pdf`;
  a.setAttribute("download", `${name}.pdf`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;

  return theBlob as File;
};

export const removeExtension = (text?: string) =>
  text?.replace(/(\.[^/.]+)$/, "") || "";
