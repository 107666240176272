import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";

import { useAppDispatch, useAppSelector, dashboard } from "store";
import { setErrorModal } from "store/dashboard";

import mainLogo from "assets/img/main-logo.svg";

import styles from "./styles.module.scss";

export const ItemActionErrorModal: FC = () => {
  const dispatch = useAppDispatch();
  const { errorModal } = useAppSelector(dashboard);
  const { t } = useTranslation("Modals", { keyPrefix: "ItemActionError" });

  const handleCloseModal = () => {
    dispatch(setErrorModal(null));
    errorModal?.onCancel();
  };

  const handleSubmit = () => {
    errorModal?.onSubmit();
  };

  if (!errorModal) {
    return <></>;
  }

  return (
    <div className={styles.ItemActionErrorModal}>
      <img src={mainLogo} alt="logo" width="120" height="32" />

      <div className={styles.info}>
        <h2 className={styles.title}>{errorModal?.title}</h2>
        <p className={styles.text}>{errorModal?.text}</p>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="outlined"
          title={errorModal?.submitButtonTitle || t("submitButtonTitle")}
          onClick={handleSubmit}
          className={styles.submitButton}
        />
        <Button
          variant="textWhite"
          title={t("cancelButtonTitle")}
          onClick={handleCloseModal}
          className={styles.submitButton}
        />
      </div>
    </div>
  );
};
