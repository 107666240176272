import { FC } from "react";
import { cs } from "utils";

import arrowDown from "assets/img/icons/arrow-down.svg";

import styles from "./styles.module.scss";

type HeaderCellProps = {
  onSort?: () => void;
  isSorted?: boolean;
  name: string;
};

export const HeaderCell: FC<HeaderCellProps> = ({ onSort, isSorted, name }) => {
  return (
    <div className={styles.HeaderCell}>
      <div>{name}</div>
      {onSort && (
        <img
          className={cs([styles.arrow, isSorted && styles.up])}
          src={arrowDown}
          alt="sort"
          onClick={onSort}
          width={16}
          height={16}
        />
      )}
    </div>
  );
};
