import { FC } from "react";
import { useTranslation } from "react-i18next";

import { RecipientsUploadFile } from "components";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type RecipientsAddFileModalProps = {
  onClose: () => void;
};

export const RecipientsAddFileModal: FC<RecipientsAddFileModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "RecipientsAddFile" });

  return (
    <div className={styles.RecipientsAddFileModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <RecipientsUploadFile noModalDarkness />
    </div>
  );
};
