// Core
import { FC, MouseEvent, memo } from "react";
import { palette } from "utils";

import SearchIcon from "./SearchIcon";
import UserEmptyIcon from "./UserEmptyIcon";
import FolderEmptyIcon from "./FolderEmptyIcon";
import DocsEmptyIcon from "./DocsEmptyIcon";
import CloseInCircleIcon from "./CloseInCircleIcon";
import CheckMarkIcon from "./CheckMarkIcon";
import TrashIcon from "./TrashIcon";
import ChevronRightFilledIcon from "./ChevronRightFilledIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import ChevronLeftEmptyIcon from "./ChevronLeftEmptyIcon";
import EyeOpenedIcon from "./EyeOpenedIcon";
import EyeClosedIcon from "./EyeClosedIcon";
import LoginIcon from "./LoginIcon";
import DoneSuccessIcon from "./DoneSuccessIcon";
import InfoIcon from "./InfoIcon";
import CheckInFlowerIcon from "./CheckInFlowerIcon";
import CloudsIcon from "./CloudsIcon";
import ThreeDotsVerticalIcon from "./ThreeDotsVerticalIcon";
import AddFolderIcon from "./AddFolderIcon";
import RenameIcon from "./RenameIcon";
import MoveIcon from "./MoveIcon";
import UserFilledIcon from "./UserFilledIcon";
import SettingsIcon from "./SettingsIcon";
import SignIcon from "./SignIcon";
import DownloadIcon from "./DownloadIcon";
import ShareIcon from "./ShareIcon";
import ThreeDotsHorizIcon from "./ThreeDotsHorizIcon";
import EditIcon from "./EditIcon";
import GlobalIcon from "./GlobalIcon";
import CloseIcon from "./CloseIcon";
import ChevronDownEmptyIcon from "./ChevronDownEmptyIcon";
import FileIcon from "./FileIcon";
import WarningErrorIcon from "./WarningErrorIcon";
import AddRecepientIcon from "./AddRecepientIcon";
import RestoreIcon from "./RestoreIcon";
import GoogleIcon from "./GoogleIcon";
import AppleIcon from "./AppleIcon";
import ContactsIcon from "./ContactsIcon";
import AddInCircleIcon from "./AddInCircleIcon";
import FolderEmptyThinIcon from "./FolderEmptyThinIcon";
import TermsIcon from "./TermsIcon";
import DateTimeIcon from "./DateTimeIcon";
import CertificationIcon from "./CertificationIcon";
import CardIcon from "./CardIcon";
import GPayIcon from "./GPayIcon";
import BankIcon from "./BankIcon";
import UploadFailedIcon from "./UploadFailedIcon";

export type IconName =
  | "search"
  | "user-empty"
  | "folder-empty"
  | "folder-empty-thin"
  | "docs-empty"
  | "close-in-circle"
  | "check"
  | "trash"
  | "chevron-right-filled"
  | "arrow-left"
  | "chevron-down-empty"
  | "chevron-left-empty"
  | "eye-opened"
  | "eye-closed"
  | "login"
  | "done-success"
  | "info"
  | "check-in-flower"
  | "clouds"
  | "three-dots-vertical"
  | "add-folder"
  | "rename"
  | "move"
  | "user-filled"
  | "settings"
  | "sign"
  | "download"
  | "share"
  | "three-dots-horizontal"
  | "edit"
  | "global"
  | "close"
  | "file"
  | "warning-error"
  | "add-recepient"
  | "restore"
  | "google"
  | "apple"
  | "contacts"
  | "add-in-circle"
  | "terms"
  | "date-time"
  | "certification"
  | "card"
  | "gpay"
  | "bank"
  | "upload-fail";

export type IconProps = {
  className?: string;
  color?: keyof typeof palette | string | null;
  action?: (e: MouseEvent<SVGElement>) => void;
  size?: number;
  dataTooltipId?: string;
  dataTooltipContent?: string;
  dataTooltipPlace?: "top" | "bottom" | "right" | "left";
};

export const Icon: FC<IconProps & { name: IconName }> = memo((props) => {
  switch (props.name) {
    case "search":
      return <SearchIcon {...props} />;
    case "user-empty":
      return <UserEmptyIcon {...props} />;
    case "folder-empty":
      return <FolderEmptyIcon {...props} />;
    case "docs-empty":
      return <DocsEmptyIcon {...props} />;
    case "close-in-circle":
      return <CloseInCircleIcon {...props} />;
    case "check":
      return <CheckMarkIcon {...props} />;
    case "trash":
      return <TrashIcon {...props} />;
    case "chevron-right-filled":
      return <ChevronRightFilledIcon {...props} />;
    case "arrow-left":
      return <ArrowLeftIcon {...props} />;
    case "chevron-left-empty":
      return <ChevronLeftEmptyIcon {...props} />;
    case "chevron-down-empty":
      return <ChevronDownEmptyIcon {...props} />;
    case "eye-opened":
      return <EyeOpenedIcon {...props} />;
    case "eye-closed":
      return <EyeClosedIcon {...props} />;
    case "login":
      return <LoginIcon {...props} />;
    case "done-success":
      return <DoneSuccessIcon {...props} />;
    case "info":
      return <InfoIcon {...props} />;
    case "check-in-flower":
      return <CheckInFlowerIcon {...props} />;
    case "clouds":
      return <CloudsIcon {...props} />;
    case "three-dots-vertical":
      return <ThreeDotsVerticalIcon {...props} />;
    case "add-folder":
      return <AddFolderIcon {...props} />;
    case "rename":
      return <RenameIcon {...props} />;
    case "move":
      return <MoveIcon {...props} />;
    case "user-filled":
      return <UserFilledIcon {...props} />;
    case "settings":
      return <SettingsIcon {...props} />;
    case "sign":
      return <SignIcon {...props} />;
    case "download":
      return <DownloadIcon {...props} />;
    case "share":
      return <ShareIcon {...props} />;
    case "three-dots-horizontal":
      return <ThreeDotsHorizIcon {...props} />;
    case "edit":
      return <EditIcon {...props} />;
    case "global":
      return <GlobalIcon {...props} />;
    case "close":
      return <CloseIcon {...props} />;
    case "file":
      return <FileIcon {...props} />;
    case "warning-error":
      return <WarningErrorIcon {...props} />;
    case "add-recepient":
      return <AddRecepientIcon {...props} />;
    case "restore":
      return <RestoreIcon {...props} />;
    case "google":
      return <GoogleIcon {...props} />;
    case "apple":
      return <AppleIcon {...props} />;
    case "contacts":
      return <ContactsIcon {...props} />;
    case "add-in-circle":
      return <AddInCircleIcon {...props} />;
    case "folder-empty-thin":
      return <FolderEmptyThinIcon {...props} />;
    case "terms":
      return <TermsIcon {...props} />;
    case "date-time":
      return <DateTimeIcon {...props} />;
    case "certification":
      return <CertificationIcon {...props} />;
    case "card":
      return <CardIcon {...props} />;
    case "gpay":
      return <GPayIcon {...props} />;
    case "bank":
      return <BankIcon {...props} />;
    case "upload-fail":
      return <UploadFailedIcon {...props} />;
    default:
      return <></>;
  }
});
