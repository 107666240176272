import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, settings } from "store";
import { setIsFromSettings } from "store/settings";
import { getDeleteReasons } from "store/settings/thunks";

import { Button, Checkbox } from "components/UI";

import { User } from "api";
import { useEffectOnce, useAuth } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

type DeleteReasonModalProps = {
  onClose: () => void;
};

export const DeleteReasonModal: FC<DeleteReasonModalProps> = ({ onClose }) => {
  const { deleteReasons, verificationId } = useAppSelector(settings);
  const { handleLogout } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "DeleteReason" });
  const navigate = useNavigate();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [details, setDetails] = useState<string>("");
  const isOther =
    deleteReasons.filter(
      (el) => selectedReasons.includes(el.id) && el.title === "other",
    )?.length > 0;

  useEffectOnce(() => {
    dispatch(getDeleteReasons());
  });

  const handleSelectReason = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedReasons((prevState) =>
      prevState.includes(name)
        ? [...prevState.filter((el) => el !== name)]
        : [...prevState, name],
    );
  };

  const handleInputDetails = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setDetails(value);
  };

  const handleSendData = async () => {
    const dataToSend = {
      verificationId,
      ...(selectedReasons.length > 0 && { reasons: selectedReasons }),
      ...(details && { textReason: details }),
    };

    const res = await User.deleteAccount(dataToSend);

    if (res?.message) {
      dispatch(setIsFromSettings(false));
      onClose();
      await handleLogout();
      navigate(PATHES.SIGN_UP);
    }
  };

  return (
    <div className={styles.DeleteReasonModal}>
      <h2 className={styles.title}>{t("title")}</h2>
      <ul className={styles.reasons}>
        {deleteReasons.map((el, index) => (
          <li key={index} className={styles.reason}>
            <Checkbox
              title={t(el.title)}
              name={el.id}
              onChange={handleSelectReason}
              isChecked={selectedReasons.includes(el.id)}
              isWhite
            />
          </li>
        ))}
      </ul>
      <textarea
        className={styles.details}
        name="reason_details"
        cols={30}
        rows={10}
        onChange={handleInputDetails}
        value={details}
        maxLength={150}
        placeholder={t("textareaPlaceholder")}
      />
      <Button
        title={t("submitButtonTitle")}
        variant="outlined"
        onClick={handleSendData}
        isDisabled={selectedReasons.length === 0 || (isOther && !details)}
      />
      <Button
        title={t("cancelButtonTitle")}
        variant="textWhite"
        onClick={onClose}
        className={styles.cancelButton}
      />
    </div>
  );
};
