import { FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppDispatch, useAppSelector, contacts } from "store";
import { setEditableContact, setIsContactModal } from "store/contacts";

import { ContextMenu, RowActions } from "components";
import { Checkbox } from "components/UI";

import { cs, getContactsItems } from "utils";
import { IContact } from "types";
import { useTableRow } from "hooks";

import styles from "./styles.module.scss";

interface IContactsTableRowProps {
  onChange: () => void;
  item: IContact;
}

export const ContactsTableRow: FC<IContactsTableRowProps> = ({
  onChange,
  item,
}) => {
  const { id, name, email } = item || {};
  const dispatch = useAppDispatch();
  const { selectedItems } = useAppSelector(contacts);
  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();

  const handleOpenContact = () => {
    dispatch(setEditableContact(id));
    dispatch(setIsContactModal(true));
  };
  const isSelected = selectedItems.some((el) => el.id === id);

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={onChange}
        className={cs([styles.ContactsTableRow, isSelected && styles.selected])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={onChange} isChecked={isSelected} />
        </div>
        <div
          className={cs([styles.td, styles.black])}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.name} onClick={handleOpenContact}>
            {name}
          </div>
          <RowActions
            actions={["edit", "trash"]}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            isContact
          />
        </div>
        <div className={styles.td}>{email}</div>
        {isClicked && (
          <ContextMenu
            theme="light"
            items={getContactsItems({ selectedItems })}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            isContact
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
