import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

type TryProProps = {
  onClick: () => void;
};

export const TryPro: FC<TryProProps> = ({ onClick }) => {
  const { t } = useTranslation("TryPro");
  return (
    <div className={styles.TryPro} onClick={onClick}>
      <h2 className={styles.title}>
        {t("title")} <span className={styles.active}>{t("titleActive")}</span>
      </h2>
      <p className={styles.text}>{t("text")} </p>
    </div>
  );
};
