import { FC, useEffect, useCallback, MouseEvent } from "react";
import dayjs from "dayjs";
import { useDraggable } from "@dnd-kit/core";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch, folders, dashboard } from "store";
import { setCurrentFolder, setDestinationFolder } from "store/folders";
import { setSearch } from "store/dashboard";

import { ContextMenu, RowActions, DocImage, DocBreadcrumbs } from "components";
import { Checkbox } from "components/UI";

import { cs, getMenuItems, folderItemName } from "utils";
import { DataType } from "types";
import { useTableRow } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

interface IMyDocsTableRowProps {
  onChange: () => void;
  item: DataType;
}

export const MyDocsTableRow: FC<IMyDocsTableRowProps> = ({
  onChange,
  item,
}) => {
  const { type, childrenCount, modifiedAt, previewUrl, id, name, parents } =
    item;
  const { destinationFolder, selectedItems } = useAppSelector(folders);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: selectedItems,
  });
  const { t } = useTranslation("Table", {
    keyPrefix: "MyDocs.Row",
  });
  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick: onRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOpenFolder = useCallback(() => {
    dispatch(setCurrentFolder(id));
    dispatch(setSearch(""));
  }, [dispatch, id]);

  useEffect(() => {
    if (!transform) {
      window.scroll(0, 0);
    }
  }, [transform]);

  const style = transform
    ? {
        transform: `translate3d(${transform.x + 150}px, ${
          transform.y + 100
        }px, 0)`,
      }
    : undefined;

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    onRightClick(e);
    dispatch(setDestinationFolder(null));
  };

  const handleCheck = () => {
    onChange();
  };

  const handleSelectRow = () => {
    onChange();
  };

  const handleOpen = async () => {
    if (type === "FOLDER") {
      handleOpenFolder();
    } else {
      type === "PDF"
        ? navigate(`${PATHES.DETAILED_VIEW}/${id}`)
        : navigate(`${PATHES.DETAILED_VIEW}/certified/${id}`);
    }
  };

  const renderBasicRow = () => (
    <div className={styles.MyDocsTableRow} style={{ marginBottom: "-85px" }}>
      <div className={styles.td}>
        <Checkbox
          name={id}
          onChange={onChange}
          isChecked={selectedItems.some((el) => el.id === id)}
        />
      </div>
      <div className={styles.td}>
        <div className={styles.name}>
          <DocImage type={type} previewUrl={previewUrl} />
          <div className={styles.nameWithDir}>
            {parents && <DocBreadcrumbs parents={parents} />}
            <div className={styles.textName}>{name}</div>
          </div>
        </div>
      </div>
      <div className={styles.td}>{type}</div>
      <div className={styles.td}>
        {childrenCount ? `${childrenCount} ${t("items")}` : ""}
      </div>
      <div className={styles.td}>
        {dayjs(modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
      </div>
    </div>
  );

  const isSelected = selectedItems.some((el) => el.id === id);

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      {transform && renderBasicRow()}
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={handleSelectRow}
        className={cs([
          styles.MyDocsTableRow,
          isSelected && styles.selected,
          type === "PDF_CERTIFIED" && styles.certified,
          !!transform && styles.graggable,
          selectedItems.length > 1 && styles.packet,
        ])}
        style={style}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {transform ? (
          <div className={styles.td}>
            <DocImage type={type} previewUrl={previewUrl} />
            <div className={styles.name}>{name}</div>
            <div className={styles.counter}>{selectedItems.length || 1}</div>
            <div className={styles.label}>
              {t("moveTo")}: {destinationFolder?.title}
            </div>
          </div>
        ) : (
          <>
            <div className={styles.td} onClick={(e) => e.stopPropagation()}>
              <Checkbox
                name={id}
                onChange={handleCheck}
                isChecked={isSelected}
              />
            </div>
            <div className={cs([styles.td, styles.black])}>
              <div className={styles.name} onClick={handleOpen}>
                <DocImage type={type} previewUrl={previewUrl} />
                <div className={styles.nameWithDir}>
                  {parents && <DocBreadcrumbs parents={parents} />}
                  <div className={styles.textName}>{name}</div>
                </div>
              </div>
              <RowActions
                actions={["move", "rename"]}
                additionalActions={["trash"]}
                isRowMenu={isRowMenu}
                points={points}
                handleSetRowMenu={handleSetRowMenu}
                handleLeave={handleLeave}
                id={id}
                isShowed={isHovered && type === "FOLDER"}
              />

              <RowActions
                actions={["move", "download", "rename"]}
                additionalActions={["trash"]}
                isRowMenu={isRowMenu}
                points={points}
                handleSetRowMenu={handleSetRowMenu}
                handleLeave={handleLeave}
                id={id}
                isShowed={isHovered && ["PDF_CERTIFIED", "PDF"].includes(type)}
              />
            </div>
            <div className={styles.td}>{folderItemName[type]}</div>
            <div className={styles.td}>
              {`${childrenCount || 0} ${
                type === "FOLDER"
                  ? `item${childrenCount === 1 ? "" : "s"}`
                  : `page${childrenCount === 1 ? "" : "s"}`
              } `}
            </div>
            <div className={styles.td}>
              {dayjs(modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
            </div>
            {isClicked && (
              <ContextMenu
                theme="light"
                items={getMenuItems({ selectedItems, type })}
                top={points.y}
                left={points.x}
                isFixed
                id={id}
                onClose={handleClearClicked}
              />
            )}
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};
