import {
  FC,
  Fragment,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useMemo,
} from "react";
import dayjs from "dayjs";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

import { useAppSelector, folders, useAppDispatch, dashboard } from "store";
import {
  setCurrentMovingFolder,
  // setIsFolderMoving,
  setItemsToMove,
  setSoftDeletedItems,
} from "store/folders";
import {
  getFolder,
  getMovingFolderParents,
  undoDocument,
} from "store/folders/thunks";

import { Icon, Button, Search } from "components/UI";
import { BreadcrumbsMoving } from "components";
import { useEffectOnce, useActions } from "hooks";
import { Folders } from "api";
import { ISearchFolder, IParent } from "types";
import { cs, toastUndo } from "utils";

import folderEmpty from "assets/img/icons/folder-empty-big.svg";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type MovingItemsModalProps = {
  onClose: () => void;
  onCreate: () => void;
  refreshState?: () => void;
  isSent?: boolean;
  isInbox?: boolean;
};

export const MovingItemsModal: FC<MovingItemsModalProps> = ({
  onClose,
  onCreate,
  isSent,
  isInbox,
  refreshState = () => {},
}) => {
  const dispatch = useAppDispatch();
  const {
    itemsToMove,
    allGeneralItems,
    currentFolder,
    currentMovingFolder,
    currentMovingFolderParents,
    destinationFolder,
  } = useAppSelector(folders);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);

  const { handleMoveSuccess } = useActions();
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchValue] = useDebounce(searchValue, 1000);
  const { t } = useTranslation("Modals", { keyPrefix: "MovingItems" });
  const [initialFolder, setInitialFolder] = useState<string>("");
  const [searchedFolders, setSearchedFolders] = useState<ISearchFolder[]>([]);
  const { folder } = currentMovingFolderParents || {};

  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder.id === currentMovingFolder)
        ?.items,
    [allGeneralItems, currentMovingFolder],
  );

  const searchItems = useCallback(async () => {
    if (debouncedSearchValue) {
      const res = await Folders.searchFolder({ query: debouncedSearchValue });
      if (res?.items) {
        setSearchedFolders(res.items);
      }
    }
  }, [debouncedSearchValue]);

  useEffectOnce(() => {
    dispatch(setCurrentMovingFolder(destinationFolder?.id || currentFolder));
    setInitialFolder(currentFolder);
  });

  useEffect(() => {
    if (currentMovingFolder) {
      dispatch(getMovingFolderParents(currentMovingFolder));
    }
  }, [dispatch, currentMovingFolder]);

  useEffect(() => {
    if (currentMovingFolder) {
      dispatch(getFolder({ id: currentMovingFolder }));
    }
  }, [dispatch, currentMovingFolder]);

  useEffect(() => {
    searchItems();
  }, [searchItems]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleOpenFolder = (id: string) => {
    dispatch(setCurrentMovingFolder(id));
  };

  const handleCancelSearch = () => {
    setSearchValue("");
    setSearchedFolders([]);
  };

  const handleSubmit = async () => {
    // dispatch(setIsFolderMoving(true));
    const id = itemsToMove[itemsToMove.length - 1];

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToMove.length > 1;

    let text = t("fileText");
    let textUndo = t("fileTextUndo");

    if (isPlural) {
      text = t("pluralText");
      textUndo = t("pluralTextUndo");
    } else if (isFolder) {
      text = t("folderText");
      textUndo = t("folderTextUndo");
    }

    handleMoveSuccess({ isSent, isInbox });
    (isSent || isInbox) && refreshState();

    // let undo = false;

    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        await dispatch(undoDocument({ folderIds: itemsToMove }));
        dispatch(setItemsToMove([]));
        dispatch(getFolder({ id: currentFolder }));
        dispatch(getFolder({ id: currentMovingFolder }));
        // dispatch(setSoftDeletedItems([]));
        // undo = true;
      },
      // onSubmit: () => {
      //   if (!undo) {
      //     handleMoveSuccess({ isSent, isInbox });
      //     (isSent || isInbox) && refreshState();
      //   }
      // },
    });
    onClose();
  };

  const handleClose = () => {
    onClose();
    dispatch(setCurrentMovingFolder(""));
    dispatch(setItemsToMove([]));
    dispatch(setSoftDeletedItems([]));
  };

  const filteredItems =
    allGeneralItems
      ?.find((item) => item.folder.id === currentMovingFolder)
      ?.items.filter((item) => !itemsToMove.includes(item.id))
      .filter((item) => item.type === "FOLDER") || [];

  const renderInnerBreadCrumbs = (parents: IParent[]) => {
    return (
      <div className={styles.InnerBreadcrumbs}>
        {parents.length > 3 ? (
          <>
            <div className={styles.link}>... /</div>
            <div className={styles.link}>
              {parents[parents.length - 1].name} /
            </div>
            <div className={styles.link}>
              {parents[parents.length - 2].name} /
            </div>
          </>
        ) : (
          parents.map((el, index) => (
            <Fragment key={index}>
              <div className={styles.link}>{el.name}</div>
            </Fragment>
          ))
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.MovingItemsModal}>
        <ModalHeader
          onClose={handleClose}
          title={
            itemsToMove.length > 1
              ? t("moveItemsTo", { count: itemsToMove.length })
              : t("moveItemTo", { count: itemsToMove.length })
          }
        />

        <div className={styles.header}>
          <Search
            onChange={handleSearch}
            value={searchValue}
            className={styles.search}
            onClear={() => setSearchValue("")}
          />
          {searchedFolders.length > 0 ? (
            <Button
              className={styles.addFolder}
              size="sm"
              variant="textBlack"
              title={t("cancelButtonTitle")}
              onClick={handleCancelSearch}
            />
          ) : (
            <Button
              className={styles.addFolder}
              size="sm"
              variant="secondary"
              title=""
              iconStart="add-folder"
              iconStartType="stroke"
              onClick={onCreate}
            />
          )}
        </div>
        {searchedFolders.length === 0 && <BreadcrumbsMoving />}
        {searchedFolders.length > 0 && (
          <ul className={styles.list}>
            {searchedFolders.map((el) => (
              <li
                key={el.id}
                className={cs([styles.listItem, styles.withDir])}
                onClick={() => handleOpenFolder(el.id)}
              >
                <div className={styles.info}>
                  <Icon
                    name="folder-empty-thin"
                    size={25}
                    className={styles.folderIcon}
                  />
                  <div className={styles.nameWithDir}>
                    {renderInnerBreadCrumbs(el.parents)}
                    <div className={styles.name}>{el.name}</div>
                  </div>
                </div>
                <div className={styles.date}>
                  {dayjs(el.modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
                </div>
              </li>
            ))}
          </ul>
        )}
        {searchedFolders.length === 0 && filteredItems?.length > 0 && (
          <ul className={styles.list}>
            {filteredItems.map((el) => (
              <li
                key={el.id}
                className={styles.listItem}
                onClick={() => handleOpenFolder(el.id)}
              >
                <div className={styles.info}>
                  <Icon
                    name="folder-empty-thin"
                    size={25}
                    className={styles.folderIcon}
                  />
                  <div className={styles.name}>{el.name}</div>
                </div>
                <div className={styles.date}>
                  {dayjs(el.modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
                </div>
              </li>
            ))}
          </ul>
        )}
        {searchedFolders.length === 0 && filteredItems?.length === 0 && (
          <div className={styles.noData}>
            <img
              src={folderEmpty}
              alt="noData"
              width={60}
              height={60}
              style={{ opacity: 0.3 }}
            />
            {t("noMoreFolders")}
          </div>
        )}

        <Button
          variant="primary"
          title={
            folder?.name && initialFolder !== currentMovingFolder
              ? `${t("submitButtonTitle")}: ${folder.name}`
              : t("submitButtonTitleInitial")
          }
          onClick={handleSubmit}
          isDisabled={!folder?.name || initialFolder === currentMovingFolder}
          className={styles.submitButton}
        />
      </div>
    </>
  );
};
