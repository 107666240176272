import { FC, Fragment, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppDispatch, useAppSelector, folders } from "store";
import { setCurrentMovingFolder } from "store/folders";

import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type BreadcrumbsMovingProps = {
  className?: string;
};

export const BreadcrumbsMoving: FC<BreadcrumbsMovingProps> = ({
  className,
}) => {
  const dispatch = useAppDispatch();
  const { currentMovingFolderParents, rootFolder, currentMovingFolder } =
    useAppSelector(folders);
  const { parents, folder } = currentMovingFolderParents || {};
  const reversedParents = parents && [...parents].reverse();
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const isRoot = rootFolder?.id === currentMovingFolder;

  const renderArrow = () => (
    <Icon
      name="chevron-left-empty"
      className={styles.arrow}
      color={palette.grey70}
      size={16}
    />
  );

  // const goUp = () => {
  //   if (reversedParents && parents.length > 3) {
  //     dispatch(setCurrentMovingFolder(reversedParents[parents.length - 2].id));
  //   }
  // };

  const goTo = (id: string) => {
    dispatch(setCurrentMovingFolder(id));
    setIsMenu(false);
  };

  const handleOpenMenu = () => {
    setIsMenu(true);
  };
  const handleCloseMenu = () => {
    setIsMenu(false);
  };

  return (
    <nav className={cs([styles.BreadcrumbsMoving, className])}>
      {reversedParents &&
        (parents.length > 3 ? (
          <>
            {/* <>
              <div
                className={styles.link}
                onClick={() => goTo(reversedParents[0].id)}
              >
                {reversedParents[0].name}
              </div>
              {renderArrow()}
            </> */}
            {isMenu && (
              <OutsideClickHandler onOutsideClick={handleCloseMenu}>
                <div className={styles.menu}>
                  <ul className={styles.menuPathes}>
                    {reversedParents
                      .slice(0, parents.length - 1)
                      .map((item, index) => (
                        <li
                          onClick={() => goTo(item.id)}
                          className={styles.menuPath}
                        >
                          <Icon
                            name={index === 0 ? "docs-empty" : "folder-empty"}
                            size={14}
                            color={palette.grey80}
                          />
                          {item.name}
                        </li>
                      ))}
                  </ul>
                </div>
              </OutsideClickHandler>
            )}
            <>
              <div className={styles.link} onClick={handleOpenMenu}>
                .....
              </div>
              {renderArrow()}
            </>
            {reversedParents.slice(parents.length - 2).map((el, index) => (
              <Fragment key={index}>
                <div className={styles.link} onClick={() => goTo(el.id)}>
                  {el.name}
                </div>
                {renderArrow()}
              </Fragment>
            ))}
          </>
        ) : (
          reversedParents.map((el, index) => (
            <Fragment key={index}>
              <div className={styles.link} onClick={() => goTo(el.id)}>
                {el.parentFolderId === null ? "My docs" : el.name}
              </div>
              {renderArrow()}
            </Fragment>
          ))
        ))}
      <div className={cs([styles.link, styles.active])}>
        {isRoot || folder?.name === "My documents" ? "My docs" : folder?.name}
      </div>
    </nav>
  );
};
