import { FC } from "react";

import { Icon } from "components/UI";

import { palette } from "utils";

import styles from "./styles.module.scss";

type PaymentMethodButtonProps = {
  title: string;
  onClick: () => void;
};

export const PaymentMethodButton: FC<PaymentMethodButtonProps> = ({
  title,
  onClick,
}) => {
  return (
    <div className={styles.PaymentMethodButton} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      <Icon
        name="chevron-left-empty"
        className={styles.icon}
        size={20}
        color={palette.grey70}
      />
    </div>
  );
};
