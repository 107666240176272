import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const Inbox = () => {
  const { t } = useTranslation("Pages", {
    keyPrefix: "Inbox",
  });
  return (
    <div className={styles.Inbox}>
      <header className={styles.header}>
        <ul className={styles.links}>
          <li>
            <NavLink
              to={PATHES.INBOX_ALL}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("all")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.INBOX_NEED_TO_SIGN}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("neesToSign")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.INBOX_WAITING_FOR_OTHERS}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("waitingForOthers")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.INBOX_COMPLETED}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("completed")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.INBOX_VOIDED}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("voided")}
            </NavLink>
          </li>
        </ul>
      </header>
      <Outlet />
    </div>
  );
};
