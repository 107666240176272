import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection } from "types";

import { $http } from "./instance";

interface IUploadItem {
  orderNumber: number;
  fileName: string;
  fileSize: number;
}

interface IUpdateItem {
  fileId: string;
  fileName: string;
  fileSize: number;
}

export interface IUploadFiles {
  items: IUploadItem[];
  documentName?: string;
  parentFolderId: string;
}

export interface IUpdateFiles {
  documentId?: string;
  items: IUpdateItem[];
}

export interface IDeleteFiles {
  documentId?: string;
  items: string[];
}

export interface IGetFilesQuotaResponse {
  generalFreemiumQuota: number;
  generalPremiumQuota: number;
  totalQuota: number;
  unusedQuota: number;
  usedQuota: number;
}

export const Files = {
  getFilesQuota: async (): Promise<IGetFilesQuotaResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/files/quota`);
      return res.data;
    } catch (error) {
      if (isAxiosError(error)) {
        error?.message &&
          toastError(
            Array.isArray(error.message) ? error.message[0] : error.message,
          );
      }
    }
  },
  uploadImages: async (data: IUploadFiles): Promise<any> => {
    try {
      const res = await $http.post(`/clients/files/images`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  updateImages: async (data: IUploadFiles): Promise<any> => {
    try {
      const res = await $http.put(`/clients/files/images`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteImages: async (data: IDeleteFiles): Promise<any> => {
    try {
      const res = await $http.delete(`/clients/files/images`, { data });
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
};
