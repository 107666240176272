import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, folders } from "store";
import {
  setRecepientsModal,
  setIsMovingItemsModal,
  setRenameModalItem,
  setIsDeleteItemsModal,
  setCurrentContextFolder,
  setDestinationFolder,
  setSoftDeletedItems,
  setItemsToMove,
} from "store/folders";
import { undoDocument, getFolder } from "store/folders/thunks";

import {
  MovingItemsModal,
  RecipientsModal,
  CreateFolderModal,
  RenameModal,
  AreYouSureModal,
} from "components";
import { Modal } from "components/UI";
import { toastUndo } from "utils";
import { useActions } from "hooks";

// import styles from "./styles.module.scss";

export const MyDocsTableModals: FC = () => {
  const dispatch = useAppDispatch();
  const {
    recepientsModal,
    isMovingItemsModal,
    renameModalItem,
    isDeleteItemsModal,
    itemsToMove,
    allGeneralItems,
    currentFolder,
  } = useAppSelector(folders);
  const { handleDeleteSuccess } = useActions();
  const { t } = useTranslation("Table", {
    keyPrefix: "MyDocs.Modals",
  });

  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  // const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder.id === currentFolder)?.items,
    [allGeneralItems, currentFolder],
  );

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  // const handleCancelMove = () => {
  //   dispatch(setIsFolderMoving(false));
  //   toastInfo("Items moving canceled");
  // };

  // const handleCancelDeletion = () => {
  //   setIsDeleteModal(false);
  //   toastInfo("Moving to trash bin canceled");
  // };

  // const handleSubmitDeletion = () => {
  //   if (isDeleteModal) {
  //     handleDeleteSuccess();
  //   }
  //   setIsDeleteModal(false);
  // };

  // const handleSubmitMoving = () => {
  //   if (isFolderMoving) {
  //     handleMoveSuccess();
  //   }
  // };

  const handleCloseAreYouSure = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSure = () => {
    const id = itemsToMove[itemsToMove.length - 1];

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToMove.length > 1;

    let text = t("movingFileToTrashBinToastText");
    let textUndo = t("movingFileToTrashBinToastTextUndo");

    if (isPlural) {
      text = t("movingPluralToTrashBinToastText");
      textUndo = t("movingPluralToTrashBinToastTextUndo");
    } else if (isFolder) {
      text = t("movingFolderToTrashBinToastText");
      textUndo = t("movingFolderToTrashBinToastTextUndo");
    }

    handleDeleteSuccess();

    // let undo = false;

    toastUndo({
      id,
      text,
      textUndo,
      onUndo: async () => {
        // dispatch(setSoftDeletedItems([]));
        await dispatch(undoDocument({ folderIds: itemsToMove }));
        dispatch(setItemsToMove([]));
        dispatch(getFolder({ id: currentFolder }));
        // undo = true;
      },
      // onSubmit: () => {
      //   if (!undo) {
      //     handleDeleteSuccess();
      //   }
      // },
    });
    dispatch(setIsDeleteItemsModal(false));
    // setIsDeleteModal(true);
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseMoveModal = () => {
    dispatch(setDestinationFolder(null));
    dispatch(setIsMovingItemsModal(false));
    dispatch(setCurrentContextFolder(null));
  };

  return (
    <>
      {/* <Modal isShowed={isFolderMoving} className={styles.MoveModal} noDarkness>
        <MovingProgressModal
          onCancel={handleCancelMove}
          onSuccess={handleSubmitMoving}
          title="Moving items, please wait..."
          iconName="move"
        />
      </Modal>
      <Modal isShowed={isDeleteModal} className={styles.MoveModal} noDarkness>
        <MovingProgressModal
          onCancel={handleCancelDeletion}
          onSuccess={handleSubmitDeletion}
          title="Moving to bin, please wait..."
          iconName="trash"
        />
      </Modal> */}
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={recepientsModal.length > 0}>
        <RecipientsModal onClose={() => dispatch(setRecepientsModal(""))} />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
        />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal onClose={handleCloseRenameModal} />
      </Modal>
      <Modal isShowed={isDeleteItemsModal} onClose={handleCloseAreYouSure}>
        <AreYouSureModal
          onClose={handleCloseAreYouSure}
          onSubmit={handleConfirmAreYouSure}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
    </>
  );
};
