import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type ActionsHistoryModalProps = {
  onClose: () => void;
};

export const ActionsHistoryModal: FC<ActionsHistoryModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "ActionsHistory" });
  const actionsList = [
    {
      action: "Signed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "user.email@gmail.com",
    },
    {
      action: "Viewed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "useruseruseruseruseruseruser.email@gmail.com",
    },
    {
      action: "Created",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "usdsfsdfer.emdfdsfail@gmail.com",
    },
    {
      action: "Signed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "user.email@gmail.com",
    },
    {
      action: "Viewed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "useruseruseruseruseruseruser.email@gmail.com",
    },
    {
      action: "Created",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "usdsfsdfer.emdfdsfail@gmail.com",
    },
    {
      action: "Signed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "user.email@gmail.com",
    },
    {
      action: "Viewed",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "useruseruseruseruseruseruser.email@gmail.com",
    },
    {
      action: "Created",
      date: "on 22 Apr 2024, 10:00am",
      sendBy: "by Recipient (Natalie Portman)",
      email: "usdsfsdfer.emdfdsfail@gmail.com",
    },
  ];

  return (
    <div className={styles.ActionsHistoryModal}>
      <ModalHeader
        onClose={onClose}
        title={t("title")}
        info="Client NDA LLC GMG"
      />
      <ul className={styles.actionsList}>
        {actionsList.map((el) => (
          <li key={el.email} className={styles.action}>
            <div className={styles.title}>{el.action}</div>
            <div className={styles.info}>
              <div>{el.date}</div>
              <div>{el.sendBy}</div>
              <div>{el.email}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
