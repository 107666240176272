import { createAsyncThunk } from "@reduxjs/toolkit";
import { User, Settings, IChangeNameRequest } from "api";

export const getUser = createAsyncThunk("settings/getUser", async () =>
  User.getAccountInfo(),
);

export const updatedUserInfo = createAsyncThunk(
  "settings/updatedUserInfo",
  async (data: IChangeNameRequest) => Settings.changeName(data),
);

export const getDeleteReasons = createAsyncThunk(
  "settings/getDeleteReasons",
  async () => User.getDeleteReasons(),
);
