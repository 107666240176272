import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IContact } from "types";

import {
  getContacts,
  createContact,
  updateContact,
  deleteContacts,
} from "./thunks";

export type ContactsState = {
  search: string;
  allContacts: IContact[];
  selectedItems: IContact[];
  editableContact: string;
  isContactModal: boolean;
  isDeleteItemsModal: boolean;
  itemsToDelete: string[];
};

const initialState: ContactsState = {
  search: "",
  allContacts: [],
  selectedItems: [],
  editableContact: "",
  isContactModal: false,
  isDeleteItemsModal: false,
  itemsToDelete: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setAllContacts(state, { payload }: PayloadAction<IContact[]>) {
      state.allContacts = payload;
    },
    setSelectedItems(state, { payload }: PayloadAction<IContact[]>) {
      state.selectedItems = payload;
    },
    setIsContactModal(state, { payload }: PayloadAction<boolean>) {
      state.isContactModal = payload;
    },
    setEditableContact(state, { payload }: PayloadAction<string>) {
      state.editableContact = payload;
    },
    setIsDeleteItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteItemsModal = payload;
    },
    setItemsToDelete(state, { payload }: PayloadAction<string[]>) {
      state.itemsToDelete = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.allContacts = action.payload.items;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.allContacts = action.payload?.id
        ? [...state.allContacts, action.payload]
        : [...state.allContacts];
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.allContacts = action.payload?.id
        ? [
            ...state.allContacts.filter((el) => el.id !== action.payload.id),
            action.payload,
          ]
        : [...state.allContacts];
    });
    builder.addCase(deleteContacts.fulfilled, (state, action) => {
      state.allContacts = state.allContacts.filter(
        (el) => !state.itemsToDelete.includes(el.id),
      );
      state.itemsToDelete = [];
    });
  },
});

export const {
  setSearch,
  setAllContacts,
  setSelectedItems,
  setIsContactModal,
  setEditableContact,
  setIsDeleteItemsModal,
  setItemsToDelete,
} = contactsSlice.actions;

export default contactsSlice.reducer;
