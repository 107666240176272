import { createAsyncThunk } from "@reduxjs/toolkit";
import { Folders, IGetFolder, ICreateFolder, IUndoDocument } from "api";

// export const getFolders = createAsyncThunk("folders/getFolders", async () =>
//   Folders.getFolders(),
// );

export const getFolder = createAsyncThunk(
  "folders/getFolder",
  async (data: IGetFolder) => Folders.getFolder(data),
);

export const getRootFolder = createAsyncThunk("folders/rootFolder", async () =>
  Folders.getRootFolder(),
);

export const getFolderParents = createAsyncThunk(
  "folders/folderParents",
  async (id: string) => Folders.getFolderParents(id),
);

export const getMovingFolderParents = createAsyncThunk(
  "folders/movingFolderParents",
  async (id: string) => Folders.getFolderParents(id),
);

export const createFolder = createAsyncThunk(
  "folders/createFolder",
  async (data: ICreateFolder) => Folders.createFolder(data),
);

export const undoDocument = createAsyncThunk(
  "folders/undoDocument",
  async (data: IUndoDocument) => Folders.undoDocument(data),
);
