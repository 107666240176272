import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import foldersReducer from "./folders";
import signaturesReducer from "./signatures";
import trashBinReducer from "./trashBin";
import contactsReducer from "./contacts";
import signingsReducer from "./signings";
import requestSigningReducer from "./requestSigning";
import settingsReducer from "./settings";
import subscriptionReducer from "./subscription";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    folders: foldersReducer,
    signatures: signaturesReducer,
    trashBin: trashBinReducer,
    contacts: contactsReducer,
    signings: signingsReducer,
    requestSigning: requestSigningReducer,
    settings: settingsReducer,
    subscription: subscriptionReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const auth = (state: RootState) => state.auth;
export const dashboard = (state: RootState) => state.dashboard;
export const folders = (state: RootState) => state.folders;
export const signatures = (state: RootState) => state.signatures;
export const trashBin = (state: RootState) => state.trashBin;
export const contacts = (state: RootState) => state.contacts;
export const signings = (state: RootState) => state.signings;
export const requestSigning = (state: RootState) => state.requestSigning;
export const settings = (state: RootState) => state.settings;
export const subscription = (state: RootState) => state.subscription;
