import { FC, useState, ChangeEvent, useMemo } from "react";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { useAppSelector, folders, useAppDispatch, dashboard } from "store";
import { getFolder, getFolderParents } from "store/folders/thunks";
import { setOpenedDocument } from "store/dashboard";
import { Folders, SharedDocuments } from "api";

import { Input, Button, Spinner } from "components/UI";
import { palette, toastError, toastSuccess } from "utils";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type RenameModalProps = {
  onClose: () => void;
  isDetailedView?: boolean;
  sentOldName?: string;
  refreshSentState?: () => void;
};

export const RenameModal: FC<RenameModalProps> = ({
  onClose,
  isDetailedView,
  sentOldName,
  refreshSentState = () => [],
}) => {
  const dispatch = useAppDispatch();
  const {
    renameModalItem,
    currentFolder,
    allGeneralItems,
    currentContextFolder,
  } = useAppSelector(folders);
  const { openedDocument } = useAppSelector(dashboard);
  const { t } = useTranslation("Modals", { keyPrefix: "Rename" });
  const [name, setName] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder.id === currentFolder)?.items ||
      [],
    [allGeneralItems, currentFolder],
  );
  const oldItem = useMemo(
    () =>
      isDetailedView
        ? openedDocument
        : openedFolderData.find((item) => item.id === renameModalItem),
    [openedFolderData, renameModalItem, openedDocument, isDetailedView],
  );
  // const oldItemFileExtension = useMemo(
  //   () => oldItem?.name?.substring(oldItem.name.lastIndexOf(".") + 1),
  //   [oldItem],
  // );
  // const oldItemName = useMemo(
  //   () =>
  //     oldItem?.type === "FOLDER"
  //       ? oldItem.name
  //       : removeExtension(oldItem?.name),
  //   [oldItem],
  // );

  const itemType = useMemo(
    () =>
      openedFolderData?.find((item) => item.id === renameModalItem)?.type ||
      (isDetailedView && "PDF") ||
      "FOLDER",
    [openedFolderData, renameModalItem, isDetailedView],
  );

  useEffectOnce(() => {
    if (openedFolderData) {
      const oldName = oldItem?.name || currentContextFolder?.title;
      setName(sentOldName || oldName || "");
    }
  });

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    if (e.target.value.length > 80) return;
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    if (
      openedFolderData
        ?.filter((item) => item.type === oldItem?.type)
        .some((item) => item.name === name)
    ) {
      setIsError(true);
      return;
    }
    if (name) {
      setIsLoading(true);
      try {
        let res;
        if (sentOldName) {
          res = await SharedDocuments.renameSentDoc(renameModalItem, name);
          refreshSentState();
          toastSuccess(t("successToast"));
        } else {
          res = await Folders.updateDocument(renameModalItem, {
            folderName: name,
          });
        }
        if (res.id) {
          toastSuccess(t("successToast"));
          if (itemType === "FOLDER") {
            dispatch(getFolder({ id: res.id }));
            dispatch(getFolderParents(currentFolder));
          }
          isDetailedView &&
            dispatch(
              setOpenedDocument({
                id: res.id,
                url: res.url,
                name: res.name,
                modifiedAt: res.modifiedAt,
              }),
            );
        }
      } catch (error) {
        console.log("error:", error);
        if (isAxiosError(error)) {
          error?.message &&
            toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
        }
      } finally {
        setIsLoading(false);
        onClose();
        dispatch(getFolder({ id: currentFolder }));
      }
    }
  };

  const handleClear = () => {
    setName("");
    setIsError(false);
  };

  return (
    <div className={styles.RenameModal}>
      <ModalHeader onClose={onClose} title={t("title")} />
      <Input
        className={styles.input}
        onChange={handleChangeName}
        value={name}
        error={
          isError && itemType === "FOLDER"
            ? t("folderNameError")
            : isError && t("fileNameError")
        }
        name="name"
        isClearButton
        onClear={handleClear}
        placeholder={
          itemType === "FOLDER"
            ? t("folderNamePlaceholder")
            : t("fileNamePlaceholder")
        }
      />
      <Button
        variant="primary"
        title={
          isLoading ? <Spinner color={palette.white} /> : t("submitButtonTitle")
        }
        onClick={handleSubmit}
        isDisabled={!name || isError || oldItem?.name === name}
      />
    </div>
  );
};
