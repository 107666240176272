import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const UploadFailedIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8091 14.6748L9.05078 12.9165"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7921 12.9331L9.02539 14.6998"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.61641 9.26709C0.716406 9.54209 0.716406 15.2171 4.61641 15.4921H6.21641"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.65771 9.26687C1.98271 1.8252 13.266 -1.1498 14.5577 6.66687C18.166 7.1252 19.6244 11.9335 16.891 14.3252C16.0577 15.0835 14.9827 15.5002 13.8577 15.4919H13.7827"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1663 13.7751C14.1663 14.3917 14.033 14.9751 13.783 15.4917C13.7163 15.6417 13.6413 15.7834 13.558 15.9167C12.8413 17.1251 11.5163 17.9417 9.99967 17.9417C8.48301 17.9417 7.15801 17.1251 6.44134 15.9167C6.35801 15.7834 6.28301 15.6417 6.21634 15.4917C5.96634 14.9751 5.83301 14.3917 5.83301 13.7751C5.83301 11.4751 7.69967 9.6084 9.99967 9.6084C12.2997 9.6084 14.1663 11.4751 14.1663 13.7751Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadFailedIcon;
