import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch, settings, subscription } from "store";
import { getCurrentSubscription, getProducts } from "store/subscription/thunks";

import { PremiumTrial, PlansPricing, LoaderFullScreen } from "components";
import { Button } from "components/UI";

import { PATHES } from "constants/pathes";
import { useSubscription, useEffectOnce } from "hooks";

import styles from "./styles.module.scss";

export const SubscriptionPlans = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPlans",
  });
  const navigate = useNavigate();
  const { isFreemium } = useSubscription();
  const { user } = useAppSelector(settings);
  const { products } = useAppSelector(subscription);

  useEffectOnce(() => {
    dispatch(getCurrentSubscription());
  });

  useEffectOnce(() => {
    dispatch(getProducts());
  });

  const handleGoBack = () => {
    navigate(PATHES.SUBSCRIPTION);
  };

  if (products.length === 0) {
    return <LoaderFullScreen show />;
  }

  return (
    <div className={styles.SubscriptionPlans}>
      <Button
        variant="textBlack"
        title={t("backButtonTitle")}
        size="sm"
        onClick={handleGoBack}
        iconStart="chevron-left-empty"
        iconStartType="stroke"
      />
      {isFreemium && !user?.isTrialUsed && <PremiumTrial />}
      <PlansPricing isPricing />
      <PlansPricing />
    </div>
  );
};
