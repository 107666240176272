import { FC, useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, subscription } from "store";
import {
  getCurrentSubscription,
  getCancelSubscriptionReasons,
} from "store/subscription/thunks";

import { Button, Checkbox } from "components/UI";

import { Subscriptions } from "api";
import { toastSuccess } from "utils";
import { useEffectOnce } from "hooks";

import styles from "./styles.module.scss";

type CancelSubscriptionModalProps = {
  onClose: () => void;
};

export const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  onClose,
}) => {
  const { cancelSubscriptionReasons } = useAppSelector(subscription);
  const { t } = useTranslation("Modals", { keyPrefix: "CancelSubscription" });

  const dispatch = useAppDispatch();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [details, setDetails] = useState<string>("");
  const isOther =
    cancelSubscriptionReasons.filter(
      (el) => selectedReasons.includes(el.id) && el.title === "Other",
    )?.length > 0;

  useEffectOnce(() => {
    dispatch(getCancelSubscriptionReasons());
  });

  useEffect(() => {
    const otherId = cancelSubscriptionReasons?.find(
      (el) => el.title === "other",
    )?.id;
    if (
      details.length > 0 &&
      !isOther &&
      otherId &&
      !selectedReasons.includes(otherId)
    ) {
      setSelectedReasons((prevState) => [...prevState, otherId]);
    }
  }, [details.length, isOther, cancelSubscriptionReasons, selectedReasons]);

  const handleSelectReason = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedReasons((prevState) =>
      prevState.includes(name)
        ? [...prevState.filter((el) => el !== name)]
        : [...prevState, name],
    );
  };

  const handleInputDetails = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setDetails(value);
  };

  const handleSendData = async () => {
    if (selectedReasons.length === 0) {
      return;
    }
    const dataToSend = {
      ...(selectedReasons.length > 0 && { cancelReasonIds: selectedReasons }),
      ...(details && { textReason: details }),
    };

    if (dataToSend) {
      const res = await Subscriptions.cancelStripeSubscription(dataToSend);

      if (res?.message) {
        onClose();
        toastSuccess(t("toastSuccessText"));
        dispatch(getCurrentSubscription());
      }
    }
  };

  return (
    <div className={styles.CancelSubscriptionModal}>
      <h2 className={styles.title}>{t("title")}</h2>
      <ul className={styles.reasons}>
        {cancelSubscriptionReasons.map((el, index) => (
          <li key={index} className={styles.reason}>
            <Checkbox
              title={t(el.title)}
              name={el.id}
              onChange={handleSelectReason}
              isChecked={selectedReasons.includes(el.id)}
              isWhite
            />
          </li>
        ))}
      </ul>
      <textarea
        className={styles.details}
        name="reason_details"
        cols={30}
        rows={10}
        onChange={handleInputDetails}
        value={details}
        maxLength={150}
        placeholder={t("textareaPlaceholder")}
      />
      <Button
        title={t("submitButtonTitle")}
        variant="outlined"
        onClick={handleSendData}
        isDisabled={selectedReasons.length === 0 || (isOther && !details)}
      />
      <Button
        title={t("cancelButtonTitle")}
        variant="textWhite"
        size="sm"
        onClick={onClose}
        className={styles.cancelButton}
      />
    </div>
  );
};
