import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type CancleUploadingModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const CancleUploadingModal: FC<CancleUploadingModalProps> = ({
  onClose,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "CancleUploading" });

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.CancleUploadingModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={t("submitButtonTitle")}
          onClick={handleSubmit}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
