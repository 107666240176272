import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, dashboard, settings } from "store";
import { getQuota } from "store/dashboard/thunks";

import { NoMemoryModal } from "components";
import { Icon, Modal } from "components/UI";

import { palette, cs } from "utils";
import { useEffectOnce, useSubscription } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const CloudMemory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quota } = useAppSelector(dashboard);
  const { t } = useTranslation("CloudMemory");
  const { user } = useAppSelector(settings);
  const { isFreemium } = useSubscription();

  const [isNoMemoryModal, setNoMemoryModal] = useState<boolean>(false);
  const sessionShowLimit = sessionStorage.getItem("showMemoryLimit");
  const memoryFilledPart =
    ((quota?.usedQuota || 1) / (quota?.totalQuota || 1)) * 100;
  const isHighFreeSpace = memoryFilledPart < 50;
  const isLowFreeSpace = memoryFilledPart > 90;
  const usedMBNoLimit = Number(
    ((quota?.usedQuota || 1) / 1024 / 1024).toFixed(1),
  );
  const usedMB = isFreemium && usedMBNoLimit > 200 ? 200 : usedMBNoLimit;
  const totalMB = Math.round((quota?.totalQuota || 1) / 1024 / 1024);
  const leftMB = totalMB - usedMB;
  const leftGB = leftMB / 1024;
  const usedGBNoLimit = Number((usedMB / 1024).toFixed(1));
  const usedGB = usedGBNoLimit > 15 ? 15 : usedGBNoLimit;
  const totalGB = Math.round(totalMB / 1024);
  const isNoFreeSpace = leftMB < 1;
  const premiumGB = Math.round(
    (quota?.generalPremiumQuota || 1) / 1024 / 1024 / 1024,
  );
  const isSubscription = user?.isWebPremium || user?.isPremium;

  useEffectOnce(() => {
    dispatch(getQuota());
  });

  useEffectOnce(() => {
    if (sessionShowLimit !== "false" && isLowFreeSpace) {
      setNoMemoryModal(true);
    }
  });

  const handleCloseNoMemoryModal = () => {
    setNoMemoryModal(false);
    sessionStorage.setItem("showMemoryLimit", "false");
  };

  const handleSubmitNoMemoryModal = () => {
    setNoMemoryModal(false);
    navigate(PATHES.SUBSCRIPTION_PLANS);
  };

  return (
    <>
      <Modal isShowed={isNoMemoryModal}>
        <NoMemoryModal
          title={
            isNoFreeSpace
              ? t("Oops, no memory for synchronisation left")
              : `${leftMB < 1024 ? `${leftMB}MB` : `${leftGB}GB`} ${t(
                  "lowMemoryTitle",
                )}`
          }
          info={isNoFreeSpace ? t("noMemoryInfo") : t("lowMemoryInfo")}
          submitTitle={
            isSubscription
              ? t("premiumSubmitButtonTitle")
              : t("freemiumSubmitButtonTitle")
          }
          submitAction={
            isSubscription
              ? handleCloseNoMemoryModal
              : handleSubmitNoMemoryModal
          }
          cancelTitle={isSubscription ? "" : t("freemiumCancelButtonTitle")}
          cancelAction={handleCloseNoMemoryModal}
          memoryFilledPart={memoryFilledPart}
          usedMB={usedMB}
          usedGB={usedGB}
          totalMB={totalMB}
          totalGB={totalGB}
        />
      </Modal>
      <div className={styles.CloudMemory}>
        <div className={styles.progressBar}>
          <div
            className={cs([
              styles.value,
              isHighFreeSpace && styles.success,
              isLowFreeSpace && styles.error,
            ])}
            style={{ width: `${memoryFilledPart}%` }}
          />
        </div>
        <p className={styles.info}>
          <Icon name="clouds" color={palette.white} />
          {usedMB < 1024 ? `${usedMB}MB` : `${usedGB}GB`}/
          {totalMB < 1024 ? `${totalMB}MB` : `${totalGB}GB`} {t("forSync")}
        </p>
        {!isHighFreeSpace && (
          <div
            className={styles.increaseButton}
            onClick={() => navigate(PATHES.SUBSCRIPTION_PLANS)}
          >
            {t("increaseCloudTo")}
            {premiumGB}GB
          </div>
        )}
      </div>
    </>
  );
};
