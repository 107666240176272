import { createAsyncThunk } from "@reduxjs/toolkit";
import { TrashBin, IGetFolder, IRestoreFolder, IUndoDocument } from "api";

export const getFolder = createAsyncThunk(
  "folders/trash/getFolder",
  async (data: IGetFolder) => TrashBin.getFolder(data),
);

export const getDocuments = createAsyncThunk(
  "folders/trash/getDocuments",
  async () => TrashBin.getDocuments(),
);

export const getFolderParents = createAsyncThunk(
  "folders/trash/folderParents",
  async (id: string) => TrashBin.getFolderParents(id),
);

export const restoreFolder = createAsyncThunk(
  "folders/trash/restoreFolder",
  async (data: IRestoreFolder) => TrashBin.restoreFolder(data),
);

export const undoDocument = createAsyncThunk(
  "folders/trash/undoDocument",
  async (data: IUndoDocument) => TrashBin.undoDocument(data),
);
