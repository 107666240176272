import { createAsyncThunk } from "@reduxjs/toolkit";
import { Subscriptions } from "api";

export const getProducts = createAsyncThunk(
  "subscription/getProducts",
  async () => Subscriptions.getProducts(),
);

export const activateTrial = createAsyncThunk(
  "subscription/activateTrial",
  async () => Subscriptions.activateTrial(),
);

export const getCurrentSubscription = createAsyncThunk(
  "subscription/getCurrentSubscription",
  async () => Subscriptions.getCurrentSubscription(),
);

export const getPaymentMethod = createAsyncThunk(
  "subscription/getPaymentMethod",
  async () => Subscriptions.getPaymentMethod(),
);

export const getCancelSubscriptionReasons = createAsyncThunk(
  "subscription/getCancelSubscriptionReasons",
  async () => Subscriptions.getCancelSubscriptionReasons(),
);
