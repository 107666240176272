import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Signatures,
  ICreateSignatureRequest,
  IEditSignatureRequest,
} from "api";

export const getSignatures = createAsyncThunk(
  "signatures/getSignatures",
  async () => Signatures.getSignatures(),
);

export const createSignature = createAsyncThunk(
  "signatures/createSignature",
  async (data: ICreateSignatureRequest) => Signatures.createSignature(data),
);

export const putSignature = createAsyncThunk(
  "signatures/putSignature",
  async (data: IEditSignatureRequest) => Signatures.putSignature(data),
);

export const deleteSignature = createAsyncThunk(
  "signatures/createSignature",
  async (id: string) => Signatures.deleteSignature(id),
);
