/* eslint-disable no-underscore-dangle */
import { FC, useState, useRef, useEffect } from "react";
import { Annotation, Instance } from "pspdfkit";
// import { useParams } from "react-router-dom";

// import { REACT_APP_PSPDFKIT_ID } from "constants/index"; // update to env secret
import { setStoredInstance } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import { setActiveRecipient } from "store/requestSigning";
import { useAppDispatch, useAppSelector, requestSigning } from "store";

import { RequestWidget } from "components";

import { getEmptyNode, palette } from "utils";
import { useInstance } from "hooks";

import signIcon from "assets/img/pdfView/sign.svg";
import checkboxIcon from "assets/img/pdfView/done-success.svg";
import dateIcon from "assets/img/pdfView/date-time.svg";
import contactsIcon from "assets/img/pdfView/contacts.svg";
import fileIcon from "assets/img/pdfView/file.svg";

export const PdfViewerRequestSigning: FC = () => {
  const dispatch = useAppDispatch();
  const { editedDraft, activeRecipient } = useAppSelector(requestSigning);
  // const { draftId } = useParams();
  const {
    saveInkAnnotationAtachment,
    saveImageAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    getDynamicColor,
    getSavedJSON,
  } = useInstance();
  const recipientId = activeRecipient?.email || "";
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  // const [isCreateInitial, setCreateInitial] = useState<boolean>(false);
  // const [statePSPDFKit, setPSPDFKit] = useState<any>(null);
  const containerRef = useRef(null);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const isCreateInitial = useRef(false);

  const isDev = window.location.href.startsWith(
    "https://dev.apostisign.fulcrum.rocks",
  );

  useEffect(() => {
    const savedJSON = getSavedJSON();
    if (!savedJSON) {
      const savedEditedJSON = editedDraft?.instantJson
        ? {
            ...editedDraft?.instantJson,
            ...(editedDraft?.instantJson.annotations?.length > 0 && {
              annotations: editedDraft?.instantJson?.annotations.map(
                (item: any) => ({
                  ...item,
                }),
              ),
            }),
          }
        : null;

      const dataStringified =
        savedEditedJSON && JSON.stringify(savedEditedJSON);
      localStorage.setItem("instantJSON", dataStringified);
    }
  }, [editedDraft?.instantJson, getSavedJSON]);

  useEffect(() => {
    editedDraft && dispatch(setActiveRecipient(editedDraft?.recipients[0]));
  }, [dispatch, editedDraft]);

  useEffect(() => {
    if (editedDraft?.file?.url) {
      const container = containerRef.current;

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");
        // setPSPDFKit(PSPDFKit);

        const savedJSON = getSavedJSON();

        // View state config
        const initialViewState = new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
          pageSpacing: 10,
          spreadSpacing: 50,
        });

        // Compose toolbar
        const emptyButton = {
          type: "custom",
          id: "emptyButton",
          node: getEmptyNode(6, `1px solid ${palette.grey30}`),
        };
        const initialsButton = {
          type: "custom",
          id: "initials-id",
          title: "Initials",
          icon: contactsIcon,
          onPress: async () => {
            if (PSPDFKit && instance) {
              instance.setViewState((viewState: any) =>
                viewState.set("interactionMode", PSPDFKit.InteractionMode.PAN),
              );
              sessionStorage.removeItem("selectedMode");
              const instantId = PSPDFKit.generateInstantId();
              const rectWidth = 220;
              const rectHeight = 150;
              const pageIndex = onPageIndexRef.current;
              const clientRect = new PSPDFKit.Geometry.Rect({
                left: 800 - rectWidth / 2,
                top: 500 - rectHeight / 2,
                height: rectHeight,
                width: rectWidth,
              });
              const pageRect = instance.transformContentClientToPageSpace(
                clientRect,
                pageIndex,
              ) as any;
              const widget = new PSPDFKit.Annotations.WidgetAnnotation({
                boundingBox: pageRect,
                backgroundColor: getDynamicColor({ PSPDFKit }),
                formFieldName: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
                id: instantId,
                pageIndex,
                name: instantId,
                customData: {
                  isInitial: true,
                },
              });
              const formField = new PSPDFKit.FormFields.SignatureFormField({
                annotationIds: PSPDFKit.Immutable.List([widget.id]),
                name: `SIGNATURE_INITIALS_WIDGET_${instantId}`,
                id: instantId,
                options: new PSPDFKit.Immutable.List([
                  new PSPDFKit.FormOption({
                    label: "Check",
                    value: "1",
                  }),
                ]),
              });
              await instance.create([widget, formField]);
              // isCreateInitial.current = true;
              instance.setViewState((viewState: any) =>
                viewState.set(
                  "interactionMode",
                  PSPDFKit.InteractionMode.FORM_CREATOR,
                ),
              );
            }
          },
        };
        const checkboxButton = {
          type: "custom",
          id: "checkbox-id",
          title: "Checkbox",
          icon: checkboxIcon,
          onPress: async () => {
            if (instance) {
              instance.setViewState((viewState: any) =>
                viewState.set(
                  "interactionMode",
                  PSPDFKit.InteractionMode.CHECKBOX_WIDGET,
                ),
              );
            }
          },
        };
        const dateButton = {
          type: "custom",
          id: "date-id",
          title: "Date",
          icon: dateIcon,
          onPress: async () => {
            if (instance) {
              instance.setViewState((viewState: any) =>
                viewState.set(
                  "interactionMode",
                  PSPDFKit.InteractionMode.DATE_WIDGET,
                ),
              );
            }
          },
        };
        const signatureButton = {
          type: "custom",
          id: "signature-id",
          title: "Signature",
          icon: signIcon,
          onPress: async () => {
            isCreateInitial.current = false;
            if (instance) {
              instance.setViewState((viewState: any) =>
                viewState.set(
                  "interactionMode",
                  PSPDFKit.InteractionMode.SIGNATURE_WIDGET,
                ),
              );
            }
          },
        };
        const textButton = {
          type: "custom",
          id: "text-id",
          title: "Text",
          icon: fileIcon,
          onPress: async () => {
            if (instance) {
              instance.setViewState((viewState: any) =>
                viewState.set(
                  "interactionMode",
                  PSPDFKit.InteractionMode.TEXT_WIDGET,
                ),
              );
            }
          },
        };
        const toolbarItems = [
          { type: "sidebar-thumbnails" },
          { type: "pager" },
          { type: "zoom-out" },
          { type: "zoom-in" },
          { type: "zoom-mode" },
          { type: "spacer" },
          { type: "form-creator", className: "form-creator" },
          { type: "signature" },
          { type: "text" },
          { type: "line" },
          { type: "document-editor" },
          { type: "search" },
          { type: "spacer" },
        ];
        toolbarItems.push(signatureButton);
        toolbarItems.push(initialsButton);
        toolbarItems.push(checkboxButton);
        toolbarItems.push(dateButton);
        toolbarItems.push(textButton);
        toolbarItems.push(emptyButton);

        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

        const {
          UI: { createBlock, Recipes, Interfaces },
        } = PSPDFKit;

        instance = await PSPDFKit.load({
          container,
          document: editedDraft.file.url,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          initialViewState,
          toolbarItems,
          enableClipboardActions: true,
          enableHistory: true,
          locale: "en",
          styleSheets: ["/custom-pspdfkit.css"],
          licenseKey: isDev ? process.env.REACT_APP_PSPDFKIT_ID : "",
          ...(savedJSON && {
            instantJSON: savedJSON,
          }),
          customRenderers: {
            Annotation: ({ annotation }: any) => {
              if (annotation.formFieldName?.startsWith("SIGNATURE")) {
                const placeholderEl = document.createElement("div");
                placeholderEl.style.fontSize = "24px";
                placeholderEl.style.height = "100%";
                placeholderEl.style.display = "flex";
                placeholderEl.style.justifyContent = "center";
                placeholderEl.style.alignItems = "center";
                placeholderEl.innerHTML = annotation.customData?.isInitial
                  ? "Initials"
                  : "Sign";
                return {
                  node: document
                    .createElement("div")
                    .appendChild(placeholderEl),
                  append: true,
                };
              }
            },
          },
          ui: {
            [Interfaces.CreateSignature]: ({ props }: any) => {
              return {
                content: createBlock(
                  Recipes?.CreateSignature,
                  props,
                  ({ ui }: any) => {
                    if (isCreateInitial.current) {
                      ui.getBlockById("title").children = "Create Initial";
                      ui.getBlockById("save-signature-checkbox")._props.label =
                        "Save Initial";

                      const textInput = ui.getBlockById("signature-text-input");
                      textInput._props.placeholder = "Initial";
                      textInput._props.label = "Intial here";
                      textInput._props.clearLabel = "Clear initial";

                      const freehand = ui.getBlockById("freehand-canvas");
                      freehand._props.placeholder = "Intial here";
                      freehand._props.clearLabel = "Clear initial";

                      const fontselect = ui.getBlockById("font-selector");
                      if (fontselect._props.items[0].label === "Signature") {
                        fontselect._props.items = fontselect._props.items.map(
                          (item: any) => {
                            return { id: item.id, label: "Initial" };
                          },
                        );
                      }
                    }
                    return ui.createComponent();
                  },
                ).createComponent(),
              };
            },
          },
        });

        instance.setViewState((viewState) => {
          const prevSelectedWidget = sessionStorage.getItem("selectedMode");
          return viewState.set(
            "interactionMode",
            prevSelectedWidget
              ? PSPDFKit.InteractionMode[prevSelectedWidget]
              : PSPDFKit.InteractionMode.SIGNATURE_WIDGET,
          );
        });

        instance.setViewState((viewState) =>
          viewState.set("sidebarMode", PSPDFKit.SidebarMode.THUMBNAILS),
        );

        instance.addEventListener("document.saveStateChange", async (event) => {
          if (event.hasUnsavedChanges) {
            dispatch(setStoredInstance(instance));
          }
        });

        instance.addEventListener("formFields.didSave", () => {
          saveInstanceJSON({ instance, recipientId });
          const prevSelectedWidget = sessionStorage.getItem("selectedMode");

          prevSelectedWidget &&
            instance.setViewState((viewState) =>
              viewState.set(
                "interactionMode",
                PSPDFKit.InteractionMode[prevSelectedWidget],
              ),
            );
        });

        instance.addEventListener("annotations.didSave", () => {
          saveInstanceJSON({ instance, recipientId });
        });

        instance.addEventListener("formFieldValues.didSave", () => {
          saveInstanceJSON({ instance, recipientId });
        });

        instance.addEventListener(
          "viewState.change",
          (viewState, previousViewState) => {
            [
              "SIGNATURE_WIDGET",
              "TEXT_WIDGET",
              "CHECKBOX_WIDGET",
              "DATE_WIDGET",
            ].forEach((item) => {
              if (
                viewState.toJS().interactionMode === "FORM_CREATOR" &&
                previousViewState.toJS().interactionMode === item
              ) {
                sessionStorage.setItem("selectedMode", item);
              }
            });
          },
        );

        // TODO: allow fill form fields for recipients

        // instance.addEventListener("annotations.press", async (event) => {
        //   // const iframe = document.querySelector("iframe");
        //   // setCreateInitial(!!event.annotation?.customData?.isInitial);
        //   isCreateInitial.current = !!event.annotation?.customData?.isInitial;

        //   const innerDoc =
        //     instance?.contentDocument || instance?.contentWindow?.document;
        //   const readOnlyInputs = innerDoc?.querySelectorAll("input");
        //   const readOnlyText = innerDoc?.querySelectorAll<HTMLElement>(
        //     ".PSPDFKit-Annotation-Widget-Text",
        //   );
        //   const readOnlyCheckboxes = innerDoc?.querySelectorAll<HTMLElement>(
        //     ".PSPDFKit-Annotation-Widget-CheckBox",
        //   );
        //   const readOnlySignatures = innerDoc?.querySelectorAll<HTMLElement>(
        //     ".PSPDFKit-Annotation-Widget-Signature",
        //   );
        //   readOnlyInputs?.forEach((item) => item.removeAttribute("disabled"));
        //   [
        //     ...readOnlyText,
        //     ...readOnlyCheckboxes,
        //     ...readOnlySignatures,
        //   ]?.forEach((item) => {
        //     item.style.pointerEvents = "auto";
        //     return "";
        //   });
        // });

        instance.addEventListener("annotations.press", async (event) => {
          instance.setViewState((viewState: any) =>
            viewState.set(
              "interactionMode",
              PSPDFKit.InteractionMode.FORM_CREATOR,
            ),
          );
        });

        instance.addEventListener(
          "storedSignatures.create",
          async (annotation: Annotation) => {
            if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
              saveInkAnnotationAtachment({ annotation, instance });
            }
            if (annotation.imageAttachmentId) {
              saveImageAnnotationAtachment({ annotation, instance });
            }
            saveSignatures({ annotation, instance, PSPDFKit });
          },
        );

        instance.setOnWidgetAnnotationCreationStart((annotation, formField) => {
          return {
            annotation: annotation.set(
              "backgroundColor",
              getDynamicColor({ PSPDFKit }),
              // .set(
              //   "blendMode",
              //   PSPDFKit.BlendMode.lighten,
              // ),
            ),
          };
        });

        instance.addEventListener(
          "viewState.currentPageIndex.change",
          (page: any) => {
            setOnPageIndex(page);
          },
        );

        return () => PSPDFKit && PSPDFKit.unload(container);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedDraft?.file?.url, activeRecipient, isCreateInitial]);

  return (
    <>
      <div
        onMouseEnter={() => {
          dispatch(setIsDetailedViewMenu(false));
        }}
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: "100",
          position: "fixed",
          left: "0",
          top: "9.9rem",
          backgroundColor: "white",
        }}
      />
      <RequestWidget />
    </>
  );
};
