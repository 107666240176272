import { FC } from "react";
import { Provider } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { store } from "store";
import Router from "router";

import { Icon } from "components/UI";

import "react-toastify/dist/ReactToastify.min.css";
import "scss/index.scss";
import styles from "./styles.module.scss";

const CloseButton = ({ closeToast }: any) => (
  <div onClick={closeToast} className={styles.CloseButton}>
    <Icon name="close" size={10} />
  </div>
);

const App: FC = () => {
  return (
    <>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
      >
        <Provider store={store}>
          <Router />
          <ToastContainer
            toastClassName={styles.Toast}
            bodyClassName={styles.ToastBody}
            position="top-center"
            autoClose={5000}
            draggable={false}
            // closeButton
            theme="colored"
            transition={Slide}
            limit={4}
            hideProgressBar
            // closeOnClick
            closeButton={CloseButton}
            containerId="Other"
          />
          <ToastContainer
            toastClassName={styles.Toast}
            bodyClassName={styles.ToastBody}
            position="top-center"
            autoClose={5000}
            draggable={false}
            // closeButton
            theme="colored"
            transition={Slide}
            limit={4}
            hideProgressBar
            // closeOnClick
            closeButton={CloseButton}
            containerId="Undo"
          />
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
