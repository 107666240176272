import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

import { useAppSelector, useAppDispatch, auth } from "store";
import { setLanguage, setDateFormat, setTimeFormat } from "store/dashboard";

import { useEffectOnce } from "hooks";
import i18n from "i18n";

import { Private } from "./Private";
import { Public } from "./Public";

const Router: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(auth);

  useEffectOnce(() => {
    const browserLng = navigator.language?.split("-")[0];
    const storageLng = localStorage.getItem("lng");
    const storageDateFormat = localStorage.getItem("dateFormat");
    const storageTimeFormat = localStorage.getItem("timeFormat");

    if (navigator.language === "en-US") {
      !storageDateFormat && dispatch(setDateFormat("MMM-DD-YYYY"));
      !storageTimeFormat && dispatch(setTimeFormat("hh:mm a"));
    }

    if (storageLng) {
      i18n.changeLanguage(storageLng);
      return;
    }

    if (!storageLng && browserLng) {
      dispatch(setLanguage(browserLng));
      i18n.changeLanguage(browserLng);
    }
  });

  return (
    <BrowserRouter>{isAuthenticated ? <Private /> : <Public />}</BrowserRouter>
  );
};

export default Router;
