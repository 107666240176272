import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon, Modal } from "components/UI";
import { DateTimeModal } from "components";

import { cs } from "utils";

import styles from "./styles.module.scss";

export const DateTimeFormat = () => {
  const { t } = useTranslation("Settings", {
    keyPrefix: "DateTimeFormat",
  });
  const [isDateTimeModal, setIsDateTimeModal] = useState<boolean>(false);

  const handleOpenDateTimeModal = () => {
    setIsDateTimeModal(true);
  };

  const handleCloseDateTimeModal = () => {
    setIsDateTimeModal(false);
  };

  return (
    <>
      <Modal isShowed={isDateTimeModal}>
        <DateTimeModal onClose={handleCloseDateTimeModal} />
      </Modal>
      <div
        className={cs([styles.DateTimeFormat, styles.item])}
        onClick={handleOpenDateTimeModal}
      >
        <Icon name="date-time" size={18} /> {t("title")}
      </div>
    </>
  );
};
