export const getButtonNode = (title: string, imgSrc: string) => {
  const childNodeImage = document.createElement("img");
  childNodeImage.src = imgSrc;
  const childNodeText = document.createTextNode(title);
  const parentNode = document.createElement("div");
  parentNode.style.padding = "0 1rem";
  parentNode.style.marginRight = "-2px";
  parentNode.style.height = "44px";
  parentNode.style.display = "flex";
  parentNode.style.alignItems = "center";
  parentNode.style.gap = "0.8rem";
  parentNode.style.borderLeft = "1px solid #8D8D8E";
  parentNode.style.cursor = "pointer";
  parentNode.onmouseover = function hover() {
    parentNode.style.backgroundColor = "#f0f3f9";
  };
  parentNode.onmouseleave = function hover() {
    parentNode.style.backgroundColor = "initial";
  };
  parentNode.appendChild(childNodeImage);
  parentNode.appendChild(childNodeText);
  return parentNode;
};

export const getEmptyNode = (width: number, borderLeft?: string) => {
  const parentNode = document.createElement("div");
  parentNode.style.width = `${width}rem`;
  parentNode.style.height = "44px";
  borderLeft && (parentNode.style.borderLeft = borderLeft);
  return parentNode;
};
