import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";
import { cs } from "utils";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type AreYouSureModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  info?: string;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  isSubmitPositive?: boolean;
  noCloseOnSubmit?: boolean;
  addInfo?: ReactElement;
};

export const AreYouSureModal: FC<AreYouSureModalProps> = ({
  onClose,
  onSubmit = () => {},
  info,
  cancelButtonTitle,
  confirmButtonTitle,
  isSubmitPositive,
  noCloseOnSubmit,
  addInfo,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "AreYouSure" });

  const handleSubmit = () => {
    onSubmit();
    !noCloseOnSubmit && onClose();
  };

  return (
    <div className={styles.AreYouSureModal}>
      <ModalHeader
        onClose={onClose}
        title={t("title")}
        info={info}
        addInfo={addInfo}
      />
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={confirmButtonTitle || t("confirm")}
          onClick={handleSubmit}
          className={cs([
            styles.confirmButton,
            isSubmitPositive && styles.positive,
          ])}
        />
        <Button
          variant="secondary"
          title={cancelButtonTitle || t("cancel")}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
