import { FC, useState, useEffect, useCallback, useMemo } from "react";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings } from "store";
import { setSelectedDraftItems } from "store/signings";
import { getDrafts } from "store/signings/thunks";

import { NoTableData } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getMenuTitle, getSigningsItems } from "utils";
import { IDraft } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { SigningsTableModals } from "./Modals";
import { SigningsDraftTableRow } from "./Row";
import styles from "./styles.module.scss";

type FieldSorted = "name" | "modifiedAt";

export const SigningsDraftTable: FC = () => {
  const dispatch = useAppDispatch();
  const { search, selectedDraftItems, drafts } = useAppSelector(signings);
  const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsDraft",
  });
  const { t: tG } = useTranslation("General");
  const [tableData, setTableData] = useState<IDraft[]>([]);
  // const storageSorting = localStorage.getItem("sortingSigningsDraft");
  // const parsedSorting = storageSorting && JSON.parse(storageSorting);
  // const [sorting, setSorting] = useState({
  //   modifiedAt: (parsedSorting && parsedSorting.modifiedAt === true) || false,
  //   name: parsedSorting?.name === true || false,
  // });
  const [sorting, setSorting] = useState({
    modifiedAt: false,
    name: false,
  });
  const [lastSorted, setLastSorted] = useState<FieldSorted>("modifiedAt");

  const initialData = useMemo(() => drafts || [], [drafts]);

  const sortTable = useCallback(async () => {
    if (tableData.length > 0) {
      setTableData((prevState) =>
        prevState.slice().sort((a: IDraft, b: IDraft) => {
          let asc = sorting[lastSorted] ? 1 : -1;
          let desc = sorting[lastSorted] ? -1 : 1;

          if (lastSorted === "modifiedAt") {
            asc = sorting[lastSorted] ? -1 : 1;
            desc = sorting[lastSorted] ? 1 : -1;
          }

          return a[lastSorted]?.toLocaleLowerCase() <
            b[lastSorted]?.toLocaleLowerCase()
            ? asc
            : desc;
        }),
      );
    }
  }, [sorting, tableData.length, lastSorted]);

  const handleSort = useCallback((field: FieldSorted) => {
    // localStorage.setItem(
    //   "sortingSigningsDraft",
    //   JSON.stringify({
    //     ...parsedSorting,
    //     [field]: !sorting[field],
    //     lastSorted: field,
    //   }),
    // );
    setLastSorted(field);
    setSorting((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  }, []);

  useEffect(() => {
    sortTable();
  }, [sortTable]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedDraftItems([]));
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setSelectedDraftItems([]));
    dispatch(getDrafts());
  }, [dispatch]);

  useEffect(() => {
    setTableData(drafts);
  }, [drafts]);

  const handleSelectAll = () => {
    dispatch(
      setSelectedDraftItems(
        selectedDraftItems.length < initialData?.length ? [...initialData] : [],
      ),
    );
  };

  const handleSelectItem = (item: IDraft) => {
    dispatch(
      setSelectedDraftItems(
        selectedDraftItems.some((el) => el.id === item.id)
          ? [...selectedDraftItems.filter((el) => el.id !== item.id)]
          : [...selectedDraftItems, item],
      ),
    );
  };

  return (
    <div className={styles.SigningsDraftTable}>
      <SigningsTableModals />
      <header
        className={cs([
          styles.tr,
          selectedDraftItems.length > 0 && styles.active,
        ])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedDraftItems.length === initialData?.length &&
            selectedDraftItems.length > 0
          }
        />
        {selectedDraftItems.length > 0 ? (
          <ul className={styles.actions}>
            {getSigningsItems({
              selectedItemsCount: selectedDraftItems.length,
              signingsType: "draft",
              isAllShowed: true,
            })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({
                  name: el,
                  signingsType: "draft",
                })}
              >
                <Icon name={el} size={18} />{" "}
                {getMenuTitle({
                  name: el,
                  signingsType: "draft",
                })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell
              name={tG("name")}
              onSort={() => handleSort("name")}
              isSorted={sorting.name}
            />
            <HeaderCell name={tG("status")} />
            <HeaderCell name={tG("fileID")} />
            <HeaderCell
              name={t("modificationDate")}
              onSort={() => handleSort("modifiedAt")}
              isSorted={sorting.modifiedAt}
            />
          </>
        )}
      </header>
      {tableData.map((item) => (
        <div key={item.id}>
          <SigningsDraftTableRow
            onChange={() => handleSelectItem(item)}
            item={item}
          />
        </div>
      ))}

      <NoTableData
        isSearch={!!searchValue}
        isNoFilteredData={tableData?.length === 0}
        isNoData={initialData?.length === 0}
        isSigningsSent
      />
    </div>
  );
};
