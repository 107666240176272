import { FC } from "react";

import { SelectedType } from "types";

import { Icon } from "components/UI";

import folderEmpty from "assets/img/icons/folder-empty-big.svg";
import filePreview from "assets/img/file_preview.png";

import styles from "./styles.module.scss";

type DocImageProps = {
  type: SelectedType;
  previewUrl?: string;
};

export const DocImage: FC<DocImageProps> = ({ type, previewUrl }) => (
  <div className={styles.DocImage}>
    {type === "PDF_CERTIFIED" && (
      <Icon name="certification" className={styles.certified} />
    )}
    <img
      className={styles.img}
      src={type === "FOLDER" ? folderEmpty : previewUrl || filePreview}
      alt={type}
    />
  </div>
);
