import { FC, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";
import { Input, Button } from "components/UI";
import { getPasswordIcon } from "utils";

import { useAppDispatch } from "store";
import {
  setPasswordVerificationId,
  setTempPass,
  setIsFromSettings,
} from "store/settings";
import { setAuthenticated } from "store/auth";
import { Settings } from "api";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type ConfirmPasswordModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const ConfirmPasswordModal: FC<ConfirmPasswordModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);
  const { t } = useTranslation("Modals", { keyPrefix: "ConfirmPassword" });
  const { t: tG } = useTranslation("General");

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setIsError(false);
    const res = await Settings.verifyPassword({ password });

    if (res?.verificationId) {
      dispatch(setPasswordVerificationId(res.verificationId));
      dispatch(setTempPass(password));
      dispatch(setIsFromSettings(true));
      onSubmit();
    } else {
      setIsError(true);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  const hadleGoToForgotPassword = () => {
    dispatch(setAuthenticated(false));
    dispatch(setIsFromSettings(true));
    navigate(PATHES.FORGOT_PASSWORD);
  };

  return (
    <div className={styles.ConfirmPasswordModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <Input
        className={styles.input}
        type={isPasswordVisible ? "text" : "password"}
        label={tG("passwordLabel")}
        onChange={handleChangePassword}
        value={password}
        error={isError && t("passwordError")}
        name="password"
        iconEnd={getPasswordIcon(password, isPasswordVisible)}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
        placeholder={tG("passwordPlaceholder")}
      />
      <Button
        variant="textBlack"
        size="sm"
        title={t("forgotButtonTitle")}
        onClick={hadleGoToForgotPassword}
        className={styles.forgotButton}
      />
      <Button
        variant="primary"
        title={t("submitButtonTitle")}
        onClick={handleSubmit}
        isDisabled={!password}
      />
    </div>
  );
};
