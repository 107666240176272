import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings } from "store";
import { setIsDeleteDraftItemsModal } from "store/signings";
import { deleteDrafts } from "store/signings/thunks";

import { AreYouSureModal } from "components";
import { Modal } from "components/UI";

export const SigningsTableModals: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsDraft.Modals",
  });
  const { isDeleteDraftItemsModal, draftsToDelete } = useAppSelector(signings);

  const handleCloseAreYouSureDeleteDraft = () => {
    dispatch(setIsDeleteDraftItemsModal(false));
  };

  const handleConfirmAreYouSureDeleteDraft = () => {
    dispatch(deleteDrafts({ documentIds: draftsToDelete }));
    handleCloseAreYouSureDeleteDraft();
  };

  return (
    <>
      <Modal
        isShowed={isDeleteDraftItemsModal}
        onClose={handleCloseAreYouSureDeleteDraft}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDeleteDraft}
          onSubmit={handleConfirmAreYouSureDeleteDraft}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteIConfirmButtonTitle")}
        />
      </Modal>
    </>
  );
};
