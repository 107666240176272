import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

import { useAppDispatch, useAppSelector, subscription } from "store";
import { setMobileSubscriptionLink } from "store/subscription";
import { getCurrentSubscription } from "store/subscription/thunks";

import { Button, Icon } from "components/UI";

import { palette } from "utils";

import styles from "./styles.module.scss";

export const ExtremelyImportantModal = () => {
  const { mobileSubscriptionLink } = useAppSelector(subscription);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "ExtremelyImportant" });

  const handleCloseModal = () => {
    dispatch(setMobileSubscriptionLink(""));
    dispatch(getCurrentSubscription());
  };

  return (
    <div className={styles.ExtremelyImportantModal}>
      <h2 className={styles.title}>
        <Icon name="warning-error" size={24} color={palette.red} />
        {t("title")}
      </h2>

      <p className={styles.info}> {t("info")}</p>

      <div className={styles.scanSection}>
        <QRCode value={mobileSubscriptionLink} size={150} />
        <p className={styles.scanInfo}>{t("scanToCancel")}</p>
      </div>

      <Button
        title={t("submitButtonTitle")}
        variant="outlined"
        onClick={handleCloseModal}
        className={styles.cancelButton}
      />
    </div>
  );
};
