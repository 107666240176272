import { useState, useEffect, useRef, useCallback } from "react";

import { useAppDispatch } from "store";
import { setSidebarWidth } from "store/dashboard";

export const useResize = () => {
  const dispatch = useAppDispatch();
  const sidebarRef = useRef<HTMLInputElement>(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  //   const [sidebarWidth, setSidebarWidth] = useState(240);

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: any) => {
      if (isResizing && sidebarRef.current) {
        const newWidth =
          mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left;

        if (newWidth < 400 && newWidth > 200) {
          dispatch(
            setSidebarWidth(
              mouseMoveEvent.clientX -
                sidebarRef.current.getBoundingClientRect().left,
            ),
          );
        }
      }
    },
    [isResizing, dispatch],
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return { sidebarRef, startResizing };
};
