import { useState, ChangeEvent, useCallback } from "react";
import { PDFDocument } from "pdf-lib";
import { useTranslation } from "react-i18next";
import axios from "axios";

import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  settings,
} from "store";
import {
  setFilesToSave,
  setIsAddFileModal,
  setFilesLimit,
} from "store/requestSigning";

import {
  toastError,
  toastErrorTwoRows,
  toArrayBuffer,
  blobToFile,
  removeExtension,
} from "utils";

import { PDF } from "api";
import { IRequestFile } from "types";
import watermark from "assets/img/watermark.png";

export const useUploadRequest = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Hooks", {
    keyPrefix: "useUploadRequest",
  });
  const { filesToSave, filesLimit } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  // const { isPremium, isBasic, isTrial } = useSubscription();
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  const uploadFiles = useCallback(
    async (files: File[] | FileList, ids?: string[]) => {
      setIsUploadInProgress(true);

      const isSomeErrorType = Array.from(files)?.some(
        (el) => el.type !== "application/pdf",
      );
      const isSomePdfType = Array.from(files)?.some(
        (el) => el.type === "application/pdf",
      );

      if (isSomeErrorType) {
        return toastError(t("toastUploadFilesError"));
      }

      const filesArr = Array.from(files)?.filter(
        (el) => el.type === "application/pdf",
      );

      if (isSomePdfType) {
        const newFilesToSave: IRequestFile[] = filesArr.map((el, index) => ({
          title: removeExtension(el.name),
          data: el,
          size: el.size,
          id: ids ? ids[index] : "",
        }));
        const totalFilesSize = filesArr.reduce(
          (acc, curVa) => acc + curVa.size,
          0,
        );

        // const fileToDownload = new Blob([newFileToSave.data]);
        // downloadPdf(fileToDownload);
        if (filesLimit - totalFilesSize < 0) {
          toastErrorTwoRows(
            t("toastFileSizeErrorFirst"),
            t("toastFileSizeErrorSecond"),
          );
          setIsUploadInProgress(false);
          return;
        }

        dispatch(setFilesToSave([...filesToSave, ...newFilesToSave]));
        dispatch(setFilesLimit(filesLimit - totalFilesSize));
      }
      setIsUploadInProgress(false);
      dispatch(setIsAddFileModal(false));
    },
    [dispatch, filesToSave, filesLimit, t],
  );

  const mergeFiles = async () => {
    const mergedPdf = await PDFDocument.create();

    await Promise.all(
      filesToSave.map(async (file) => {
        const documentBuffer = file.data && (await toArrayBuffer(file.data));
        const document = await PDFDocument.load(documentBuffer as ArrayBuffer);

        const copiedPages = await mergedPdf.copyPages(
          document,
          document.getPageIndices(),
        );
        copiedPages.forEach((page) => mergedPdf.addPage(page));
      }),
    );

    const mergedPdfBuffer = await mergedPdf.save();

    const newBlob = new Blob([mergedPdfBuffer], { type: "application/pdf" });
    const newPdfDoc = blobToFile(newBlob, filesToSave[0].title);

    return newPdfDoc;
  };

  const handleUploadFile = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    files && uploadFiles(files);
    target.value = "";
  };

  const handleUploadDroppedFile = async (acceptedFiles: File[]) => {
    acceptedFiles && uploadFiles(acceptedFiles);
  };

  const getFileFromInstance = async (instance: any, docName: string) => {
    const buffer = await instance.exportPDF({ flatten: true });
    const blob = new Blob([buffer], { type: "application/pdf" });
    return new File([blob], docName, {
      type: "application/pdf",
    });
  };

  const handleUpdateFile = useCallback(
    async (editedFile: File, documentUserID: string) => {
      if (documentUserID) {
        try {
          // const res = isCertified
          //   ? await PDF.getSignedPdfDocument(documentUserID)
          //   : await PDF.getPdfDocument(documentUserID);
          const res = await PDF.updatePdfDocument({
            documentId: documentUserID,
            fileSize: editedFile.size,
          });
          const { uploadUrl = "", name = "" } = res?.document || {};
          const s3Res =
            uploadUrl &&
            (await axios.put(uploadUrl, editedFile, {
              headers: {
                "Content-Type": "application/pdf",
              },
            }));
          // const s3Res = await axios.get(url);
          const uploadedFile = s3Res.data;
          const selectedFileToUpload = new File([uploadedFile], name, {
            type: "application/pdf",
          });

          return selectedFileToUpload;
        } catch (error) {
          console.log("error:", error);
        }
      }
    },
    [],
  );

  const handleAddWaterMark = async (documentBuffer: ArrayBuffer) => {
    const content = await PDFDocument.load(documentBuffer);

    const pages = content.getPages();
    const embedPngImage = await content.embedPng(watermark);

    if (!user?.isWebPremium && !user?.isPremium) {
      for (const [, page] of Object.entries(pages)) {
        const { width, height } = page.getSize();
        page.drawImage(embedPngImage, {
          x: width - 119,
          y: height - 64,
          width: 99,
          height: 44,
        });
      }
    }

    const pdfBuffer = await content.save();
    return pdfBuffer;
  };

  return {
    onUpload: handleUploadFile,
    onUploadDropped: handleUploadDroppedFile,
    uploadFiles,
    isUploadInProgress,
    mergeFiles,
    handleUpdateFile,
    getFileFromInstance,
    handleAddWaterMark,
  };
};
