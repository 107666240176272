import { useState, useEffect, useCallback, FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, signings, signatures } from "store";

import { Icon, Button } from "components/UI";

import { cs } from "utils";
import { useInstance } from "hooks";

import styles from "./styles.module.scss";

type SignatureWidgetProps = {
  onChangeSignature: () => void;
  handleSignAll: () => void;
  handleCleanAll: () => void;
};

export const SignatureWidget: FC<SignatureWidgetProps> = ({
  onChangeSignature,
  handleSignAll,
  handleCleanAll,
}) => {
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "SignatureWidget",
  });
  const { inboxDoc } = useAppSelector(signings);
  const { isDeleted, isCreated, isUpdated } = useAppSelector(signatures);
  const { getStoredAtachments, getStoredSignatures } = useInstance();
  // const { attachmentsArray } = useAppSelector(signatures);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const attachmentsArray = localStorage.getItem("attachments_storage");
  const parsedArray = attachmentsArray ? JSON.parse(attachmentsArray) : [];
  const atachImageUrl =
    attachmentsArray && parsedArray[parsedArray.length - 1]?.url;
  // const attachmentsArray =
  // storedSignatures
  //   ?.filter((el) => el.customData?.atachment)
  //   .map((el) => el.customData?.atachment) || [];

  const getDefaultSignatureImage = useCallback(async () => {
    const storedAttachments = await getStoredAtachments();
    const storedSignatures = await getStoredSignatures();
    const defaultSignature = storedAttachments.find((el) => el.isDefault);
    if (defaultSignature?.url) {
      setImageUrl(defaultSignature.url);
    } else if (
      atachImageUrl &&
      storedSignatures &&
      storedSignatures.length > 0
    ) {
      setImageUrl(atachImageUrl);
    } else {
      setImageUrl("");
    }
  }, [getStoredAtachments, atachImageUrl, getStoredSignatures]);

  useEffect(() => {
    getDefaultSignatureImage();
  }, [getDefaultSignatureImage, isDeleted, isCreated, isUpdated]);

  if (inboxDoc?.recipients?.length === 0) {
    return <></>;
  }

  return (
    <nav
      className={cs([styles.SignatureWidget, isMinimized && styles.minimized])}
    >
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <h2 className={styles.title}>{t("title")}:</h2>
      <div className={styles.signBox}>
        <img src={imageUrl} alt="" className={styles.signImage} />
        <div className={styles.changeButton} onClick={onChangeSignature}>
          {t("change")}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={t("signButtonTitle")}
          onClick={handleSignAll}
          className={styles.submitButton}
        />
        <Button
          variant="secondary"
          title={t("cleanButtonTitle")}
          onClick={handleCleanAll}
          className={styles.cancelButton}
        />
      </div>
    </nav>
  );
};
