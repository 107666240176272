import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Switcher } from "components";
import {
  PlansList,
  FeaturesList,
  PlanType,
} from "components/Subscription/types";
import { Icon, Button } from "components/UI";
import { cs, palette } from "utils";
import { useSubscription } from "hooks";

import monitorImg from "assets/img/subscription/monitor.svg";
import mobileImg from "assets/img/subscription/mobile.svg";

import styles from "./styles.module.scss";

type PlansOverviewProps = {
  plansList: PlansList;
  featuresList: FeaturesList;
  isAnnually: boolean;
  setIsAnnually: (value: boolean) => void;
  getCanceledButtonTitle: () => string;
  handleRenew: () => void;
};

export const PlansOverview: FC<PlansOverviewProps> = ({
  plansList,
  isAnnually,
  setIsAnnually,
  getCanceledButtonTitle,
  featuresList,
  handleRenew,
}) => {
  const { t } = useTranslation("Subscription", {
    keyPrefix: "PlansOverview",
  });
  const { t: tS } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPlans",
  });
  const { isCanceled } = useSubscription();

  return (
    <div className={styles.PlansOverview}>
      <h3 className={styles.subtitle}>{t("subtitle")}</h3>
      <h2 className={styles.title}>{t("title")}</h2>
      <div className={styles.header}>
        <div className={styles.switcherWrap}>
          {/* <div className={styles.switcherInfo}>
            {t("saveDiscount", { count: discount })}
          </div> */}
          <Switcher onClick={setIsAnnually} isActive={isAnnually} />
        </div>
        {Object.values(plansList).map(
          ({ title, isPopular, price, priceInfo, button, isCurrent }) => (
            <div className={styles.card} key={title}>
              <div className={styles.title}>
                {title}{" "}
                {isPopular && (
                  <div className={styles.label}>{tS("mostPopular")}</div>
                )}
              </div>
              <div className={cs([styles.price, !price && styles.free])}>
                <span>${price?.toFixed()}</span>{" "}
                {price ? `/ ${tS("month")}` : ""}
              </div>
              <div className={styles.priceInfo}>{priceInfo}</div>
              {button && (
                <Button
                  variant="outlinedBlack"
                  shape="rounded"
                  title={button?.title}
                  onClick={button?.action}
                  className={styles.getButton}
                />
              )}
              {isCurrent && isCanceled && (
                <Button
                  variant="outlinedBlack"
                  shape="rounded"
                  title={getCanceledButtonTitle()}
                  onClick={handleRenew}
                  className={styles.getButton}
                />
              )}
            </div>
          ),
        )}
      </div>
      {Object.values(featuresList).map((item) => (
        <div key={item.title} className={styles.row}>
          <div className={styles.title}>{item.title}</div>
          {Object.keys(plansList).map((el) => {
            const feature = item[el as PlanType];
            return (
              <div className={styles.td} key={`${item}${el}`}>
                {feature?.value && feature.value}
                {feature?.isDesktop && <img src={monitorImg} alt="desktop" />}
                {feature?.isMobile && <img src={mobileImg} alt="mobile" />}
                {!feature?.isDesktop &&
                  !feature?.isMobile &&
                  !feature?.value && (
                    <Icon
                      name="close-in-circle"
                      size={20}
                      color={palette.grey50}
                    />
                  )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};
