import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const RestoreIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace = "bottom",
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <>
      <svg
        className={className}
        onClick={action}
        width={width}
        height={height}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={dataTooltipContent}
        data-tooltip-place={dataTooltipPlace}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1253 18.0582C15.7003 17.1165 18.3337 13.8665 18.3337 9.99984C18.3337 5.39984 14.6337 1.6665 10.0003 1.6665C4.44199 1.6665 1.66699 6.29984 1.66699 6.29984M1.66699 6.29984V2.49984M1.66699 6.29984H3.34199H5.36699"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.66699 10C1.66699 14.6 5.40033 18.3333 10.0003 18.3333"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="3 3"
        />
      </svg>

      {dataTooltipId && (
        <Tooltip
          id={dataTooltipId}
          positionStrategy="fixed"
          delayShow={300}
          noArrow
        />
      )}
    </>
  );
};

export default RestoreIcon;
