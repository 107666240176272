import { Fragment, FC } from "react";
import { useDroppable } from "@dnd-kit/core";

import { useAppSelector, folders } from "store";

import { SidebarFolder } from "components";
import { FolderNode } from "types";
import { cs } from "utils";

import styles from "./styles.module.scss";

type SidebarFoldersProps = {
  handleOpenFolder: (folder: string) => void;
  openedFolders: string[];
  isOverRoot: boolean;
  rootName: string;
  newData: FolderNode[];
};

export const SidebarFolders: FC<SidebarFoldersProps> = ({
  handleOpenFolder,
  openedFolders,
  isOverRoot,
  rootName,
  newData,
}) => {
  const { rootFolder } = useAppSelector(folders);
  const { isOver, node, setNodeRef } = useDroppable({
    id: "droppable",
  });

  const isOpenedEl = (name: string, id: string) => {
    return (
      (isOver && node?.current?.outerText.includes(name)) ||
      !!(isOverRoot && rootName) ||
      openedFolders.includes(id)
    );
  };

  const renderSubFolders = (elements: FolderNode[]): any => {
    return elements.map((el, index): any => {
      const childrenCount = el.items.filter(
        (child) =>
          child.parentFolderId === el.folder.id && child.type === "FOLDER",
      )?.length;

      if (el.items.length === 0) {
        return (
          <li
            className={styles.folder}
            key={`${el.folder.id}${index}`}
            style={{
              marginLeft: `${(el.folder.parentsCount || 1) * 10}px`,
              width: `calc(100% - ${(el.folder.parentsCount || 1) * 10}px})`,
            }}
          >
            <SidebarFolder
              title={el.folder.name}
              id={el.folder.id}
              childrenCount={childrenCount}
              isOpened={
                openedFolders.includes(el.folder.id) ||
                isOpenedEl(el.folder.name, el.folder.id)
              }
              handleOpenFolder={() => handleOpenFolder(el.folder.id)}
            />
          </li>
        );
      }
      if (el.childFolders) {
        return [
          <Fragment key={`${el.folder.id}${index}`}>
            <li
              className={styles.folder}
              style={{
                marginLeft: `${(el.folder.parentsCount || 1) * 10}px`,
                width: `calc(100% - ${(el.folder.parentsCount || 1) * 10}px)`,
              }}
            >
              <SidebarFolder
                title={el.folder.name}
                id={el.folder.id}
                childrenCount={childrenCount}
                isOpened={
                  openedFolders.includes(el.folder.id) ||
                  isOpenedEl(el.folder.name, el.folder.id)
                }
                handleOpenFolder={() => handleOpenFolder(el.folder.id)}
              />
            </li>
            {isOpenedEl(el.folder.name, el.folder.id) &&
              renderSubFolders(el.childFolders)}
          </Fragment>,
        ];
      }
      return <></>;
    });
  };

  return (
    <>
      {newData &&
        rootFolder &&
        newData.map((item, indexRoot) => (
          <Fragment key={`${item.folder.id}${indexRoot}`}>
            <ul
              className={cs([
                styles.SidebarFolders,
                isOpenedEl(rootFolder.name, rootFolder.id) && styles.visible,
              ])}
              ref={setNodeRef}
            >
              {renderSubFolders(item.childFolders)}
            </ul>
          </Fragment>
        ))}
    </>
  );
};
