import { ChangeEvent, FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import { cs } from "utils";

import { Icon } from "../Icon";

import styles from "./styles.module.scss";

type SearchProps = {
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickOutside?: () => void;
  onClear?: () => void;
  name?: string;
  disabled?: boolean;
  isAutofocus?: boolean;
  isClearButton?: boolean;
};

export const Search: FC<SearchProps> = ({
  value = "",
  onChange = () => {},
  onClickOutside = () => {},
  onClear = () => {},
  name,
  className,
  disabled,
  isAutofocus = false,
  isClearButton = true,
}) => {
  const { t } = useTranslation("Dashboard");
  return (
    <OutsideClickHandler onOutsideClick={onClickOutside}>
      <div
        className={cs([
          styles.Search,
          className,
          disabled && styles.disabled,
          value && styles.withValue,
        ])}
      >
        <div className={styles.inputWithIcon}>
          <Icon name="search" size={20} />
          <input
            disabled={disabled}
            type="text"
            name={name}
            onChange={onChange}
            value={value}
            autoComplete="off"
            placeholder={t("searchPlaceholder")}
            className={styles.originalInput}
            autoFocus={isAutofocus}
          />
        </div>
        {isClearButton && (
          <Icon
            name="close-in-circle"
            size={20}
            className={styles.clearButton}
            action={onClear}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
