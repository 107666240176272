export const getPasswordIcon = (
  password: string,
  isPasswordVisible: boolean,
) => {
  if (password && isPasswordVisible) {
    return "eye-opened";
  }
  if (password && !isPasswordVisible) {
    return "eye-closed";
  }
  return undefined;
};
