import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector, subscription, dashboard } from "store";
import {
  setTrialExpireModal,
  setPremiumActivatedModal,
  setSubscriptionRequiredModal,
} from "store/subscription";
import { getCurrentSubscription } from "store/subscription/thunks";

import { PATHES } from "constants/pathes";
import { Modal } from "components/UI";
import { SubscriptionSuccessModal, ConfirmModal } from "components";

import { usePremium, useSubscription, useEffectOnce } from "hooks";

export const SidebarModals = () => {
  const { t } = useTranslation("Sidebar");
  const { t: tT } = useTranslation("Subscription", {
    keyPrefix: "TrialSuccess",
  });
  const { t: tS } = useTranslation("Modals", {
    keyPrefix: "SubscriptionSuccess",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isTrialActivatedModal,
    isTrialExpireModal,
    isPremiumActivatedModal,
    isSubscriptionRequiredModal,
    currentSubscription,
    mobileSubscriptionLink,
    trialDaysLeft: trialDaysLeftWS,
  } = useAppSelector(subscription);
  const { dateFormat } = useAppSelector(dashboard);

  const {
    trialDaysLeft: trialDaysLeftFront,
    isCurrentBasicMonthly,
    isCurrentBasicAnnual,
    isCurrentPremiumMonthly,
    isCurrentPremiumAnnual,
    isPremium,
    isPending,
    isFailed,
    isBasic,
  } = useSubscription();
  const { handleSubscribe } = usePremium();

  useEffectOnce(() => {
    dispatch(getCurrentSubscription());
  });

  const getTrialTitle = () => {
    const trialDaysLeft = trialDaysLeftWS || trialDaysLeftFront;
    if (Number(trialDaysLeft) < 0) {
      return t("titleExpired");
    }
    if (Number(trialDaysLeft) === 0) {
      return t("titleWillExpireToday");
    }
    if (Number(trialDaysLeft) === 1) {
      return t("titleWillExpireOne");
    }
    if (Number(trialDaysLeft) > 1) {
      return t("titleWillExpirePlural", { count: Number(trialDaysLeft) });
    }
    return "";
  };

  const handleCloseTrial = () => {
    dispatch(setTrialExpireModal(false));
    sessionStorage.setItem("showTrial", "false");
  };

  const handleSubmitTrial = () => {
    handleCloseTrial();
    navigate(PATHES.SUBSCRIPTION_PLANS);
  };

  const getSubscriptionType = () => {
    if (isCurrentBasicMonthly) {
      return tS("monthBasicType");
    }
    if (isCurrentBasicAnnual) {
      return tS("annualBasicType");
    }
    if (isCurrentPremiumMonthly) {
      return tS("monthPremiumType");
    }
    if (isCurrentPremiumAnnual) {
      return tS("annualPremiumType");
    }
    return "";
  };

  const handleSubmitPremiumActivatedModal = () => {
    dispatch(setPremiumActivatedModal(false));
    dispatch(getCurrentSubscription());
  };

  const handleCloseSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
  };

  const handleSubmitSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
    navigate(PATHES.SUBSCRIPTION_PLANS);
  };

  return (
    <>
      <Modal isShowed={isSubscriptionRequiredModal}>
        <ConfirmModal
          title={
            isBasic ? t("titleOnlyWithPremium") : t("titleOnlyWithBasicPremium")
          }
          info={
            isBasic ? t("infoOnlyWithPremium") : t("infoOnlyWithBasicPremium")
          }
          submitTitle={t("submitButtonTitle")}
          submitAction={handleSubmitSubscriptionRequired}
          cancelTitle={t("cancelButtonTitle")}
          cancelAction={handleCloseSubscriptionRequired}
        />
      </Modal>
      <Modal isShowed={isTrialActivatedModal}>
        <SubscriptionSuccessModal
          title={tT("title")}
          info={tT("activeUntil")}
          type={tT("freeTrial", { count: 7 })}
          submitTitle={tT("confirmButtonTitle")}
          submitAction={handleSubscribe}
          features={[
            { title: "signatureRequests", isActive: true },
            { title: "digitalSignature", isActive: true },
            { title: "noWatermarks", isActive: true },
            { title: "forDevicesSync", isActive: true },
          ]}
        />
      </Modal>
      <Modal isShowed={isTrialExpireModal}>
        <ConfirmModal
          title={getTrialTitle()}
          info={t("info")}
          submitTitle={t("submitButtonTitle")}
          submitAction={handleSubmitTrial}
          cancelTitle={t("cancelButtonTitle")}
          cancelAction={handleCloseTrial}
        />
      </Modal>
      <Modal
        isShowed={
          isPremiumActivatedModal &&
          !isPending &&
          !mobileSubscriptionLink &&
          !isFailed &&
          !isTrialActivatedModal
        }
      >
        <SubscriptionSuccessModal
          title={isPremium ? tS("youGotPremium") : tS("youGotBasic")}
          type={getSubscriptionType()}
          info={`${tT("activeUntil")} ${dayjs(
            currentSubscription?.expiresAt,
          ).format(`${dateFormat}`)}`}
          submitTitle={tT("confirmButtonTitle")}
          submitAction={handleSubmitPremiumActivatedModal}
          features={[
            { title: "signatureRequests", isActive: isPremium },
            { title: "digitalSignature", isActive: true },
            { title: "noWatermarks", isActive: true },
            { title: "forDevicesSync", isActive: true },
          ]}
        />
      </Modal>
    </>
  );
};
