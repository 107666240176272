import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection, ISignature } from "types";

import { $http } from "./instance";

interface IGetSignaturesResponse {
  items: ISignature[];
}
export interface ICreateSignatureRequest {
  isDefault?: boolean;
  file: {
    fileSize: number;
  };
}
interface ICreateSignatureResponse {
  signature: ISignature[];
  fileUpload: {
    url: string;
    urlExpiresAt: string;
  };
}

export interface IEditSignatureRequest {
  id: string;
  data: {
    isDefault: boolean;
  };
}
interface IEditSignatureResponse {
  signature: ISignature[];
  fileUpload: {
    url: string;
    urlExpiresAt: string;
  };
}

export const Signatures = {
  getSignatures: async (): Promise<IGetSignaturesResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/signatures`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  createSignature: async (
    data: ICreateSignatureRequest,
  ): Promise<ICreateSignatureResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/signatures`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  putSignature: async (
    data: IEditSignatureRequest,
  ): Promise<IEditSignatureResponse | undefined> => {
    try {
      const res = await $http.put(`/clients/signatures/${data.id}`, data.data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteSignature: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.delete(`/clients/signatures/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
};
