import { FC, useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

import { useAppDispatch, useAppSelector, signings, folders } from "store";
import { setSelectedSentItems } from "store/signings";
import { getSentDocs } from "store/signings/thunks";

import { NoTableData } from "components";
import { Checkbox, Icon, Spinner } from "components/UI";
import {
  cs,
  getMenuTitle,
  getSigningsItems,
  getSigningsDateTitle,
} from "utils";
import { TSharedDocumentStatus, ISentDoc } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { SigningsSentTableModals } from "./Modals";
import { SigningsSentTableRow } from "./Row";
import styles from "./styles.module.scss";

type FieldSorted = "name" | "sentAt";
type OrderSorted = "DESC" | "ASC";

type SortingType = {
  field: FieldSorted;
  order: OrderSorted;
};

type SigningsTableProps = {
  status?: TSharedDocumentStatus;
};

export const SigningsSentTable: FC<SigningsTableProps> = ({ status }) => {
  const dispatch = useAppDispatch();
  const { t: tG } = useTranslation("General");
  const {
    sentMeta,
    search,
    sentDocs,
    selectedSentItems,
    isDeleteSentItemsModal,
    isMovingItemsModal,
  } = useAppSelector(signings);
  const { softDeletedItems } = useAppSelector(folders);
  const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const [tableData, setTableData] = useState<ISentDoc[]>(sentDocs);
  const storageSorting = localStorage.getItem("sortingSigningsSent");
  const parsedSorting = storageSorting && JSON.parse(storageSorting);
  const dateTitle = getSigningsDateTitle("sent");
  const [sorting, setSorting] = useState<SortingType>({
    field: parsedSorting?.field || "sentAt",
    order: parsedSorting?.order || "DESC",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleSort = useCallback(
    (field: FieldSorted) => {
      const newSortData = {
        field,
        order: (sorting.order === "ASC" ? "DESC" : "ASC") as OrderSorted,
      };
      localStorage.setItem("sortingSigningsSent", JSON.stringify(newSortData));
      setSorting(newSortData);
    },
    [sorting.order],
  );

  const convertStatus = useCallback(() => {
    if (status === "IN_PROGRESS") {
      return "in_progress";
    }
    if (status === "COMPLETED") {
      return "completed";
    }
    if (status === "CANCELED") {
      return "voided";
    }
    return "all";
  }, [status]);

  const getStateDocs = useCallback(() => {
    const convertedSatus = convertStatus();

    dispatch(
      getSentDocs({
        filter: convertedSatus,
        page: 1,
        limit: currentPage * 10,
        orderBy: sorting.field === "sentAt" ? "sent_at" : sorting.field,
        order: sorting.order,
      }),
    );
  }, [dispatch, sorting.field, sorting.order, convertStatus, currentPage]);

  useEffect(() => {
    getStateDocs();
  }, [getStateDocs]);

  useEffect(() => {
    // setTableData((prevState) => [
    //   ...prevState,
    //   ...sentDocs.filter((el) => prevState.every((item) => item.id !== el.id)),
    // ]);
    setTableData(sentDocs);
  }, [sentDocs]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedSentItems([]));
    setTableData([]);
    setCurrentPage(1);
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // const getFilteredDocs = () => {
  //   if (status === "IN_PROGRESS" || status === "COMPLETED") {
  //     return sentDocs?.filter((item) => item.status === status);
  //   }
  //   if (status === "CANCELED") {
  //     return sentDocs?.filter((item) =>
  //       ["CANCELED", "REJECTED", "EXPIRED"].includes(item.status),
  //     );
  //   }
  //   return sentDocs;
  // };

  const handleSelectAll = () => {
    dispatch(
      setSelectedSentItems(
        selectedSentItems.length < tableData?.length ? [...tableData] : [], // .filter((el) => el.status !== "IN_PROGRESS" || status)
      ),
    );
  };

  const handleSelectItem = (item: ISentDoc) => {
    dispatch(
      setSelectedSentItems(
        selectedSentItems.some((el) => el.id === item.id)
          ? [...selectedSentItems.filter((el) => el.id !== item.id)]
          : [...selectedSentItems, item],
      ),
    );
  };

  const handleLoadingMore = () => {
    if (currentPage < Number(sentMeta?.totalPages)) {
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  const refreshState = () => {
    handleClearSelected();
    getStateDocs();
  };

  return (
    <div className={styles.SigningsSentTable}>
      <SigningsSentTableModals refreshState={refreshState} />
      <header
        className={cs([
          styles.tr,
          selectedSentItems.length > 0 && styles.active,
        ])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedSentItems.length === tableData.length &&
            selectedSentItems.length > 0
          }
        />
        {selectedSentItems.length > 0 ? (
          <ul className={styles.actions}>
            {getSigningsItems({
              selectedItemsCount: selectedSentItems.length,
              signingsType: "sent",
              isAllShowed: true,
              signingsStatus: status,
              isAllSelectedItemsInProgress: selectedSentItems.every(
                (el) => el.status === "IN_PROGRESS",
              ),
              isSomeSelectedItemsInProgress: selectedSentItems.some(
                (el) => el.status === "IN_PROGRESS",
              ),
              // isSelectedItemsCompleted: selectedSentItems.every(
              //   (el) => el.status === "COMPLETED",
              // ),
              // isSelectedItemsVoided: selectedSentItems.every((el) =>
              //   ["CANCELED", "REJECTED", "EXPIRED"].includes(el.status),
              // ),
            })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({
                  name: el,
                  signingsType: "sent",
                  signingsStatus: status,
                })}
              >
                <Icon name={el} size={18} />{" "}
                {getMenuTitle({
                  name: el,
                  signingsType: "sent",
                  signingsStatus: status,
                })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell
              name={tG("name")}
              onSort={() => handleSort("name")}
              isSorted={sorting.field === "name" && sorting.order === "ASC"}
            />
            <HeaderCell name={tG("status")} />
            <HeaderCell name={tG("fileID")} />
            <HeaderCell
              name={dateTitle}
              onSort={() => handleSort("sentAt")}
              isSorted={sorting.field === "sentAt" && sorting.order === "ASC"}
            />
          </>
        )}
      </header>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={handleLoadingMore}
        hasMore={Number(sentMeta?.totalItems) > tableData.length}
        loader={
          <div key={0} className={styles.loader}>
            <Spinner />
          </div>
        }
        threshold={20}
      >
        {tableData
          ?.filter(
            (el) =>
              !softDeletedItems.includes(el.id) ||
              isDeleteSentItemsModal ||
              isMovingItemsModal,
          )
          .map((item) => (
            <div key={item.id}>
              <SigningsSentTableRow
                onChange={() => handleSelectItem(item)}
                item={item}
                signingsType="sent"
                signingsStatus={status}
              />
            </div>
          ))}
      </InfiniteScroll>

      <NoTableData
        isSearch={!!searchValue}
        isNoFilteredData={tableData?.length === 0}
        isNoData={
          tableData?.filter(
            (el) =>
              !softDeletedItems.includes(el.id) ||
              isDeleteSentItemsModal ||
              isMovingItemsModal,
          )?.length === 0
        }
        isSigningsSent
        signingsStatus={status}
      />
    </div>
  );
};
