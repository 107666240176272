import { FC, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { useAppSelector, useAppDispatch, subscription } from "store";
import { setMobileSubscriptionLink } from "store/subscription";

import { Icon, Button } from "components/UI";

import { PATHES } from "constants/pathes";
import { BASE_URL } from "constants/index";
import { toastErrorTwoRows, sleep, stripeErrors } from "utils";
import { useSubscription } from "hooks";
import { Subscriptions } from "api";

import mainLogoBlack from "assets/img/main-logo-black.svg";

import styles from "./styles.module.scss";

type PaymentFormProps = {
  isUpdate?: boolean;
};

export const PaymentForm: FC<PaymentFormProps> = ({ isUpdate }) => {
  const dispatch = useAppDispatch();
  const { products } = useAppSelector(subscription);
  const { t } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPayment",
  });
  const {
    monthBasicPrice,
    monthPremiumPrice,
    yearBasicPrice,
    yearPremiumPrice,
    isMobileSub,
  } = useSubscription();
  const navigate = useNavigate();
  const { productId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const selectedProduct = products?.find((el) => el.id === productId);

  const handleGoBack = () => {
    navigate(-1);
  };

  const getSubscriptionInfo = () => {
    if (selectedProduct?.name === "Basic" && selectedProduct?.sku === "month") {
      return t("infoMonthBasic");
    }
    if (
      selectedProduct?.name === "Premium" &&
      selectedProduct?.sku === "month"
    ) {
      return t("infoMonthPremium");
    }
    if (selectedProduct?.name === "Basic" && selectedProduct?.sku === "year") {
      return t("infoAnnualBasic", { count: yearBasicPrice * 12 });
    }
    if (
      selectedProduct?.name === "Premium" &&
      selectedProduct?.sku === "year"
    ) {
      return t("infoAnnualBasic", { count: yearPremiumPrice * 12 });
    }
    return "";
  };

  const getSubscriptionPrice = () => {
    if (selectedProduct?.name === "Basic" && selectedProduct?.sku === "month") {
      return monthBasicPrice;
    }
    if (
      selectedProduct?.name === "Premium" &&
      selectedProduct?.sku === "month"
    ) {
      return monthPremiumPrice;
    }
    if (selectedProduct?.name === "Basic" && selectedProduct?.sku === "year") {
      return yearBasicPrice * 12;
    }
    if (
      selectedProduct?.name === "Premium" &&
      selectedProduct?.sku === "year"
    ) {
      return yearPremiumPrice * 12;
    }
    return 0;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error: submitError }: any = await elements.submit();
    if (submitError) {
      setIsLoading(false);
      console.log("submitError:", submitError);
      return;
    }

    if (!selectedProduct) {
      setIsLoading(false);
      return;
    }

    if (isUpdate && !isMobileSub && selectedProduct) {
      const res = await Subscriptions.upgradeStripeSubscription({
        productId: selectedProduct.id,
      });

      if (res?.message) {
        navigate(PATHES.SUBSCRIPTION);
        // dispatch(setPremiumActivatedModal(true));
      }

      setIsLoading(false);

      return;
    }

    const res = isMobileSub
      ? await Subscriptions.upgradeMobileSubscription({
          productId: selectedProduct.id,
        })
      : await Subscriptions.createStripeSubscription({
          productId: selectedProduct.id,
        });

    const { type, clientSecret } = res || {};

    if (!clientSecret) {
      setIsLoading(false);
      return;
    }

    const confirmIntent =
      type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

    const { error } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${BASE_URL}${PATHES.SUBSCRIPTION}`,
      },
      redirect: "if_required",
    });

    if (error) {
      toastErrorTwoRows(
        stripeErrors("payment_failed"),
        stripeErrors(error?.decline_code || ""),
      );
      setIsLoading(false);
      console.error(error);
    } else {
      if (isMobileSub && res?.mobileSubscriptionLink) {
        dispatch(setMobileSubscriptionLink(res?.mobileSubscriptionLink));
      }
      await sleep(1000);
      navigate(PATHES.SUBSCRIPTION);
      // dispatch(setPremiumActivatedModal(true));
    }

    setIsLoading(false);
  };

  return (
    <form className={styles.PaymentForm} onSubmit={handleSubmit}>
      <Link to={PATHES.SIGN_IN} className={styles.link}>
        <img src={mainLogoBlack} alt="main-logo" width="124" height="33" />
      </Link>
      <Icon
        name="chevron-left-empty"
        action={handleGoBack}
        className={styles.backButton}
      />
      <div className={styles.fieldLong}>
        <h2 className={styles.title}>{t("title")}</h2>
        {selectedProduct && (
          <div className={styles.info}>{getSubscriptionInfo()}</div>
        )}
      </div>
      <PaymentElement className={styles.paymentElement} />
      <Button
        variant="primary"
        type="submit"
        title={t("payButtonTitle", {
          price: getSubscriptionPrice().toFixed(2),
        })}
        onClick={handleSubmit}
        className={styles.submitButton}
        isDisabled={!stripe || isLoading}
      />
    </form>
  );
};
