import i18n from "i18n";

export function validatePassword(value: string) {
  const { t } = i18n;
  const errors: string[] = [];
  const validationPassed = value.match(
    // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+\\/'‘’?:.|"(){}[\]~\-_])[0-9a-zA-Z!@#$%^&+\\/'‘’?:.,|"(){}[\]~\-_]{8,20}$/,
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  );
  const minWidthPassed = value.length >= 8;
  const hasNumber = value.match(/[0-9]+/);
  const hasUppercaseLetter = value.match(/[A-Z]+/);
  const hasLowercaseLetter = value.match(/[a-z]+/);

  if (validationPassed) {
    return errors;
  }
  if (!minWidthPassed) {
    errors.push(t("Utils.Validator.validatePassword.minEightChars"));
  }
  if (!hasNumber) {
    errors.push(t("Utils.Validator.validatePassword.oneNumber"));
  }
  if (!hasLowercaseLetter) {
    errors.push(t("Utils.Validator.validatePassword.oneLowLetter"));
  }
  if (!hasUppercaseLetter) {
    errors.push(t("Utils.Validator.validatePassword.oneUpLetter"));
  }
  return errors;
}

export function validateEmail(value: string) {
  const errors: string[] = [];
  const validationPassed = value.match(
    /^(([^<>()[\]\\.,;:\s@"%&|çÇ]+(\.[^<>()[\]\\.,;:\s@"%&|çÇ]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
  );
  // const validationPassed = value.match(
  //   /^[A-Za-z0-9-]{1,64}@[A-Za-z0-9-]{1,255}\.[A-Za-z]{2,}$/,
  // );

  if (validationPassed) {
    return errors;
  }
  errors.push("Email is incorrect");

  return errors;
}

// export function validatePhone(value: string) {
//   const errors: string[] = [];
//   const validationPassed = value.match(/^$|^\+$|^\+?\d{0,}$/);
//   const minWidthPassed = value.length >= 7;
//   const maxWidthPassed = value.length <= 15;

//   if (!validationPassed) {
//     errors.push("Format is not supported");
//   } else if (value.length > 0 && !minWidthPassed) {
//     errors.push("Has not to be shorter than 7 symbols");
//   } else if (!maxWidthPassed && value.length > 16) {
//     errors.push("Has not to be longer than 15 symbols");
//   } else if (validationPassed) {
//     return errors;
//   }
//   errors.push("Format is not supported");

//   return errors;
// }

// export function validateFirstName(value: string) {
//   const errors: string[] = [];
//   const validationPassed = value.match(/^$|^[ÆØÅæøåa-zA-Z- ']{0,20}$/);
//   const maxWidthPassed = value.length <= 20;
//   const hasSpecialCharacter = value.match(/[!@#$%^&+\\/'‘’?:.,(){}[\]~\-_]+/);
//   const hasNumber = value.match(/[0-9]+/);

//   if (validationPassed) {
//     return errors;
//   }
//   if (!maxWidthPassed) {
//     errors.push("Has not to be longer than 20 symbols");
//   } else if (hasSpecialCharacter) {
//     errors.push("Special characters are not allowed");
//   } else if (hasNumber) {
//     errors.push("Numbers are not allowed");
//   } else {
//     errors.push("Format is not supported");
//   }

//   return errors;
// }

// export function validateLastName(value: string) {
//   const errors: string[] = [];
//   const validationPassed = value.match(/^$|^[ÆØÅæøåa-zA-Z- ']{0,30}$/);
//   const maxWidthPassed = value.length <= 30;
//   const hasSpecialCharacter = value.match(/[!@#$%^&+\\/'‘’?:.,(){}[\]~\-_]+/);
//   const hasNumber = value.match(/[0-9]+/);

//   if (validationPassed) {
//     return errors;
//   }
//   if (!maxWidthPassed) {
//     errors.push("Has not to be longer than 30 symbols");
//   } else if (hasSpecialCharacter) {
//     errors.push("Special characters are not allowed");
//   } else if (hasNumber) {
//     errors.push("Numbers are not allowed");
//   } else {
//     errors.push("Format is not supported");
//   }

//   return errors;
// }
