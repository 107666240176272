import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";

import { AccountButton } from "components";
import styles from "./styles.module.scss";

export const Account = () => {
  const { t } = useTranslation("Pages", {
    keyPrefix: "Account",
  });
  return (
    <div className={styles.Account}>
      <header className={styles.header}>
        <h1 className={styles.title}>{t("title")}</h1>
        <AccountButton />
      </header>

      <ul className={styles.links}>
        <li>
          <NavLink
            to={PATHES.SETTINGS}
            className={({ isActive }) =>
              [styles.link, isActive ? styles.active : ""].join(" ")
            }
          >
            {t("settings")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={PATHES.SUBSCRIPTION}
            className={({ isActive }) =>
              [styles.link, isActive ? styles.active : ""].join(" ")
            }
          >
            {t("subscription")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={PATHES.CONTACTS}
            className={({ isActive }) =>
              [styles.link, isActive ? styles.active : ""].join(" ")
            }
          >
            {t("contacts")}
          </NavLink>
        </li>
      </ul>
      <Outlet />
    </div>
  );
};
