import { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Annotation, Instance } from "pspdfkit";

import { useAppSelector, signings } from "store";

import { SharedDocuments } from "api";

import { Button } from "components/UI";

import { PATHES } from "constants/pathes";
import { toastSuccess } from "utils";

import styles from "./styles.module.scss";

type RecipientSigningFooterProps = {
  stateChanged: boolean;
  stateInstance: Instance | null;
  statePSPDFKit: any;
};

export const RecipientSigningFooter: FC<RecipientSigningFooterProps> = ({
  stateChanged,
  stateInstance,
  statePSPDFKit,
}) => {
  const { t } = useTranslation("RecipientSigningFooter");
  const [isFooter, setIsFooter] = useState<boolean>(false);
  const { inboxDoc } = useAppSelector(signings);
  const navigate = useNavigate();

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsToSignCount = 0;
      let signaturesCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());

      try {
        if (pageIndexes.length > 0 && statePSPDFKit) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations = await stateInstance.getAnnotations(page);

                const widgetsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );

                const signatures = allAnnotations.filter(
                  (el: Annotation) => el.isSignature,
                );

                // Increment counts
                widgetsToSignCount += widgetsToSign.size;
                signaturesCount += signatures.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }

      if (signaturesCount === widgetsToSignCount && signaturesCount > 0) {
        setIsFooter(true);
      } else {
        setIsFooter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInstance, statePSPDFKit, stateChanged]);

  const handleFinish = async () => {
    console.log("handleFinish:");
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    if (isFooter && parsedInstant && inboxDoc?.id) {
      const res = await SharedDocuments.signInboxDoc(inboxDoc.id, {
        instantJson: parsedInstant,
      });
      if (res?.isDocumentCompleted) {
        toastSuccess(t("toastSuccess"));
        navigate(PATHES.INBOX);
      }
    }
  };

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures]);

  return (
    <>
      {isFooter && (
        <footer className={styles.RecipientSigningFooter}>
          <Button
            title={t("submitButtonTitle")}
            onClick={handleFinish}
            variant="primary"
            size="lg"
          />
        </footer>
      )}
    </>
  );
};
