import { FC } from "react";

import { palette } from "utils";

import styles from "./styles.module.scss";

type LoaderProps = {
  size?: number;
  color?: string;
};

export const Loader: FC<LoaderProps> = ({ size = 20, color }) => {
  return (
    <div
      className={styles.Loader}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        color: color || palette.actionGreen,
      }}
    />
  );
};
