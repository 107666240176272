import { FC } from "react";
import { calculateSideByRatio } from "utils";
import { IconProps } from "./index";

const BankIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 16 / 16; // width / height
  let height = 16;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00031 7.5V14H6.50031V7.5H9.50031V14H11.0003V7.5H14.0003V14H15.0003C15.2655 14 15.5199 14.1054 15.7074 14.2929C15.895 14.4804 16.0003 14.7348 16.0003 15V16H0.000313816V15C0.000313816 14.7348 0.105671 14.4804 0.293207 14.2929C0.480743 14.1054 0.735097 14 1.00031 14H2.00031V7.5H5.00031ZM8.00031 0C12.6813 2.572 15.1813 3.95 15.5003 4.134C15.6925 4.24488 15.8424 4.41655 15.9263 4.62197C16.0103 4.82739 16.0235 5.05489 15.9639 5.26865C15.9044 5.48241 15.7754 5.67028 15.5973 5.80269C15.4192 5.93509 15.2022 6.00451 14.9803 6H1.02031C0.798455 6.00451 0.581399 5.93509 0.403324 5.80269C0.22525 5.67028 0.0962732 5.48241 0.0367046 5.26865C-0.0228641 5.05489 -0.00964091 4.82739 0.0742918 4.62197C0.158224 4.41655 0.308099 4.24488 0.500314 4.134C0.820314 3.95 3.32031 2.572 8.00031 0Z"
        fill={color || "#706F6F"}
      />
    </svg>
  );
};

export default BankIcon;
