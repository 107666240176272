import { useTranslation } from "react-i18next";

import { Button, Modal } from "components/UI";
import { CreateFolderModal } from "components";
import { useAppDispatch, useAppSelector, folders } from "store";
import { setCreateModalItem, setCurrentContextFolder } from "store/folders";

import styles from "./styles.module.scss";

export const CreateFolder = () => {
  const dispatch = useAppDispatch();
  const { createModalItem } = useAppSelector(folders);
  const { t } = useTranslation("Dashboard");

  const handleOpenCreateModal = () => {
    dispatch(setCurrentContextFolder(null));
    dispatch(setCreateModalItem(true));
  };

  const handleCloseCreateModal = () => {
    dispatch(setCreateModalItem(false));
  };

  return (
    <div className={styles.CreateFolder}>
      <Modal isShowed={!!createModalItem} onClose={handleCloseCreateModal}>
        <CreateFolderModal onClose={handleCloseCreateModal} />
      </Modal>
      <Button
        title={t("createFolderButtonTitle")}
        variant="secondary"
        onClick={handleOpenCreateModal}
        iconStart="add-folder"
        iconStartType="stroke"
        className={styles.controlButton}
      />
    </div>
  );
};
