import { FC, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  dashboard,
  trashBin,
} from "store";
import { setSelectedSearchItems } from "store/dashboard";
import { getFolder } from "store/folders/thunks";

import { NoTableData } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getSearchItems, getMenuTitle } from "utils";
import { IGlobalSearchDoc } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { GlobalSearchTableRow } from "./Row";
import styles from "./styles.module.scss";

export const GlobalSearchTable: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "GlobalSearch",
  });
  const { t: tG } = useTranslation("General");
  const { currentFolder, createdFolderId } = useAppSelector(folders);
  const { globalSearchItems, selectedSearchItems } = useAppSelector(dashboard);
  const { itemsToRestore } = useAppSelector(trashBin);
  const { getAction } = useActions();

  useEffect(() => {
    createdFolderId && dispatch(getFolder({ id: createdFolderId }));
  }, [createdFolderId, dispatch]);

  useEffect(() => {
    if (currentFolder) {
      dispatch(setSelectedSearchItems([]));
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder, itemsToRestore]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedSearchItems([]));
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toast.dismiss({ containerId: "Undo" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder]);

  const handleSelectAll = () => {
    dispatch(
      setSelectedSearchItems(
        selectedSearchItems.length < globalSearchItems?.length
          ? [...globalSearchItems]
          : [],
      ),
    );
  };

  const handleSelectItem = (item: IGlobalSearchDoc) => {
    dispatch(
      setSelectedSearchItems(
        selectedSearchItems.some((el) => el.id === item.id)
          ? [...selectedSearchItems.filter((el) => el.id !== item.id)]
          : [...selectedSearchItems, item],
      ),
    );
  };

  return (
    <div className={styles.GlobalSearchTable}>
      {/* <MyDocsTableModals /> */}
      <h2 className={styles.title}>
        {globalSearchItems.length
          ? t("results", { count: globalSearchItems.length })
          : t("noResults")}
      </h2>

      <header
        className={cs([
          styles.tr,
          selectedSearchItems.length > 0 && styles.active,
        ])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={selectedSearchItems.length > 0}
        />
        {selectedSearchItems.length > 0 ? (
          <ul className={styles.actions}>
            {getSearchItems({ selectedItems: selectedSearchItems })?.map(
              (el, index) => (
                <li
                  className={styles.action}
                  key={index}
                  onClick={getAction({ name: el })}
                >
                  <Icon name={el} size={18} /> {getMenuTitle({ name: el })}
                </li>
              ),
            )}
          </ul>
        ) : (
          <>
            <HeaderCell name={tG("name")} />
            <HeaderCell name={t("type")} />
            <HeaderCell name={t("attributes")} />
            <HeaderCell name={t("date")} />
          </>
        )}
      </header>

      {globalSearchItems?.map((item, index) => (
        <div
          key={item.id}
          style={
            index === globalSearchItems.length - 1
              ? { marginBottom: "5rem" }
              : {}
          }
        >
          <GlobalSearchTableRow
            onChange={() => handleSelectItem(item)}
            item={item}
          />
        </div>
      ))}

      <NoTableData
        isSearch
        isNoFilteredData={globalSearchItems?.length === 0}
      />
    </div>
  );
};
