import { FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector, dashboard, subscription } from "store";
import { getCurrentSubscription } from "store/subscription/thunks";

import { Icon } from "components/UI";
import { cs, palette } from "utils";
import { useEffectOnce, useSubscription } from "hooks";

import styles from "./styles.module.scss";

export const StatusCard: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Subscription", {
    keyPrefix: "StatusCard",
  });
  const { t: tS } = useTranslation("Subscription");
  const { t: tG } = useTranslation("General");
  const {
    isPending,
    isCanceled,
    isFailed,
    isPaused,
    isOnHold,
    isFreemium,
    isTrial,
    isBasic,
    isPremium,
    isCurrentBasicMonthly,
    isCurrentBasicAnnual,
  } = useSubscription();
  const { dateFormat } = useAppSelector(dashboard);
  const { currentSubscription } = useAppSelector(subscription);

  useEffectOnce(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription());
    }
  });

  const featuresList = [
    {
      isActive:
        (isPremium || isPending || isFailed || isTrial) &&
        !isCurrentBasicMonthly &&
        !isCurrentBasicAnnual,
      title: tS("signatureRequests"),
    },
    {
      isActive:
        isPremium || isBasic || isPending || isCanceled || isFailed || isTrial,
      title: tS("digitalSignature"),
    },
    {
      isActive:
        isPremium || isBasic || isPending || isCanceled || isFailed || isTrial,
      title: tS("noWatermarks"),
    },
    {
      isActive:
        isPremium || isBasic || isPending || isCanceled || isFailed || isTrial,
      title: tS("memoryForDevicesSync"),
    },
  ];

  const getStatus = () => {
    if (isPending) {
      return t("pending");
    }
    if (isCanceled) {
      return t("canceled");
    }
    if (isFailed) {
      return t("failed");
    }
    if (isPaused) {
      return t("paused");
    }
    if (isOnHold) {
      return t("onHold");
    }
    // if (isProcessing) {
    //   return t("processing");
    // }
    if (isTrial) {
      return t("trialActive");
    }
    if (isBasic) {
      return t("basicActive");
    }
    if (isPremium) {
      return t("premiumActive");
    }
    if (isFreemium) {
      return t("freemium");
    }
  };

  const getExpiredDate = () => {
    if (isCanceled) {
      return t("canceledUntil", {
        date: dayjs(currentSubscription?.expiresAt).format(`${dateFormat}`),
        type:
          isCurrentBasicMonthly || isCurrentBasicAnnual ? "basic" : "premium",
      });
    }
    if (isFailed) {
      return t("pleaseCheck");
    }
    if (isPremium || isPending || isOnHold || isPaused) {
      return `${t("premiumUntil")} ${dayjs(
        currentSubscription?.expiresAt,
      ).format(`${dateFormat}`)}`;
    }
    if (isBasic) {
      return `${t("basicUntil")} ${dayjs(currentSubscription?.expiresAt).format(
        `${dateFormat}`,
      )}`;
    }
    if (isTrial) {
      return `${t("trialUntil")} ${dayjs(currentSubscription?.expiresAt).format(
        `${dateFormat}`,
      )}`;
    }
    if (isFreemium) {
      return "";
    }
  };

  const getPaymentInfo = () => {
    if (isTrial) {
      return t("noPayment");
    }
    if (!isFreemium) {
      return currentSubscription?.product?.sku === "month"
        ? `$${Number(
            currentSubscription?.product?.monthlyPriceAmount,
          ).toFixed()} ${t("billedMonthly")}`
        : `$${
            (Number(currentSubscription?.product?.monthlyPriceAmount) || 1) * 12
          } ${t("billedAnnually")}`;
    }
    if (isFreemium) {
      return "";
    }
  };

  return (
    <div className={cs([styles.StatusCard, !isFreemium && styles.active])}>
      <header className={cs([styles.header])}>
        <div className={styles.title}>
          {tG("status")}:{" "}
          <span
            className={cs([
              styles.status,
              !isFreemium && styles.active,
              (isPending || isFailed) && styles.pending,
              isCanceled && styles.canceled,
              (isPaused || isOnHold) && styles.paused,
              // isProcessing && styles.processing,
            ])}
          >
            {getStatus()}
          </span>
        </div>
        {getExpiredDate() && (
          <div className={styles.expiredDate}>{getExpiredDate()}</div>
        )}
        {getPaymentInfo() && (
          <div className={styles.paymentInfo}>{getPaymentInfo()}</div>
        )}
      </header>

      {/* {!isProcessing && ( */}
      <div className={styles.info}>
        <div className={styles.title}>
          {isOnHold ? t("noAccess") : t("privileges")}:
        </div>
        <ul className={styles.featuresList}>
          {featuresList.map((item) => (
            <li key={item.title} className={styles.feature}>
              {item.isActive ? (
                <Icon
                  name="check-in-flower"
                  size={20}
                  color={palette.actionGreen}
                />
              ) : (
                <Icon name="close-in-circle" size={20} color={palette.red} />
              )}
              {item.title}
            </li>
          ))}
        </ul>
      </div>
      {/* )} */}
    </div>
  );
};
