import i18next from "i18next";

import { SelectedType } from "types";

import en from "../assets/locales/en.json";

i18next.addResourceBundle("en", "en", en);

type FolderItem = {
  value: SelectedType;
  title: string;
};

const { t } = i18next;

export const folderItems: FolderItem[] = [
  {
    title: t("Utils.FolderItems.certifiedFiles"),
    value: "PDF_CERTIFIED",
  },
  {
    title: t("Utils.FolderItems.originalFiles"),
    value: "PDF",
  },
  {
    title: t("Utils.FolderItems.folders"),
    value: "FOLDER",
  },
];

export const folderItemName = {
  PDF_CERTIFIED: t("Utils.FolderItems.certifiedFile"),
  PDF: t("Utils.FolderItems.originalFile"),
  FOLDER: t("Utils.FolderItems.folder"),
};
