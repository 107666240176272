import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings } from "store";
import { rejectRequest } from "store/signings/thunks";

import { Button } from "components/UI";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type RejectSigningModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const RejectSigningModal: FC<RejectSigningModalProps> = ({
  onClose,
  onSubmit = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "RejectSigning" });
  const { inboxToRejectRequest } = useAppSelector(signings);
  const [details, setDetails] = useState<string>("");

  const handleSubmit = async () => {
    await Promise.all(
      inboxToRejectRequest.map((el) =>
        dispatch(rejectRequest({ id: el, reason: details })),
      ),
    );
    onSubmit();
    onClose();
  };

  const handleInputDetails = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setDetails(value);
  };

  return (
    <div className={styles.RejectSigningModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={t("info")} />
      <div className={styles.label}>{t("reasonLabel")}</div>
      <textarea
        className={styles.details}
        name="reason_details"
        cols={30}
        rows={10}
        onChange={handleInputDetails}
        value={details}
        maxLength={150}
        placeholder={t("reasonPlaceholder")}
      />
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={t("confirm")}
          onClick={handleSubmit}
          className={styles.confirmButton}
        />
        <Button variant="secondary" title={t("cancel")} onClick={onClose} />
      </div>
    </div>
  );
};
