import { FC, ReactElement } from "react";

import { Icon } from "components/UI";
import { cs } from "utils";

import styles from "./styles.module.scss";

type ModalHeaderProps = {
  onClose: () => void;
  info?: string;
  title?: string;
  className?: string;
  addInfo?: ReactElement;
};

export const ModalHeader: FC<ModalHeaderProps> = ({
  onClose,
  info,
  title,
  className,
  addInfo,
}) => {
  return (
    <div className={cs([styles.ModalHeader, className])}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.infoWrap}>
        {info && <p className={styles.info}>{info}</p>}
        {addInfo && addInfo}
      </div>
    </div>
  );
};
