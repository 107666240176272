import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "store";
import { getCurrentSubscription } from "store/subscription/thunks";

import { Pricing, PlansOverview } from "components";
import { useSubscription } from "hooks";
import { IProduct } from "types";
import { toastSuccess } from "utils";
import { Subscriptions } from "api";
import { PATHES } from "constants/pathes";

type PlansPricingProps = {
  isPricing?: boolean;
};

export const PlansPricing: FC<PlansPricingProps> = ({ isPricing }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Subscription", {
    keyPrefix: "PlansPricing",
  });
  const { t: tS } = useTranslation("Subscription", {
    keyPrefix: "SubscriptionPlans",
  });
  const {
    monthBasic,
    monthPremium,
    yearBasic,
    yearPremium,
    monthBasicPrice,
    monthPremiumPrice,
    yearBasicPrice,
    yearPremiumPrice,
    isPremium,
    isBasic,
    isFreemium,
    isTrial,
    isCanceled,
    isCurrentBasicMonthly,
    isCurrentBasicAnnual,
    isCurrentPremiumMonthly,
    isCurrentPremiumAnnual,
  } = useSubscription();
  const navigate = useNavigate();
  const [isAnnuallyPricing, setIsAnnuallyPricing] = useState<boolean>(true);
  const [isAnnuallyPlans, setIsAnnuallyPlans] = useState<boolean>(true);

  const handleSetSubscription = (product?: IProduct) => {
    if (product) {
      navigate(
        `${
          isFreemium || isTrial
            ? PATHES.SUBSCRIPTION_PAYMENT
            : PATHES.SUBSCRIPTION_UPDATE_PAYMENT
        }/${product.id}`,
      );
    }
  };

  const handleRenewSubscription = async () => {
    const res = await Subscriptions.renewStripeSubscription();

    if (res?.message) {
      toastSuccess(t("toastSuccessRenew"));
      navigate(PATHES.SUBSCRIPTION);
      dispatch(getCurrentSubscription());
    }
  };

  const getCanceledButtonTitle = () => {
    if (isCanceled && isCurrentBasicMonthly) {
      return tS("basicMonthButtonTitle", { type: "Renew" });
    }
    if (isCanceled && isCurrentBasicAnnual) {
      return tS("basicAnnualButtonTitle", { type: "Renew" });
    }
    if (isCanceled && isCurrentPremiumMonthly) {
      return tS("premiumMonthButtonTitle", { type: "Renew" });
    }
    if (isCanceled && isCurrentPremiumAnnual) {
      return tS("premiumAnnualButtonTitle", { type: "Renew" });
    }
    return "";
  };

  const getPlansList = (isAnnually: boolean) => ({
    freemium: {
      title: tS("freemium"),
      price: 0,
      priceInfo: "",
      info: t("onlyMainFeatures"),
      features: [
        t("scaningSignature"),
        t("devicesSync"),
        `200MB ${t("cloudStorage")}`,
      ],
      button: null,
      isPopular: false,
      isCardPremium: false,
      isCurrent: false,
    },
    basic: {
      title: tS("basic"),
      price: isAnnually ? yearBasicPrice : monthBasicPrice,
      priceInfo: isAnnually
        ? `$${yearBasicPrice * 12} ${tS("billedAnnually")}`
        : "",
      info: t("easyWay"),
      features: [
        t("allFreemiumFeatures"),
        t("noWatermarks"),
        t("digitalSignature"),
        `15GB ${t("cloudStorage")}`,
      ],
      button:
        isPremium ||
        (!isAnnually && isBasic) ||
        (isAnnually && isCurrentBasicAnnual)
          ? null
          : {
              title: isAnnually
                ? tS("basicAnnualButtonTitle", { type: "Get" })
                : tS("basicMonthButtonTitle", { type: "Get" }),
              action: isAnnually
                ? () => handleSetSubscription(yearBasic)
                : () => handleSetSubscription(monthBasic),
            },
      isPopular: isAnnually ? yearBasic?.isFavourite : monthBasic?.isFavourite,
      isCardPremium: false,
      isCurrent: isAnnually ? isCurrentBasicAnnual : isCurrentBasicMonthly,
    },
    premium: {
      title: tS("premium"),
      price: isAnnually ? yearPremiumPrice : monthPremiumPrice,
      priceInfo: isAnnually
        ? `$${yearPremiumPrice * 12} ${tS("billedAnnually")}`
        : "",
      info: t("legalSiganture"),
      features: [
        t("allBasicFeatures"),
        t("requestSignatures"),
        t("notificationsAboutRecipients"),
        t("signatureProvision"),
      ],
      button:
        (isPremium && !isAnnually) ||
        (!isAnnually && isCurrentBasicAnnual) ||
        isCanceled
          ? null
          : {
              title: isAnnually
                ? tS("premiumAnnualButtonTitle", { type: "Get" })
                : tS("premiumMonthButtonTitle", { type: "Get" }),
              action: isAnnually
                ? () => handleSetSubscription(yearPremium)
                : () => handleSetSubscription(monthPremium),
            },
      isPopular: isAnnually
        ? yearPremium?.isFavourite
        : monthPremium?.isFavourite,
      isCardPremium: true,
      isCurrent: isAnnually ? isCurrentPremiumAnnual : isCurrentPremiumMonthly,
    },
  });

  const featuresList = {
    eSignature: {
      title: t("eSignature"),
      freemium: { isDesktop: true, isMobile: true, value: "" },
      basic: { isDesktop: true, isMobile: true, value: "" },
      premium: { isDesktop: true, isMobile: true, value: "" },
    },
    scanning: {
      title: t("scanning"),
      freemium: { isDesktop: false, isMobile: true, value: "" },
      basic: { isDesktop: false, isMobile: true, value: "" },
      premium: { isDesktop: false, isMobile: true, value: "" },
    },
    devicesSync: {
      title: t("devicesSync"),
      freemium: { isDesktop: true, isMobile: true, value: "" },
      basic: { isDesktop: true, isMobile: true, value: "" },
      premium: { isDesktop: true, isMobile: true, value: "" },
    },
    cloudStorage: {
      title: t("cloudStorage"),
      freemium: { isDesktop: false, isMobile: false, value: "200MB" },
      basic: { isDesktop: false, isMobile: false, value: "15GB" },
      premium: { isDesktop: false, isMobile: false, value: "15GB" },
    },
    digitalSignature: {
      title: t("digitalSignature"),
      freemium: { isDesktop: false, isMobile: false, value: "" },
      basic: { isDesktop: true, isMobile: true, value: "" },
      premium: { isDesktop: true, isMobile: true, value: "" },
    },
    noWatermarks: {
      title: t("noWatermarks"),
      freemium: { isDesktop: false, isMobile: false, value: "" },
      basic: { isDesktop: true, isMobile: true, value: "" },
      premium: { isDesktop: true, isMobile: true, value: "" },
    },
    inboxSection: {
      title: t("inboxSection"),
      freemium: { isDesktop: false, isMobile: false, value: "" },
      basic: { isDesktop: true, isMobile: false, value: "" },
      premium: { isDesktop: true, isMobile: false, value: "" },
    },
    signatureRequest: {
      title: t("signatureRequest"),
      freemium: { isDesktop: false, isMobile: false, value: "" },
      basic: { isDesktop: false, isMobile: false, value: "" },
      premium: { isDesktop: true, isMobile: false, value: "" },
    },
    emailNotifications: {
      title: t("emailNotifications"),
      freemium: { isDesktop: false, isMobile: false, value: "" },
      basic: { isDesktop: false, isMobile: false, value: "" },
      premium: { isDesktop: true, isMobile: false, value: "" },
    },
  };

  if (isPricing) {
    return (
      <Pricing
        cardsList={getPlansList(isAnnuallyPricing)}
        isAnnually={isAnnuallyPricing}
        setIsAnnually={setIsAnnuallyPricing}
        getCanceledButtonTitle={getCanceledButtonTitle}
        handleRenew={handleRenewSubscription}
      />
    );
  }

  return (
    <PlansOverview
      plansList={getPlansList(isAnnuallyPlans)}
      featuresList={featuresList}
      isAnnually={isAnnuallyPlans}
      setIsAnnually={setIsAnnuallyPlans}
      getCanceledButtonTitle={getCanceledButtonTitle}
      handleRenew={handleRenewSubscription}
    />
  );
};
