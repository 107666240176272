import dayjs from "dayjs";
import { useAppSelector, subscription } from "store";

export const useSubscription = () => {
  const { products, currentSubscription } = useAppSelector(subscription);

  const isExpired = currentSubscription?.status === "EXPIRED";
  const isPremium =
    currentSubscription?.product?.name === "Premium" && !isExpired;
  const isBasic =
    currentSubscription?.product &&
    ["Basic", "Monthly", "Yearly"].includes(currentSubscription.product.name) &&
    !isExpired;
  const isMobileSub =
    currentSubscription?.product &&
    ["Monthly", "Yearly"].includes(currentSubscription.product.name) &&
    !isExpired;
  const isPending = currentSubscription?.status === "PENDING";
  const isPaused = currentSubscription?.status === "PAUSED";
  const isOnHold = currentSubscription?.status === "ON_HOLD";
  const isCanceled = currentSubscription?.status === "CANCELED";
  const isFailed =
    currentSubscription?.status &&
    ["IN_GRACE", "PAYMENT_FAILED"].includes(currentSubscription?.status);
  const isTrial = currentSubscription?.onTrial && !isExpired;
  const isTrialWasUsed =
    currentSubscription?.onTrial &&
    (isExpired || dayjs(currentSubscription.expiresAt) > dayjs());
  const isFreemium =
    isExpired ||
    (!isPremium &&
      !isTrial &&
      !isBasic &&
      !isFailed &&
      !isPaused &&
      !isOnHold &&
      !isPending &&
      !isCanceled);
  const trialDaysLeft =
    isTrial && dayjs(currentSubscription?.expiresAt).diff(dayjs(), "day");

  const monthBasic = products?.find(
    (el) =>
      ["month", "monthlydev", "monthlyprod"].includes(el.sku) &&
      ["Basic", "Monthly"].includes(el.name),
  );
  const monthPremium = products?.find(
    (el) => el.sku === "month" && el.name === "Premium",
  );

  const yearBasic = products?.find(
    (el) =>
      ["year", "yearlydev", "yearlyprod"].includes(el.sku) &&
      ["Basic", "Yearly"].includes(el.name),
  );
  const yearPremium = products?.find(
    (el) => el.sku === "year" && el.name === "Premium",
  );

  const isCurrentBasicMonthly =
    !isExpired &&
    ((currentSubscription?.product?.name === "Basic" &&
      currentSubscription?.product?.sku === "month") ||
      currentSubscription?.product?.name === "Monthly");
  const isCurrentBasicAnnual =
    !isExpired &&
    ((currentSubscription?.product?.name === "Basic" &&
      currentSubscription?.product?.sku === "year") ||
      currentSubscription?.product?.name === "Yearly");
  const isCurrentPremiumMonthly =
    !isExpired &&
    currentSubscription?.product?.name === "Premium" &&
    currentSubscription?.product?.sku === "month";
  const isCurrentPremiumAnnual =
    !isExpired &&
    currentSubscription?.product?.name === "Premium" &&
    currentSubscription?.product?.sku === "year";

  return {
    monthBasicPrice: Number(monthBasic?.monthlyPriceAmount),
    monthPremiumPrice: Number(monthPremium?.monthlyPriceAmount),
    yearBasicPrice: Number(yearBasic?.monthlyPriceAmount),
    yearPremiumPrice: Number(yearPremium?.monthlyPriceAmount),
    monthBasic,
    monthPremium,
    yearBasic,
    yearPremium,
    isPending,
    isPaused,
    isOnHold,
    isCanceled,
    isFailed,
    isFreemium,
    isTrial,
    isBasic,
    isPremium,
    isMobileSub,
    trialDaysLeft,
    isCurrentBasicMonthly,
    isCurrentBasicAnnual,
    isCurrentPremiumMonthly,
    isCurrentPremiumAnnual,
    isTrialWasUsed,
  };
};
