import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "components/UI";
import { PATHES } from "constants/pathes";

import manHi from "assets/img/man-hi.svg";

import styles from "./styles.module.scss";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("NotFound");

  const goToMain = () => {
    navigate(PATHES.HOME);
  };
  return (
    <div className={styles.NotFound}>
      <img src={manHi} alt="manHi" width={450} height={280} />
      <h2 className={styles.title}>{t("title")}</h2>
      <p className={styles.info}>{t("info")}</p>
      <Button
        variant="primary"
        size="md"
        title={t("confirmButtonTitle")}
        onClick={goToMain}
        className={styles.confirmButton}
      />
    </div>
  );
};
