import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const ContactsIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63314 9.05817C7.5498 9.04984 7.4498 9.04984 7.35814 9.05817C5.3748 8.9915 3.7998 7.3665 3.7998 5.3665C3.7998 3.32484 5.4498 1.6665 7.4998 1.6665C9.54147 1.6665 11.1998 3.32484 11.1998 5.3665C11.1915 7.3665 9.61647 8.9915 7.63314 9.05817Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6747 3.3335C15.2914 3.3335 16.5914 4.64183 16.5914 6.25016C16.5914 7.82516 15.3414 9.1085 13.7831 9.16683C13.7164 9.1585 13.6414 9.1585 13.5664 9.16683"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.4666 12.1335C1.44993 13.4835 1.44993 15.6835 3.4666 17.0252C5.75827 18.5585 9.5166 18.5585 11.8083 17.0252C13.8249 15.6752 13.8249 13.4752 11.8083 12.1335C9.52494 10.6085 5.7666 10.6085 3.4666 12.1335Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2832 16.6665C15.8832 16.5415 16.4499 16.2998 16.9165 15.9415C18.2165 14.9665 18.2165 13.3582 16.9165 12.3832C16.4582 12.0332 15.8999 11.7998 15.3082 11.6665"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContactsIcon;
