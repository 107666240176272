import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IFolderInnerData,
  DataType,
  IFolderParents,
  IFolderGeneral,
  SelectedType,
} from "types";

import {
  getFolder,
  restoreFolder,
  getFolderParents,
  getDocuments,
} from "./thunks";
import { getRootFolder } from "../folders/thunks";

interface IOpenedDocument {
  id: string;
  name: string;
  url: string;
  daysLeft?: number;
  type?: SelectedType | string;
}

export type TrashBinState = {
  search: string;
  allDeletedItems: IFolderInnerData[];
  allDocuments: IFolderGeneral[];
  restoredFolderId: string;
  selectedItems: DataType[];
  currentFolder: string;
  currentFolderParents: IFolderParents | null;
  isDeleteItemsModal: boolean;
  itemsToDelete: string[];
  softDeletedItems: string[];
  isRestoreItemsModal: boolean;
  itemsToRestore: string[];
  openedDocument: IOpenedDocument;
};

const initialState: TrashBinState = {
  search: "",
  allDeletedItems: [],
  allDocuments: [],
  restoredFolderId: "",
  selectedItems: [],
  currentFolder: "",
  currentFolderParents: null,
  itemsToDelete: [],
  isDeleteItemsModal: false,
  itemsToRestore: [],
  isRestoreItemsModal: false,
  softDeletedItems: [],
  openedDocument: { url: "", id: "", name: "", type: "" },
};

const trashBinSlice = createSlice({
  name: "trashBin",
  initialState,
  reducers: {
    setCurrentFolder(state, { payload }: PayloadAction<string>) {
      state.currentFolder = payload;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setSelectedItems(state, { payload }: PayloadAction<DataType[]>) {
      state.selectedItems = payload;
    },
    setAllDeletedItems(state, { payload }: PayloadAction<IFolderInnerData[]>) {
      state.allDeletedItems = payload;
    },
    setIsDeleteItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isDeleteItemsModal = payload;
    },
    setItemsToDelete(state, { payload }: PayloadAction<string[]>) {
      state.itemsToDelete = payload;
    },
    setIsRestoreItemsModal(state, { payload }: PayloadAction<boolean>) {
      state.isRestoreItemsModal = payload;
    },
    setItemsToRestore(state, { payload }: PayloadAction<string[]>) {
      state.itemsToRestore = payload;
    },
    setSoftDeletedItems(state, { payload }: PayloadAction<string[]>) {
      state.softDeletedItems = payload;
    },
    setOpenedDocument(state, { payload }: PayloadAction<IOpenedDocument>) {
      state.openedDocument = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolder.fulfilled, (state, action) => {
      if (action.payload) {
        state.allDeletedItems = [
          ...state.allDeletedItems.filter(
            (item) => item.folder.id !== action.payload?.folder?.id,
          ),
          action.payload,
        ];
      }
    });
    builder.addCase(restoreFolder.fulfilled, (state, action) => {
      state.restoredFolderId = action.payload.id;
    });
    builder.addCase(getFolderParents.fulfilled, (state, action) => {
      state.currentFolderParents = action.payload;
    });
    builder.addCase(getDocuments.fulfilled, (state, action) => {
      state.allDocuments = action.payload;
    });
    builder.addCase(getRootFolder.fulfilled, (state, action) => {
      state.currentFolder = action.payload?.id;
    });
  },
});

export const {
  setSearch,
  setSelectedItems,
  setCurrentFolder,
  setIsDeleteItemsModal,
  setAllDeletedItems,
  setItemsToDelete,
  setIsRestoreItemsModal,
  setItemsToRestore,
  setSoftDeletedItems,
  setOpenedDocument,
} = trashBinSlice.actions;

export default trashBinSlice.reducer;
