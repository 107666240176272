import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "components/UI";
import { palette } from "utils";

import styles from "./styles.module.scss";

type SidebarTipProps = {
  onClose: () => void;
};

export const SidebarTip: FC<SidebarTipProps> = ({ onClose }) => {
  const { t } = useTranslation("Sidebar", {
    keyPrefix: "SidebarTip",
  });
  return (
    <div className={styles.SidebarTip}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t("title")}</h2>
        <Icon
          name="close"
          action={onClose}
          className={styles.closeIcon}
          color={palette.grey80}
        />
        <p className={styles.text}>{t("text")}</p>
      </div>
    </div>
  );
};
