import { FC, useState, ChangeEvent } from "react";

import { useAppSelector, folders, useAppDispatch } from "store";
import { setRecipients } from "store/folders";
import { setOpenedDocument } from "store/dashboard";
import { PDF } from "api";

import { Icon, Input, Button } from "components/UI";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type RecipientsModalProps = {
  onClose: () => void;
};

export const RecipientsModal: FC<RecipientsModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { recipients, recepientsModal } = useAppSelector(folders);
  // const [recipients, setRecipients] = useState<string[]>([""]);
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleChangeName = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const res = [...recipients];
    res[index] = e.target.value;
    dispatch(setRecipients(res));
  };

  // const handleClear = (index: number) => {
  //   setRecipients((prevState) => {
  //     const res = [...prevState];
  //     res[index] = "";
  //     return res;
  //   });
  // };

  const handleAddRecipient = () => {
    dispatch(setRecipients([...recipients, ""]));
  };

  const handleRemoveRecipient = (index: number) => {
    const res = [...recipients];
    res.splice(index, 1);
    dispatch(setRecipients(res || [""]));
  };

  const handleSubmit = async () => {
    try {
      const res = await PDF.getPdfDocument(recepientsModal);
      const { url = "", name = "" } = res?.document || {};
      dispatch(setOpenedDocument({ id: recepientsModal, url, name }));
      onClose();
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div className={styles.RecipientsModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Share file</h2>
      {/* <p className={styles.recepientsLabel}>Recipients emails</p> */}
      <ModalHeader
        onClose={onClose}
        title="Share file"
        info="Recipients emails"
      />
      {recipients.map((item, index) => (
        <div className={styles.optionalEmail} key={index}>
          <Input
            className={styles.input}
            onChange={(e) => handleChangeName(e, index)}
            value={item}
            name="email"
            placeholder="Enter email"
            // isClearButton
            // onClear={() => handleClear(index)}
          />
          {index > 0 && (
            <Icon
              name="close-in-circle"
              size={20}
              className={styles.clearButton}
              action={() => handleRemoveRecipient(index)}
            />
          )}
        </div>
      ))}
      <Button
        className={styles.addRecipientButton}
        size="sm"
        variant="textBlack"
        title="Add recipient"
        iconStart="add-recepient"
        iconStartType="stroke"
        onClick={handleAddRecipient}
      />

      <Input
        onChange={(e) => setSubject(e.target.value)}
        value={subject}
        name="text"
        label="Email subject"
        isClearButton
        onClear={() => setSubject("")}
        placeholder="Enter email subject"
      />

      <Input
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        name="text"
        label="Email message (Optional)"
        isClearButton
        onClear={() => setMessage("")}
        placeholder="Enter email message"
      />

      <Button
        variant="primary"
        title="Share"
        onClick={handleSubmit}
        isDisabled={recipients.some((el) => !el) || !subject}
      />
    </div>
  );
};
