import { FC, Fragment } from "react";

import { IParent } from "types";

import styles from "./styles.module.scss";

type DocBreadcrumbsProps = {
  parents: IParent[];
  rootFolderName?: string;
};

export const DocBreadcrumbs: FC<DocBreadcrumbsProps> = ({
  parents,
  rootFolderName,
}) => {
  const curPar = parents.slice().reverse();
  return (
    <div className={styles.DocBreadcrumbs}>
      {curPar.length > 3 ? (
        <>
          <div className={styles.link}>...</div>
          <div className={styles.link}>/ {curPar[curPar.length - 3].name}</div>
          <div className={styles.link}>/ {curPar[curPar.length - 2].name}</div>
          <div className={styles.link}>/ {curPar[curPar.length - 1].name}</div>
        </>
      ) : (
        curPar.map((el, index) => {
          const rootName =
            rootFolderName && index === 0 ? rootFolderName : el.name;
          return (
            <Fragment key={index}>
              <div className={styles.link}>{rootName}</div>
              {index < curPar.length - 1 ? "/" : ""}
            </Fragment>
          );
        })
      )}
    </div>
  );
};
