import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const AddRecepientIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4163 16.25H12.083"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 17.9168V14.5835"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1332 9.05817C10.0498 9.04984 9.94984 9.04984 9.85817 9.05817C7.87484 8.9915 6.29984 7.3665 6.29984 5.3665C6.2915 3.32484 7.94984 1.6665 9.9915 1.6665C12.0332 1.6665 13.6915 3.32484 13.6915 5.3665C13.6915 7.3665 12.1082 8.9915 10.1332 9.05817Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99121 18.1751C8.47454 18.1751 6.96621 17.7917 5.81621 17.0251C3.79954 15.6751 3.79954 13.4751 5.81621 12.1334C8.10788 10.6001 11.8662 10.6001 14.1579 12.1334"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddRecepientIcon;
