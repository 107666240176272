import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const DateTimeIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.317 13.0415C18.3337 12.8665 18.3337 12.6832 18.3337 12.4998V7.49984C18.3337 3.33317 16.667 1.6665 12.5003 1.6665H7.50033C3.33366 1.6665 1.66699 3.33317 1.66699 7.49984V12.4998C1.66699 16.6665 3.33366 18.3332 7.50033 18.3332H12.5003C12.6837 18.3332 12.867 18.3332 13.042 18.3165M18.317 13.0415C17.6337 12.1999 16.592 11.6665 15.417 11.6665C14.5337 11.6665 13.717 11.9748 13.0753 12.4915C12.2087 13.1748 11.667 14.2332 11.667 15.4165C11.667 16.5915 12.2003 17.6332 13.042 18.3165M18.317 13.0415C18.8503 13.6832 19.1667 14.5167 19.1667 15.4167C19.1667 16.5417 18.6667 17.5417 17.8917 18.2333C17.225 18.8083 16.3667 19.1667 15.4167 19.1667C14.5167 19.1667 13.6837 18.8498 13.042 18.3165M2.09993 5.92472H17.9M7.09994 1.75806V5.80804M12.8999 1.75798V5.433M15.6499 14.2415V15.6499L14.4832 16.3498"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DateTimeIcon;
