import { FC, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, auth, settings } from "store";
import {
  setEditableEmail,
  setAuthenticated,
  setVerificationId,
} from "store/auth";
import {
  setVerificationId as settingsSetVerificationId,
  setPasswordVerificationId,
} from "store/settings";
import { Auth, Settings } from "api";

import { Button } from "components/UI";
import { PATHES } from "constants/pathes";
import { cs, sleep } from "utils";

import styles from "./styles.module.scss";

export const EnterCode: FC = () => {
  const dispatch = useAppDispatch();
  const { email, editableEmail, forgotEmail } = useAppSelector(auth);
  const {
    verificationId: settingsVerificationId,
    email: settingsEmail,
    tempPass,
  } = useAppSelector(settings);
  const navigate = useNavigate();
  const { t } = useTranslation("Auth", { keyPrefix: "EnterCode" });
  const [otp, setOtp] = useState<string>("");
  const [count, setCount] = useState<number>(59);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleVerify = useCallback(async () => {
    let res;
    if (editableEmail === "old") {
      res = await Settings.confirmCurrentEmail({
        verificationId: settingsVerificationId,
        verificationCode: otp,
      });
    } else if (editableEmail === "new" && settingsEmail) {
      res = await Settings.changeEmail({
        verificationId: settingsVerificationId,
        verificationCode: otp,
      });
    } else if (forgotEmail) {
      res = await Auth.forgotPasswordVerify({
        email: forgotEmail,
        verificationCode: otp,
      });
    } else if (!editableEmail) {
      res = await Auth.signUpVerify({ email, verificationCode: otp });
    }

    if (res?.verificationId) {
      editableEmail === "old" &&
        dispatch(settingsSetVerificationId(res.verificationId));
      !editableEmail && dispatch(setVerificationId(res.verificationId));
      setSuccess(true);
    } else if (editableEmail === "new" && res?.message === "ok") {
      setSuccess(true);
    } else {
      setError(res?.error || "incorrect");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    settingsEmail,
    otp,
    dispatch,
    forgotEmail,
    settingsVerificationId,
  ]);

  useEffect(() => {
    if (otp.length === 6 && (email || forgotEmail || settingsEmail)) {
      handleVerify();
    } else if (otp.length < 6) {
      setError("");
    }
  }, [otp.length, handleVerify, email, forgotEmail, settingsEmail]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (editableEmail === "old" && success) {
        dispatch(setEditableEmail("new"));
        navigate(PATHES.FORGOT_PASSWORD);
      } else if (editableEmail === "new" && success) {
        dispatch(setAuthenticated(true));
        await sleep(0);
        navigate(PATHES.SETTINGS);
        dispatch(setEditableEmail("updated"));
      } else if (success && !editableEmail) {
        navigate(PATHES.CREATE_PASSWORD);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [success, navigate, dispatch, editableEmail]);

  const getNewCode = async () => {
    setOtp("");
    setError("");
    setCount(59);
    if (editableEmail === "old" && settingsVerificationId) {
      const res = await Settings.verifyPassword({ password: tempPass });
      if (res?.verificationId) {
        dispatch(setPasswordVerificationId(res.verificationId));
        const codeRes = await Settings.verifyCurrentEmail({
          verificationId: res.verificationId,
          email: settingsEmail,
        });
        codeRes?.verificationId &&
          dispatch(settingsSetVerificationId(codeRes.verificationId));
      }
    } else if (editableEmail === "new" && settingsVerificationId) {
      const res = await Settings.verifyPassword({ password: tempPass });
      if (res?.verificationId) {
        const codeRes = await Settings.verifyNewEmail({
          verificationId: res.verificationId,
          email: settingsEmail,
        });
        codeRes?.verificationId &&
          dispatch(settingsSetVerificationId(codeRes.verificationId));
      }
    } else if (forgotEmail && !editableEmail) {
      await Auth.forgotPassword({
        email: forgotEmail,
      });
    } else {
      await Auth.checkEmail({ email });
    }
  };

  return (
    <div className={styles.EnterCode}>
      <h1 className={styles.title}>{t("title")}</h1>
      <div className={styles.email}>
        {t("codeSent")}: {email || settingsEmail}
      </div>
      <div className={styles.codeWrap}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="tel"
          renderSeparator={false}
          renderInput={(props) => <input {...props} />}
          containerStyle={styles.containerStyle}
          inputStyle={cs([
            styles.inputStyle,
            success && styles.inputSuccess,
            error && styles.inputError,
          ])}
          shouldAutoFocus
        />
        {error && (
          <div className={styles.error}>
            {t("codeIs")} {error === "expired" ? t("expired") : t("incorrect")}
          </div>
        )}
      </div>
      {count > 0 ? (
        <div className={styles.resend}>
          {t("resendIn")}:{" "}
          <span className={styles.counter}>
            {dayjs().set("minute", 0).set("second", count).format("mm:ss")}
          </span>
        </div>
      ) : (
        <Button
          variant="textBlack"
          size="sm"
          title={t("buttonTitle")}
          onClick={getNewCode}
          className={styles.resendButton}
        />
      )}
    </div>
  );
};
