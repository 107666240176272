import { createAsyncThunk } from "@reduxjs/toolkit";
import { Files, PDF, Folders } from "api";

export const getQuota = createAsyncThunk("dashboard/getFilesQuota", async () =>
  Files.getFilesQuota(),
);

export const getMetadata = createAsyncThunk(
  "dashboard/getMetadata",
  async (id: string) => PDF.getSignatureInfo(id),
);

export const getGlobalSearchItems = createAsyncThunk(
  "dashboard/getGlobalSearchItems",
  async (query: string) => Folders.globalSearch({ query }),
);
