import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IProduct,
  ISubscription,
  ICard,
  ICancelSubscriptionReason,
} from "types";

import {
  getProducts,
  getCurrentSubscription,
  getPaymentMethod,
  getCancelSubscriptionReasons,
} from "./thunks";

export type SubscriptionState = {
  isPremium: boolean;
  isRetryFailed: boolean;

  isPremiumModal: boolean;
  isTrialActivatedModal: boolean;
  isTrialExpireModal: boolean;
  isPaymentInitiatedModal: boolean;
  isCancelSubscriptionModal: boolean;
  isPaymentFailedModal: boolean;
  isPremiumActivatedModal: boolean;
  isSubscriptionRequiredModal: boolean;
  isPremiumRequiredModal: boolean;

  products: IProduct[];
  currentSubscription: ISubscription | null;
  paymentMethod: ICard | null;
  cancelSubscriptionReasons: ICancelSubscriptionReason[];
  mobileSubscriptionLink: string;
  trialDaysLeft: number;
};

const initialState: SubscriptionState = {
  isPremium: false,
  isRetryFailed: false,

  isPremiumModal: false,
  isTrialActivatedModal: false,
  isTrialExpireModal: false,
  isPaymentInitiatedModal: false,
  isCancelSubscriptionModal: false,
  isPaymentFailedModal: false,
  isPremiumActivatedModal: false,
  isSubscriptionRequiredModal: false,
  isPremiumRequiredModal: false,

  products: [],
  currentSubscription: null,
  paymentMethod: null,
  cancelSubscriptionReasons: [],
  mobileSubscriptionLink: "",
  trialDaysLeft: 0,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setPremium(state, { payload }: PayloadAction<boolean>) {
      state.isPremium = payload;
    },
    setRetryFailed(state, { payload }: PayloadAction<boolean>) {
      state.isRetryFailed = payload;
    },
    setPremiumModal(state, { payload }: PayloadAction<boolean>) {
      state.isPremiumModal = payload;
    },
    setTrialActivatedModal(state, { payload }: PayloadAction<boolean>) {
      state.isTrialActivatedModal = payload;
    },
    setTrialExpireModal(state, { payload }: PayloadAction<boolean>) {
      state.isTrialExpireModal = payload;
    },
    setPremiumActivatedModal(state, { payload }: PayloadAction<boolean>) {
      state.isPremiumActivatedModal = payload;
    },
    setPaymentInitiatedModal(state, { payload }: PayloadAction<boolean>) {
      state.isPaymentInitiatedModal = payload;
    },
    setCancelSubscriptionModal(state, { payload }: PayloadAction<boolean>) {
      state.isCancelSubscriptionModal = payload;
    },
    setPaymentFailedModal(state, { payload }: PayloadAction<boolean>) {
      state.isPaymentFailedModal = payload;
    },
    setProducts(state, { payload }: PayloadAction<IProduct[]>) {
      state.products = payload;
    },
    setCurrentSubscription(
      state,
      { payload }: PayloadAction<ISubscription | null>,
    ) {
      state.currentSubscription = payload;
    },
    setPaymentMethod(state, { payload }: PayloadAction<ICard | null>) {
      state.paymentMethod = payload;
    },
    setSubscriptionRequiredModal(state, { payload }: PayloadAction<boolean>) {
      state.isSubscriptionRequiredModal = payload;
    },
    setPremiumRequiredModal(state, { payload }: PayloadAction<boolean>) {
      state.isPremiumRequiredModal = payload;
    },
    setMobileSubscriptionLink(state, { payload }: PayloadAction<string>) {
      state.mobileSubscriptionLink = payload;
    },
    setTrialDaysLeft(state, { payload }: PayloadAction<number>) {
      state.trialDaysLeft = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload?.products || [];
    });
    builder.addCase(getCurrentSubscription.fulfilled, (state, action) => {
      state.currentSubscription = action.payload?.subscription || null;
    });
    builder.addCase(getPaymentMethod.fulfilled, (state, action) => {
      state.paymentMethod = action.payload?.card || null;
    });
    builder.addCase(getCancelSubscriptionReasons.fulfilled, (state, action) => {
      state.cancelSubscriptionReasons = action.payload?.reasons || [];
    });
  },
});

export const {
  setPremium,
  setRetryFailed,
  setPremiumModal,
  setTrialActivatedModal,
  setTrialExpireModal,
  setPaymentInitiatedModal,
  setCancelSubscriptionModal,
  setPaymentFailedModal,
  setProducts,
  setCurrentSubscription,
  setPaymentMethod,
  setPremiumActivatedModal,
  setSubscriptionRequiredModal,
  setPremiumRequiredModal,
  setMobileSubscriptionLink,
  setTrialDaysLeft,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
